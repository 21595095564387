import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import { ApiService } from "@/services/ApiService";
import { watch } from "@arcgis/core/core/watchUtils";
import { MapService } from "@/services/MapService";
import { reactive } from "vue";
import { ILocationType } from "@/interfaces/ILocationTypes";
import { ITransportationPoint } from "@/interfaces/ITransportationPoints";
import { ContextService } from "@/services/ContextService";
import { IBadLocation } from "@/interfaces/IBadLocation";
import ButtonMenuItem from "@arcgis/core/widgets/FeatureTable/Grid/support/ButtonMenuItem";
import Extent from "@arcgis/core/geometry/Extent";
import { standardizeErrorMsg } from "../utils/errorManagement";
import { Constants } from "@/services/Constants";
import { NotificationService } from "@/services/NotificationService";

export class LocationTransportationService {
  private static instance: LocationTransportationService;
  isEditor = false;
  locationTypesFL: FeatureLayer = null;
  transportationPointsFL: FeatureLayer = null;
  reportFL: FeatureLayer = null;
  locationFeatureTable: FeatureTable;
  locationTableSize = { size: 0 };
  locationSelectedItems = { size: 0 };
  transportationFeatureTable: FeatureTable;
  transportationTableSize = { size: 0 };
  transportationSelectedItems = { size: 0 };
  importFeatureTable: FeatureTable;
  importTableSize = { size: 0 };
  assignFeatureTable: FeatureTable;
  assignTableSize = { size: 0 };
  reportFeatureTable: FeatureTable;
  reportTableSize = { size: 0 };
  reportSelectedItems = { size: 0 };
  syncFeatureTable: FeatureTable;
  syncTableSize = { size : 0};
  syncSelectedItems = { size: 0 };
  locationFieldConfig: FieldConfig[] = [
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "TypeName",
      label: "Type Name",
    } as FieldConfig,
    {
      name: "TypeID",
      label: "Type ID",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "ClusterName",
      label: "Cluster Name",
    } as FieldConfig,
    {
      name: "ClusterID",
      label: "Cluster ID",
    } as FieldConfig,
    {
      name: "IsOriginDestination",
      label: "Is Origin Destination",
    } as FieldConfig,
    {
      name: "LocationValidity",
      label: "Location Validity",
    } as FieldConfig,
    {
      name: "SynchronizationStatus",
      label: "Synchronization Status",
    } as FieldConfig,
    {
      name: "UpdatedAt",
      label: "Updated At",
    } as FieldConfig,
  ];
  transportationFieldConfig: FieldConfig[] = [
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "TypeName",
      label: "Type Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "ParentLocationName",
      label: "Parent Location Name",
      editable: true,
    } as FieldConfig,
    {
      name: "ParentLocationID",
      label: "Parent Location ID",
      editable: true,
    } as FieldConfig,
    {
      name: "CheckPointName",
      label: "CheckPoint Name",
    } as FieldConfig,
    {
      name: "CheckPointID",
      label: "CheckPoint ID",
    } as FieldConfig,
    {
      name: "TimePenalty",
      label: "Time Penalty",
    } as FieldConfig,
    {
      name: "IsOriginDestination",
      label: "Is Origin Destination",
    } as FieldConfig,
    {
      name: "LocationValidity",
      label: "Location Validity",
    } as FieldConfig,
    {
      name: "SynchronizationStatus",
      label: "Synchronization Status",
    } as FieldConfig,
    {
      name: "UpdatedAt",
      label: "Updated At",
    } as FieldConfig,
  ];
  reportFieldConfig: FieldConfig[] = [
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "IDSource",
      label: "ID Source",
    } as FieldConfig,
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "Type",
      label: "Type",
    } as FieldConfig,
    {
      name: "Other",
      label: "Other",
    } as FieldConfig,
    {
      name: "UserName",
      label: "User Name",
    } as FieldConfig,
    {
      name: "ImportedDate",
      label: "Imported Date",
    } as FieldConfig,
    {
      name: "Comments",
      label: "Comments",
    } as FieldConfig,

  ]
  syncLocationsFieldConfig: FieldConfig[] = [
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "TypeName",
      label: "Type Name",
    } as FieldConfig,
    {
      name: "TypeID",
      label: "Type ID",
    } as FieldConfig,
    {
      name: "ClusterName",
      label: "Cluster Name",
    } as FieldConfig,
    {
      name: "ClusterID",
      label: "Cluster ID",
    } as FieldConfig,
    {
      name: "UpdatedAt",
      label: "Updated At",
    } as FieldConfig,
  ]
  syncTransportationsFieldConfig: FieldConfig[] = [
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "ParentLocationID",
      label: "Parent Location ID",
    } as FieldConfig,
  ]


  

  constructor() {
    esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;
    this.initFeatureLayers();
    this.locationTableSize = reactive({ size: 0 });
    this.locationSelectedItems = reactive({ size: 0 });
    this.transportationTableSize = reactive({ size: 0 });
    this.transportationSelectedItems = reactive({ size: 0 });
    this.importTableSize = reactive({ size: 0 });
    this.assignTableSize = reactive({ size: 0 });
    this.reportTableSize = reactive({ size: 0 });
    this.reportSelectedItems = reactive({ size: 0 });
    this.syncTableSize = reactive({ size: 0 });
    this.syncSelectedItems = reactive({ size: 0 });
    this.isEditor = ContextService.getInstance().isEditor();
  }

  static getInstance() {
    if (!LocationTransportationService.instance) {
      LocationTransportationService.instance = new LocationTransportationService();
    }
    return LocationTransportationService.instance;
  }

  initFeatureLayers(): void {
    if (!this.locationTypesFL && ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.locationTypes.urlField]) {
      this.locationTypesFL = new FeatureLayer({
        editingEnabled: true,
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.locationTypes.urlField]
      });
    }

    if (!this.transportationPointsFL && ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.transportationPoints.urlField]) {
      this.transportationPointsFL = new FeatureLayer({
        editingEnabled: true,
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.transportationPoints.urlField]
      });
    }

    if (!this.reportFL && ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.importedLocations.urlField]) {
      this.reportFL = new FeatureLayer({
        editingEnabled: true,
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.importedLocations.urlField]
      });
    }
  }

  initFeatureTables(locationContainer: HTMLElement, transportationContainer: HTMLElement, importContainer: HTMLElement, assignContainer: HTMLElement, reportContainer: HTMLElement, syncContainer: HTMLElement): any {
    let errorMsg: string = null;
    if (this.locationTypesFL == null) {
      errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMessageManageLocationsLoadingLocations);
    }

    if (this.transportationPointsFL == null) {
      errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMessageManageLocationsTransportationPoints);
    }
    if (this.reportFL == null) {
      errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMessageManageLocationsImportedLocations);
    }

    if (errorMsg && errorMsg.length > 1)
      return errorMsg;

    try {
      const locationZoom = new ButtonMenuItem({
        label: "Zoom to selected feature(s)",
        iconClass: "esri-icon-zoom-in-magnifying-glass",
        clickFunction: async () => {
          if ((this.locationFeatureTable as any).grid.selectedItems.length > 1) {
            const locations = (this.locationFeatureTable as any).grid.selectedItems.items;
            const query = this.locationTypesFL.createQuery();
            let where = "OBJECTID in ";
            const list: any[] = [];
            locations.forEach((loc: any) => {
              list.push(loc.feature.attributes.OBJECTID);
            });
            where += "(" + list.join(", ") + ")"
            query.where = where;
            query.outFields = ['*'];
            const response = await this.locationTypesFL.queryFeatures(query);
            MapService.getInstance().mapView.goTo(response.features);

          } else {
            const location = (this.locationFeatureTable as any).grid.selectedItems.items[0];
            const query = this.locationTypesFL.createQuery();
            query.where = "OBJECTID = '" + location.feature.attributes.OBJECTID + "'";
            query.outFields = ['*'];
            const response = await this.locationTypesFL.queryFeatures(query);
            if (response && response.features[0] && response.features[0].geometry) {
              MapService.getInstance().mapView.goTo(response.features[0]);
              MapService.getInstance().mapView.zoom = 15;
            }
          }
        }
      });
      const locationSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.locationTypesFL.queryObjectIds();
          this.locationFeatureTable.selectRows(response);
        }
      });
      const locationClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.locationFeatureTable.clearSelection();
        }
      });

      this.locationTypesFL.title = "";

      this.locationFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: this.locationTypesFL,
        fieldConfigs: this.locationFieldConfig,
        container: locationContainer,
        menuConfig: {
          items: [
            locationZoom,
            locationSelectAll,
            locationClearSelection
          ]
        }
      });
      watch((this.locationFeatureTable as any).grid, "selectedItems.length", () => {
        this.locationSelectedItems.size = (this.locationFeatureTable as any).grid.selectedItems.length;
      });
      watch((this.locationFeatureTable as any).grid, "size", () => {
        this.locationTableSize.size = (this.locationFeatureTable as any).grid.size;
      });

      const tpZoom = new ButtonMenuItem({
        label: "Zoom to selected feature(s)",
        iconClass: "esri-icon-zoom-in-magnifying-glass",
        clickFunction: async () => {
          if ((this.transportationFeatureTable as any).grid.selectedItems.length > 1) {
            const tps = (this.locationFeatureTable as any).grid.selectedItems.items;
            const query = this.locationTypesFL.createQuery();
            let where = "OBJECTID in ";
            const list: any[] = [];
            tps.forEach((loc: any) => {
              list.push(loc.feature.attributes.OBJECTID);
            });
            where += "(" + list.join(", ") + ")"
            query.where = where;
            query.outFields = ['*'];
            const response = await this.locationTypesFL.queryFeatures(query);
            MapService.getInstance().mapView.goTo(response.features);

          } else {
            const tp = (this.transportationFeatureTable as any).grid.selectedItems.items[0];
            const query = this.transportationPointsFL.createQuery();
            query.where = "OBJECTID = '" + tp.feature.attributes.OBJECTID + "'";
            query.outFields = ['*'];
            const response = await this.transportationPointsFL.queryFeatures(query);
            if (response && response.features[0] && response.features[0].geometry) {
              MapService.getInstance().mapView.goTo(response.features[0]);
              MapService.getInstance().mapView.zoom = 15;
            }
          }
        }
      });
      const tpSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.transportationPointsFL.queryObjectIds();
          this.transportationFeatureTable.selectRows(response);
        }
      });
      const tpClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.transportationFeatureTable.clearSelection();
        }
      });

      this.transportationPointsFL.title = "";

      this.transportationFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: this.transportationPointsFL,
        fieldConfigs: this.transportationFieldConfig,
        container: transportationContainer,
        menuConfig: {
          items: [
            tpZoom,
            tpSelectAll,
            tpClearSelection
          ]
        }
      });
      watch((this.transportationFeatureTable as any).grid, "selectedItems.length", () => {
        this.transportationSelectedItems.size = (this.transportationFeatureTable as any).grid.selectedItems.length;
      });
      watch((this.transportationFeatureTable as any).grid, "size", () => {
        this.transportationTableSize.size = (this.transportationFeatureTable as any).grid.size;
      });

      const importSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.importFeatureTable.layer.queryObjectIds();
          this.importFeatureTable.selectRows(response);
        }
      });
      const importClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.transportationFeatureTable.clearSelection();
        }
      });

      this.importFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: null,
        fieldConfigs: this.locationFieldConfig,
        container: importContainer,
        visible: false,
        menuConfig: {
          items: [
            importSelectAll,
            importClearSelection
          ]
        }
      });
      this.importFeatureTable.on("selection-change", (evt) => {
        if (evt.removed.length < 1 || evt.added.length < 1) {
          this.importTableSize.size = (this.importFeatureTable as any).grid.selectedItems.length;
        }
      });

      const assignSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.assignFeatureTable.layer.queryObjectIds();
          this.assignFeatureTable.selectRows(response);
        }
      });
      const assignClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.assignFeatureTable.clearSelection();
        }
      });

      this.assignFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: null,
        fieldConfigs: this.locationFieldConfig,
        container: assignContainer,
        visible: false,
        menuConfig: {
          items: [
            assignSelectAll,
            assignClearSelection
          ]
        }
      });
      watch((this.assignFeatureTable as any).grid, "selectedItems.length", () => {
        this.assignTableSize.size = (this.assignFeatureTable as any).grid.selectedItems.length;
      });
      this.assignFeatureTable.on("selection-change", (evt) => {
        if (evt.removed.length < 1) {
          this.assignFeatureTable.clearSelection();
          this.assignFeatureTable.selectRows(evt.added[0].feature);
          (this.assignFeatureTable as any).grid.selectedItems.items[0] = evt.added[0].feature;
        }
      });

      const reportSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.reportFeatureTable.layer.queryObjectIds();
          this.reportFeatureTable.selectRows(response);
        }
      });
      const reportClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.reportFeatureTable.clearSelection();
        }
      });

      this.reportFL.title = "NON IMPORTED ITEMS";

      this.reportFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: this.reportFL,
        fieldConfigs: this.reportFieldConfig,
        container: reportContainer,
        menuConfig: {
          items: [
            reportSelectAll,
            reportClearSelection
          ]
        }
      });
      watch((this.reportFeatureTable as any).grid, "selectedItems.length", () => {
        this.reportSelectedItems.size = (this.reportFeatureTable as any).grid.selectedItems.length;
      });
      watch((this.reportFeatureTable as any).grid, "size", () => {
        this.reportTableSize.size = (this.reportFeatureTable as any).grid.size;
      });

      const syncZoom = new ButtonMenuItem({
        label: "Zoom to selected feature(s)",
        iconClass: "esri-icon-zoom-in-magnifying-glass",
        clickFunction: async () => {
          if ((this.syncFeatureTable as any).grid.selectedItems.length > 0) {
            console.log("1")
            const syncs = (this.syncFeatureTable as any).grid.selectedItems.items;
            const query = this.syncFeatureTable.layer.createQuery();
            let where = "OBJECTID in ";
            const list: any[] = [];
            syncs.forEach((loc: any) => {
              list.push(loc.feature.attributes.OBJECTID);
            });
            where += "(" + list.join(", ") + ")"
            console.log(where)
            query.where = where;
            query.outFields = ['*'];
            const response = await this.syncFeatureTable.layer.queryFeatures(query);
            if(response.features.length == 1) {
              MapService.getInstance().mapView.goTo(response.features);
              MapService.getInstance().mapView.zoom = 15;
            } else {
              MapService.getInstance().mapView.goTo(response.features);
            }

          }
        }
      });
      const syncSelectAll = new ButtonMenuItem({
        label: "Select all",
        clickFunction: async () => {
          const response = await this.syncFeatureTable.layer.queryObjectIds();
          this.syncFeatureTable.selectRows(response);
        }
      });
      const syncClearSelection = new ButtonMenuItem({
        label: "Clear selection",
        clickFunction: () => {
          this.syncFeatureTable.clearSelection();
        }
      });

      this.syncFeatureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: null,
        fieldConfigs: null,
        container: syncContainer,
        visible: false,
        menuConfig: {
          items: [
            syncZoom,
            syncSelectAll,
            syncClearSelection
          ]
        },
        visibleElements: {
          menuItems: {
            clearSelection: false, 
            refreshData: false,
            toggleColumns: true
          }
        }
      });
      watch((this.syncFeatureTable as any).grid, "size", () => {
        this.syncTableSize.size = (this.syncFeatureTable as any).grid.size;
      });
      watch((this.syncFeatureTable as any).grid, "selectedItems.length", () => {
        this.syncSelectedItems.size = (this.syncFeatureTable as any).grid.selectedItems.length;
      });

      if (this.isEditor) {
        this.locationFeatureTable.editingEnabled = true;
        this.transportationFeatureTable.editingEnabled = true;
      }
    }
    catch (error: any) {
      errorMsg = standardizeErrorMsg(error, Constants.errorMessageManageLocationsInitialisation);
    }

    return null;
  }

  async getCurrentLocation(): Promise<any> {
    const locations: ILocationType[] = [];
    const query = this.locationTypesFL.createQuery();
    query.where = "1=1";
    query.outFields = ["*"];
    const response = await this.locationTypesFL.queryFeatures(query);
    if (response && response.features) {
      response.features.forEach((feature) => {
        const location = feature.attributes;
        location.geometry = feature.geometry;
        locations.push(location);
      });
    }
    return locations;
  }

  async getCurrentTransportation(): Promise<any> {
    const transportations: ITransportationPoint[] = [];
    const query = this.transportationPointsFL.createQuery();
    query.where = "1=1";
    query.outFields = ["*"];
    const response = await this.transportationPointsFL.queryFeatures(query);
    if (response && response.features) {
      response.features.forEach((feature) => {
        const transportation = feature.attributes;
        transportation.geometry = feature.geometry;
        transportations.push(transportation);
      });
    }
    return transportations;
  }

  //DB Operations

  async addLocation(
    location: ILocationType
  ): Promise<{ objectId: number }> {
    const geometry = location.geometry;
    delete location.geometry;
    const areaGraphic: Graphic = new Graphic({
      attributes: location,
      geometry: geometry as any,
    });
    const result: any = await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.locationTypes
        .urlField
      ],
      [areaGraphic],
      ApiService.editMode.ADD
    );
    this.locationFeatureTable.refresh();
    return result && result.length ? result[0].objectId : null;
  }

  async deleteSelectedLocation(): Promise<void> {
    const locationGrid = (this.locationFeatureTable as any).grid;
    if (locationGrid && locationGrid.selectedItems && locationGrid.selectedItems.items) {
      const locations: any[] = [];
      locationGrid.selectedItems.items.forEach((element: any) => {
        locations.push(element.objectId);
      });
      await this.deleteLocation(locations);
    }
  }

  async deleteLocation(locationsID: any[]): Promise<any> {
    await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.locationTypes
        .urlField
      ],
      locationsID,
      ApiService.editMode.DELETE
    );
    this.locationFeatureTable.refresh();
  }

  async addTransportation(
    transportation: ITransportationPoint
  ): Promise<{ objectId: number }> {
    const geometry = transportation.geometry;
    delete transportation.geometry;
    const transportationGraphic: Graphic = new Graphic({
      attributes: transportation,
      geometry: geometry as any,
    });
    const result: any = await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.transportationPoints
        .urlField
      ],
      [transportationGraphic],
      ApiService.editMode.ADD
    );
    this.transportationFeatureTable.refresh();
    return result && result.length ? result[0].objectId : null;
  }

  async deleteSelectedTransportation(): Promise<void> {
    const transportationGrid = (this.transportationFeatureTable as any).grid;
    if (transportationGrid && transportationGrid.selectedItems && transportationGrid.selectedItems.items) {
      const transportations: any[] = [];
      transportationGrid.selectedItems.items.forEach((element: any) => {
        transportations.push(element.objectId);
      });
      await this.deleteTransportation(transportations);
    }
  }

  async deleteTransportation(transportationsID: any[]): Promise<any> {
    await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.transportationPoints
        .urlField
      ],
      transportationsID,
      ApiService.editMode.DELETE
    );
    this.transportationFeatureTable.refresh();
  }

  async addBadLocation(
    badLocation: IBadLocation
  ): Promise<{ objectId: number }> {
    const geometry = badLocation.geometry;
    delete badLocation.geometry;
    const areaGraphic: Graphic = new Graphic({
      attributes: badLocation,
      geometry: geometry as any,
    });
    const result: any = await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.importedLocations
        .urlField
      ],
      [areaGraphic],
      ApiService.editMode.ADD
    );
    this.locationFeatureTable.refresh();
    return result && result.length ? result[0].objectId : null;
  }

  async deleteSelectedBadLocation(): Promise<void> {
    const badLocationGrid = (this.reportFeatureTable as any).grid;
    if (badLocationGrid && badLocationGrid.selectedItems && badLocationGrid.selectedItems.items) {
      const badLocations: any[] = [];
      badLocationGrid.selectedItems.items.forEach((element: any) => {
        badLocations.push(element.objectId);
      });
      await this.deleteBadLocation(badLocations);
    }
  }

  async deleteBadLocation(badLocationsID: any[]): Promise<any> {
    await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.importedLocations
        .urlField
      ],
      badLocationsID,
      ApiService.editMode.DELETE
    );
    this.reportFeatureTable.refresh();
  }
}
