
import { IOverlay } from "@/interfaces/IOverlay";
import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import { MapService } from "./MapService";
import { ICustomRoads } from "@/interfaces/ICustomRoads";
import { reactive } from "vue";
import { watch } from "@arcgis/core/core/watchUtils";
import { ApiService } from "@/services/ApiService";
import ButtonMenuItem from "@arcgis/core/widgets/FeatureTable/Grid/support/ButtonMenuItem";


export class CustomRoadsService {

    private static instance: CustomRoadsService;
    featureTable: FeatureTable;
    featureLayer: FeatureLayer;


    selectedFeature: any;

    tableSize: {size: number};
    tableSelectedItems: { size: number };

    tableFieldConfig: FieldConfig[] = [
    {
        name: "TypeOfEdit",
        label: "Type of edit"
    } as FieldConfig,
    {
        name: "Name",
        label: "Name"
    } as FieldConfig,
    {
        name: "SpeedCategory",
        label: "Speed Category"
    } as FieldConfig,
    {
        name: "DirectionOfTravel",
        label: "Direction Of Travel"
    } as FieldConfig,
    {
        name: "Car",
        label: "Car"
    } as FieldConfig,
    {
        name: "Bus",
        label: "Bus"
    } as FieldConfig,
    {
        name: "Truck",
        label: "Truck"
    } as FieldConfig,
    {
        name: "Pedestrian",
        label: "Pedestrian"
    } as FieldConfig,
    {
        name: "Comments",
        label: "Comments"
    } as FieldConfig]

    constructor() {
        esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;

        // Replaced because this the find by layer is not working
        this.featureLayer = new FeatureLayer({
            editingEnabled: true,
            url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.urlField]
        });
        
        //this.featureLayer = MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.title]);
        
        this.tableSize = reactive({size: 0});
        this.tableSelectedItems = reactive({ size: 0});
    }

    static getInstance() {
        if (!CustomRoadsService.instance) {
            CustomRoadsService.instance = new CustomRoadsService();
        }

        return CustomRoadsService.instance;
    }

    showFeatureTable(containerEl: HTMLElement): void {
        const zoom = new ButtonMenuItem({
            label: "Zoom to selected feature(s)",
            iconClass: "esri-icon-zoom-in-magnifying-glass",
            clickFunction: async () => {
              if ((this.featureTable as any).grid.selectedItems.length > 1) {
                const areas = (this.featureTable as any).grid.selectedItems.items;
                const query = this.featureLayer.createQuery();
                let where = "OBJECTID in ";
                const list: any[] = [];
                areas.forEach((area: any) => {
                  list.push(area.feature.attributes.OBJECTID);
                });
                where += "(" + list.join(", ") + ")"
                query.where = where;
                query.outFields = ['*'];
                const response = await this.featureLayer.queryFeatures(query);
                MapService.getInstance().mapView.goTo(response.features);
      
              } else {
                const area = (this.featureTable as any).grid.selectedItems.items[0];
                const query = this.featureLayer.createQuery();
                query.where = "OBJECTID = '" + area.feature.attributes.OBJECTID + "'";
                query.outFields = ['*'];
                const response = await this.featureLayer.queryFeatures(query);
                if (response && response.features[0] && response.features[0].geometry) {
                  MapService.getInstance().mapView.goTo(response.features[0]);
                  MapService.getInstance().mapView.zoom = 15;
                }
              }
            }
        });
        const selectAll = new ButtonMenuItem ({
            label: "Select all",
            clickFunction: async () => {
                const response = await this.featureLayer.queryObjectIds();
                this.featureTable.selectRows(response);
            }
        });
        const clearSelection = new ButtonMenuItem ({
            label: "Clear selection",
            clickFunction: () => {
                this.featureTable.clearSelection();
            }
        });
        this.featureLayer.title = "Custom Roads";
        this.featureTable = new FeatureTable({
            editingEnabled: true,
            view: MapService.getInstance().mapView,
            layer: this.featureLayer,
            fieldConfigs: this.tableFieldConfig,
            container: containerEl,
            menuConfig: {
                items: [
                    zoom,
                    selectAll,
                    clearSelection
                ]
            }
        });
        const areaGrid = (this.featureTable as any).grid;
        watch(areaGrid, "size", () => {
            this.tableSize.size = areaGrid.size;
        })
        watch((this.featureTable as any).grid, "selectedItems.length", () => {
            this.tableSelectedItems.size = (this.featureTable as any).grid.selectedItems.length;
        });
    }

    async deleteSelectedCustomRoad(): Promise<any> {
        const customRoadsGrid = (this.featureTable as any).grid;
        if (customRoadsGrid && customRoadsGrid.selectedItems && customRoadsGrid.selectedItems.items) {
            const roads: any[] = [];
            customRoadsGrid.selectedItems.items.forEach((element: any) => {
                roads.push(element.objectId);
            });
            await this.deleteCustomRoad(roads);
        }
    }

    // Map Operations
    filterCustomRoadsByOverlayId(overlayID: number): void {
        this.featureLayer.definitionExpression = `OVERLAYID = ${overlayID}`;
    }

    async getCurrentCustomRoads(): Promise<any> {
        const customRoads: ICustomRoads[] = [];
        const query = this.featureLayer.createQuery();
        query.where = this.featureLayer.definitionExpression;
        query.outFields = ['*'];
        const response = await this.featureLayer.queryFeatures(query);
        if (response && response.features) {
            response.features.forEach(feature => {
                const customRoad = feature.attributes;
                customRoad.geometry = feature.geometry;
                customRoads.push(customRoad);
            });
        }
        return customRoads;
        //const overlays: IOverlay;
    }

    //DB Operations

    async addCustomRoad(customRoad: ICustomRoads): Promise<{ objectId: number }> {
        const geometry = customRoad.geometry;
        delete (customRoad.geometry);
        const roadGraphic: Graphic = new Graphic({
            attributes: customRoad,
            geometry: geometry
        })
        const result: any = await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.urlField], [roadGraphic], ApiService.editMode.ADD);
        this.featureTable.refresh();
        MapService.getInstance().map.allLayers.forEach((layer: any) => {
            if(layer.title == "Custom roads")
              layer.refresh();
        });
        return result && result.length ? result[0].objectId : null;
    }

    async deleteCustomRoad(roadsID: any[]): Promise<any> {
        await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
                ConfigService.getInstance().config.featuresServer.customRoads.urlField], 
            roadsID, 
            ApiService.editMode.DELETE);
        this.featureTable.refresh();
        MapService.getInstance().map.allLayers.forEach((layer: any) => {
            if(layer.title == "Custom roads")
              layer.refresh();
        });
    }

}