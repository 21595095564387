
import { Options, Vue } from "vue-class-component";
import { CustomRoadsService } from "@/services/CustomRoadsService";
import { MapService } from "@/services/MapService";
import { LoaderService } from "@/services/LoaderService";
import { ContextService } from "@/services/ContextService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import { NotificationService } from "@/services/NotificationService";
import { ICustomRoads } from "@/interfaces/ICustomRoads";
import { IOverlay } from "@/interfaces/IOverlay";
import { store } from "@/store";
import eventBus from "@/services/EventBus";
import EsriGeoJSONUtils from "@/utils/EsriGeoJSONUtils";

@Options({
  props: {},
})
export default class CustomRoads extends Vue {
  overlay: IOverlay = {} as IOverlay;
  isEditor = false;
  tableSize = {size: 0};
  tableSelectedItems = { size: 0};
  showFT = false;
  overwriteData = false;
  appendData = true;
  size = 0;
  allLayers: any;
  featureLayer: FeatureLayer;
  featureTable: FeatureTable;
  tableFieldConfig: FieldConfig[] = [
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
  ];
  exportType = "Layer";

  mounted() {
    this.overlay = { ...store.state.overlay };
    CustomRoadsService.getInstance().showFeatureTable(
      (this.$refs as any).featureTableRef
    );
    this.isEditor = ContextService.getInstance().isEditor();
    this.tableSize = CustomRoadsService.getInstance().tableSize;
    this.tableSelectedItems = CustomRoadsService.getInstance().tableSelectedItems;
    this.allLayers = MapService.getInstance().map.allLayers;
    eventBus().emitter.on("addLayer", () => {
      this.allLayers = MapService.getInstance().map.allLayers;
      this.$forceUpdate();
    });
  }

  onNewCustomRoad(): void {
    MapService.getInstance().startDrawing("custom-road");
  }

  async onRemoveCustomRoad(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await CustomRoadsService.getInstance().deleteSelectedCustomRoad();
    } finally {
        LoaderService.getInstance().hideLoader();
    }
  }

  showImportModal(): void {
    (this.$refs as any).importModal.active = true;
  }

  hideImportModal(): void {
    (this.$refs as any).importModal.active = false;
  }

  showExportModal(): void {
    (this.$refs as any).exportModal.active = true;
  }

  hideExportModal(): void {
    (this.$refs as any).exportModal.active = false;
  }

  showFeatureTable(layer: any): void {
    if (this.featureTable) {
      this.featureTable.view = MapService.getInstance().mapView;
      this.featureTable.layer = layer;
      this.featureTable.fieldConfigs = this.tableFieldConfig as any;
      this.featureTable.container = (this.$refs as any).featureTableRef2;
      this.featureTable.visible = true;
    } else {
      this.featureTable = new FeatureTable({
        view: MapService.getInstance().mapView,
        layer: layer,
        fieldConfigs: this.tableFieldConfig,
        container: (this.$refs as any).featureTableRef2,
      });
    }
    this.featureTable.on("selection-change", (evt) => {
      if (evt.removed.length < 1 || evt.added.length < 1) {
        this.size = (this.featureTable as any).grid.selectedItems.length;
      }
    });
  }

  hideFeatureTable(): void {
    this.featureTable.visible = false;
  }

  async importData(): Promise<void> {
    const roadGrid = (this.featureTable as any).grid;
    if (this.appendData === true && this.overwriteData === false) {
      if (roadGrid && roadGrid.selectedItems && roadGrid.selectedItems.items) {
        LoaderService.getInstance().showLoader();
        try {
          const query = this.featureTable.layer.createQuery();
          query.where = "1=1";
          query.outFields = ["*"];
          const roads = await this.featureTable.layer.queryFeatures(query);
          roads.features.forEach(async (element: any) => {
            const road: ICustomRoads = {
              OBJECTID: element.attributes.OBJECTID,
              CreationDate: element.attributes.CreationDate,
              Creator: element.attributes.Creator,
              EditDate: element.attributes.EditDate,
              Editor: element.attributes.Editor,
              Name: element.attributes.Name,
              DirectionofTravel: element.attributes.DirectionofTravel,
              SpeedCategory: element.attributes.SpeedCategory,
              OVERLAYID: store.state.overlay.OBJECTID,
              TransportMode: element.attributes.TransportMode,
              Car: element.attributes.Car,
              Bus: element.attributes.Bus,
              Truck: element.attributes.Truck,
              Pedestrian: element.attributes.Pedestrian,
              TypeOfEdit: element.attributes.TypeOfEdit,
              geometry: element.geometry
            } as ICustomRoads;
            await CustomRoadsService.getInstance().addCustomRoad(road);
          });
        } catch (error) {
          LoaderService.getInstance().hideLoader();
          NotificationService.getInstance().showNotification(
            "Import Custom Roads",
            error.data.error,
            "red",
            false
          );
        } finally {
          LoaderService.getInstance().hideLoader();
          NotificationService.getInstance().showNotification(
            "Import Custom Roads",
            "The data has been successfully added",
            "green",
            false
          );
        }
      }
    } else if (this.overwriteData === true && this.appendData === false) {
      if (roadGrid && roadGrid.selectedItems && roadGrid.selectedItems.items) {
        LoaderService.getInstance().showLoader();
        try {
          const roads =
            await CustomRoadsService.getInstance().getCurrentCustomRoads();
          roads.forEach(async (element: any) => {
            await CustomRoadsService.getInstance().deleteCustomRoad(
              element.OBJECTID
            );
          });
          const query = this.featureTable.layer.createQuery();
          query.where = "1=1";
          query.outFields = ["*"];
          const roadss = await this.featureTable.layer.queryFeatures(query);
          roadss.features.forEach(async (element: any) => {
            const road: ICustomRoads = {
              OBJECTID: element.attributes.OBJECTID,
              CreationDate: element.attributes.CreationDate,
              Creator: element.attributes.Creator,
              EditDate: element.attributes.EditDate,
              Editor: element.attributes.Editor,
              Name: element.attributes.Name,
              DirectionofTravel: element.attributes.DirectionofTravel,
              SpeedCategory: element.attributes.SpeedCategory,
              OVERLAYID: store.state.overlay.OBJECTID,
              TransportMode: element.attributes.TransportMode,
              Car: element.attributes.Car,
              Bus: element.attributes.Bus,
              Truck: element.attributes.Truck,
              Pedestrian: element.attributes.Pedestrian,
              TypeOfEdit: element.attributes.TypeOfEdit,
              geometry: element.geometry
            } as ICustomRoads;
            await CustomRoadsService.getInstance().addCustomRoad(road);
          });
        } catch (error) {
          LoaderService.getInstance().hideLoader();
          NotificationService.getInstance().showNotification(
            "Import Custom Roads",
            error.data.error,
            "red",
            false
          );
        } finally {
          LoaderService.getInstance().hideLoader();
          NotificationService.getInstance().showNotification(
            "Import Custom Roads",
            "The data has been successfully overwritten",
            "green",
            false
          );
        }
      }
    }
  }

  async exportData(): Promise<void> {
    let layer;
    let features: any[] = [];
    try {
      LoaderService.getInstance().showLoader();
      if (this.exportType == "Layer") {
        const query = CustomRoadsService.getInstance().featureLayer.createQuery();
        query.where = "OVERLAYID = '" + store.state.overlay.OBJECTID + "'";
        query.outFields = ["*"];
        const response = await CustomRoadsService.getInstance().featureLayer.queryFeatures(query);
        response.features.forEach((f: any) => {
          const feat = {
            type: "Feature",
            objectId: f.attributes.OBJECTID,
            geometry: EsriGeoJSONUtils.arcgisToGeoJSON(f.geometry),
            properties: {
              OBJECTID: f.attributes.OBJECTID,
              CreationDate: f.attributes.CreationDate,
              Creator: f.attributes.Creator,
              EditDate: f.attributes.EditDate,
              Editor: f.attributes.Editor,
              Name: f.attributes.Name,
              DirectionofTravel: f.attributes.DirectionofTravel,
              SpeedCategory: f.attributes.SpeedCategory,
              OVERLAYID: store.state.overlay.OBJECTID,
              TransportMode: f.attributes.TransportMode,
              Car: f.attributes.Car,
              Bus: f.attributes.Bus,
              Truck: f.attributes.Truck,
              Pedestrian: f.attributes.Pedestrian,
              TypeOfEdit: f.attributes.TypeOfEdit,
            },
          };
          features.push(feat);
        });
        layer = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326",
            },
          },
          features: features,
        };
      } else if (
        this.exportType == "Selected" &&
        (CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.length > 0
      ) {
        const table = (CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.items;
        let where;
        if ((CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.length > 1) {
          where = "OBJECTID in ";
          const list: any[] = [];
          table.forEach((feat: any) => {
            list.push(feat.feature.attributes.OBJECTID);
          });
          where += "(" + list.join(", ") + ")";
        } else if ((CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.length == 1) {
          where = "OBJECTID = '" + table[0].feature.attributes.OBJECTID + "'";
        }
        const query = CustomRoadsService.getInstance().featureLayer.createQuery();
        query.where = where;
        query.outFields = ["*"];
        const response = await CustomRoadsService.getInstance().featureLayer.queryFeatures(query);
        response.features.forEach((f: any) => {
          const feat = {
            type: "Feature",
            objectId: f.attributes.OBJECTID,
            geometry: EsriGeoJSONUtils.arcgisToGeoJSON(f.geometry),
             properties: {
              OBJECTID: f.attributes.OBJECTID,
              CreationDate: f.attributes.CreationDate,
              Creator: f.attributes.Creator,
              EditDate: f.attributes.EditDate,
              Editor: f.attributes.Editor,
              Name: f.attributes.Name,
              DirectionofTravel: f.attributes.DirectionofTravel,
              SpeedCategory: f.attributes.SpeedCategory,
              OVERLAYID: store.state.overlay.OBJECTID,
              TransportMode: f.attributes.TransportMode,
              Car: f.attributes.Car,
              Bus: f.attributes.Bus,
              Truck: f.attributes.Truck,
              Pedestrian: f.attributes.Pedestrian,
              TypeOfEdit: f.attributes.TypeOfEdit,
            },
          };
          features.push(feat);
        });
        layer = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326",
            },
          },
          features: features,
        };
      }
      if (
        this.exportType == "Layer" ||
        (this.exportType == "Selected" &&
          (CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.length > 0)
      ) {
        let name = "Custom Roads - ";
        name += Date.now().toString();
        var hiddenElement = document.createElement("a");
        var data = new Blob([JSON.stringify(layer)], { type: "text/plain" });
        var url = window.URL.createObjectURL(data);
        hiddenElement.href = url;
        hiddenElement.target = "_blank";
        hiddenElement.download = name + ".geojson";
        hiddenElement.click();
      }
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Export Custom Roads",
        "Can't export the data.",
        "red",
        false
      );
    } finally {
      if (
        (CustomRoadsService.getInstance().featureTable as any).grid.selectedItems.length == 0 &&
        this.exportType == "Selected"
      ) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Export Custom Roads",
          "Impossible to export the data because there is no selected item in the table. Please select at least one item.",
          "red",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Export Custom Roads",
          "The data has been exported successfully.",
          "green",
          false
        );
      }
    }
  }

  get isDisable() {
    if (this.size < 1 || this.size > 2000) return true;
    else return false;
  }

}
