import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import Search from "@arcgis/core/widgets/Search";
import Locate from "@arcgis/core/widgets/Locate";
import Home from "@arcgis/core/widgets/Home";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import Expand from "@arcgis/core/widgets/Expand";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import Point from "@arcgis/core/geometry/Point";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import { ConfigService } from "./ConfigService";
import CoordinateConversion from "@arcgis/core/widgets/CoordinateConversion";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import Bookmarks from "@arcgis/core/widgets/Bookmarks";
import { createApp } from "vue";
import Route from "../components/widgets/route/RouteWidget.vue";
import Overlays from "../components/widgets/overlays/Overlays.vue";
import Matrix from "../components/widgets/matrix/Matrix.vue";
import AddDataWidget from "../components/widgets/addData/addDataWidget.vue";
import LocationTransportation from "../components/widgets/locationTransportation/locationTransportation.vue";
import NewOverlayDialog from "../components/widgets/overlays/overlay/new-overlay-dialog/NewOverlayDialog.vue";
import Draw from "@arcgis/core/views/draw/Draw";
import PortalSource from "@arcgis/core/widgets/BasemapGallery/support/PortalBasemapsSource";
import { reactive } from "vue";
import { NotificationService } from "./NotificationService";
import { IRestrictedArea } from "@/interfaces/IRestrictedArea";
import { store } from "@/store";
import { RestrictedAreasService } from "./RestrictedAreasService";
import { ICustomRoads } from "@/interfaces/ICustomRoads";
import { CustomRoadsService } from "./CustomRoadsService";
import Polygon from "@arcgis/core/geometry/Polygon";
import { LoaderService } from "./LoaderService";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import esriId from "@arcgis/core/identity/IdentityManager";
import { IOverlay } from "@/interfaces/IOverlay";
import { Constants } from "./Constants";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import Basemap from "@arcgis/core/Basemap";
import WebTileLayer from "@arcgis/core/layers/WebTileLayer";
import { MapForRouteService } from "./MapForRouteService";
import { ICustomBaseMap } from "@/interfaces/ICustomBaseMap";
import eventBus from "@/services/EventBus";
import { ContextService } from "./ContextService";
import { ApiService } from "./ApiService";
import VectorTileLayer from "@arcgis/core/layers/VectorTileLayer";
import WMSLayer from "@arcgis/core/layers/WMSLayer";
import { standardizeErrorMsg } from "../utils/errorManagement";

export class MapService {
  private static instance: MapService;
  ioverly: IOverlay;
  map!: WebMap;
  mapView!: MapView;
  geoJSONLayer!: GeoJSONLayer;
  isFromEndActive: "start" | "end" | null = null;
  graphicFrom!: Graphic;
  pointFrom!: { lat: number; lng: number };
  graphicTo!: Graphic;
  pointTo!: { lat: number; lng: number };
  currentDraw: "overlay" | "area" | "areaV8" | "custom-road";
  drawToolBar: Draw;
  isDrawing: boolean;
  drawLayer: GraphicsLayer;
  startEndLayer: GraphicsLayer;
  RouteGroupLayer: GroupLayer = null;
  matrixGroupLayer: GroupLayer;
  addDataGroupLayer: GroupLayer = null;
  manageEventLocationsGroupLayer: GroupLayer = null;
  routeExpandComponent: Expand;
  overlayExpandComponent: Expand;

  sketchViewModel: SketchViewModel;
  markerSymbolFrom: PictureMarkerSymbol;
  markerSymbolTo: PictureMarkerSymbol;
  matrixExpandComponent: Expand;
  addDataComponent: Expand;
  mapForRouteService: MapForRouteService;
  BookmarksKey: string;
  locationTransportationComponent: Expand;

  constructor(containerElement: HTMLDivElement) {
    let BookmarksConfig: number;
    let errorMsg: string;

    this.CreateBookmarksKey();
    this.setMaker();
    this.map = this.initMap();

    // To be improved
    if (this.map == null) {
      LoaderService.getInstance().hideLoader();
      return;
    }
    this.drawLayer = new GraphicsLayer({
      title: "overlay_draw_layer",
      listMode: "hide",
    });
    this.map.add(this.drawLayer);

    // Create Graphic Layer for pin poitn start and stop
    this.startEndLayer = new GraphicsLayer({
      title: "Start end locations",
      graphics: [],
    });

    this.mapView = new MapView({
      container: containerElement,
      map: this.map,
      viewpoint: this.map.initialViewProperties.viewpoint,
    });

    if (ConfigService.getInstance().webmapConfig.attributes.ZoomToExtent) {
      if (Number(ConfigService.getInstance().webmapConfig.attributes.ZoomToExtent) == 1) {
        if (ConfigService.getInstance().webmapConfig.geometry) {
          const geometry = Polygon.fromJSON(
            ConfigService.getInstance().webmapConfig.geometry
          );

          this.mapView.goTo(geometry)
        }
        else {
          errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMsgInvalidWebMapZoomToExtentGeometry);
        }
      }
    }
    else {
      errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMsgInvalidWebMapZoomToExtent);
    }


    async function widgets(mapView: MapView, BookmarksKey: string) {

      try {
        const customWidgetList: any = ConfigService.getInstance().webmapConfig
          ? ConfigService.getInstance().webmapConfig.attributes.WidgetList
            ? ConfigService.getInstance().webmapConfig.attributes.WidgetList.length
              ? ConfigService.getInstance().webmapConfig.attributes.WidgetList
              : JSON.stringify(Constants.defaultWidgetList)
            : JSON.stringify(Constants.defaultWidgetList)
          : JSON.stringify(Constants.defaultWidgetList);

        //Parse the string to Json format
        const array = JSON.parse(customWidgetList);

        const searchWidget = new Search({
          view: mapView,
        });

        const homeWidget = new Home({
          view: mapView,
        });

        const locateWidget = new Locate({
          view: mapView,
        })

        /*let locateWidget
        await navigator.permissions.query({ name: 'geolocation' })
          .then((perm) => {
            if (perm.state == "granted") {
              locateWidget = new Locate({
                view: mapView,
              })
            } else {
              navigator.geolocation.getCurrentPosition(function (){
                locateWidget = new Locate({
                  view: mapView,
                })
                const index = array.findIndex((element: any) => element.title === "locateWidget")
                if (index != -1) {
                  mapView.ui.add(locateWidget, {
                    position: array[index].position,
                    index: array[index].index ? array[index].index : array[index].position == "top-left" ? widgetIndexTopLeft + 2 : array[index].position == "top-right" ? widgetIndexTopRight + 2 : undefined
                  });
                }
              },function (){
                errorMsg = standardizeErrorMsg(errorMsg,Constants.errorMsgLocateButtonAccessDenied);
              });
            }

          });*/

        const xyWidget = new CoordinateConversion({
          view: mapView,
          visibleElements: {
            settingsButton: false,
            editButton: false,
            expandButton: true,
            captureButton: true,
          },
        });
        //Creating the source to be added to the BasemapGallery widget

        const source = new PortalSource({
          // filtering portal basemaps from the basemap list
          filterFunction: (basemap: any) =>
            allowedBasemapTitles.indexOf(basemap.portalItem.title) > -1,

          // add custom basemap stored the vectorBaseMapArray
          updateBasemapsCallback: function (items) {
            // add custom basemap item to the array
            for (let i = 0; i < vectorBaseMapArray.length; i++) {
              items.push(vectorBaseMapArray[i]);
            }
            // return the array of basemaps
            return items;
          },
        });

        const basemapGallery = new BasemapGallery({
          view: mapView,
          container: document.createElement("div"),
          //Creating the source to be added to the BasemapGallery widget
          source: source,
        });

        const basemapWidget = new Expand({
          view: mapView,
          content: basemapGallery,
          expandTooltip: "Basemap",
          group: "top-right",
        });

        basemapGallery.watch("activeBasemap", () => {
          const mobileSize =
            mapView.heightBreakpoint === "xsmall" ||
            mapView.widthBreakpoint === "xsmall";
          if (mobileSize) {
            basemapWidget.collapse();
          }
        });

        // Adds widget in the bottom left corner of the view

        const layerList = new LayerList({
          view: mapView,
        });

        const layerListWidget = new Expand({
          view: mapView,
          content: layerList,
          expandTooltip: "Layer list",
          group: "top-right",
        });

        const bookmarks = new Bookmarks({
          view: mapView,
          // allows bookmarks to be added, edited, or deleted
          editingEnabled: true,
        });
        const bookmarksWidget = new Expand({
          view: mapView,
          content: bookmarks,
          expandTooltip: "Bookmarks",
          group: "top-right",
        });

        bookmarks.on("bookmark-edit", () => {
          if (BookmarksConfig != null) {
            const tmpArr = (bookmarks.bookmarks.get("items") as []);
            if (BookmarksKey) {
              localStorage.setItem(BookmarksKey, JSON.stringify(tmpArr.slice(BookmarksConfig, tmpArr.length)))
            }
          }
        })

        bookmarks.watch("bookmarks.length", () => {
          if (BookmarksConfig != null) {
            const tmpArr = (bookmarks.bookmarks.get("items") as []);
            if (BookmarksKey) {
              localStorage.setItem(BookmarksKey, JSON.stringify(tmpArr.slice(BookmarksConfig, tmpArr.length)))
            }
          }
        });

        bookmarks.watch("activeBasemap", () => {
          const mobileSize =
            mapView.heightBreakpoint === "xsmall" ||
            mapView.widthBreakpoint === "xsmall";
          if (mobileSize) {
            bookmarksWidget.collapse();
          }
        });

        const legend = new Legend({
          view: mapView,
        });
        const legendWidget = new Expand({
          view: mapView,
          content: legend,
          expandTooltip: "Legend",
          group: "top-right",
        });
        legend.watch("activeBasemap", () => {
          const mobileSize =
            mapView.heightBreakpoint === "xsmall" ||
            mapView.widthBreakpoint === "xsmall";
          if (mobileSize) {
            legendWidget.collapse();
          }
        });

        let widgetIndexTopLeft = 0;
        let widgetIndexTopRight = 0;
        for (let i = 0; i < array.length; i++) {
          for (let j = 0; j < Constants.availableWidgetList.length; j++) {
            if (
              array[i].enable === 1 &&
              array[i].title === Constants.availableWidgetList[j]
            ) {
              if (
                array[i].position === "top-left" ||
                array[i].position === "top-right" ||
                array[i].position === "bottom-left" ||
                array[i].position === "bottom-right"
              ) {

                if (array[i].title === "searchWidget") {
                  mapView.ui.add(searchWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }

                if (array[i].title === "homeWidget") {
                  mapView.ui.add(homeWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : array[i].position == "top-left" ? widgetIndexTopLeft + 2 : array[i].position == "top-right" ? widgetIndexTopRight + 1 : undefined,
                  });

                  if (!array[i].index) {
                    widgetIndexTopLeft = widgetIndexTopLeft + 1;
                    widgetIndexTopRight = widgetIndexTopRight + 1;
                  }

                }
                if (array[i].title === "locateWidget") {
                  mapView.ui.add(locateWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : array[i].position == "top-left" ? widgetIndexTopLeft + 2 : array[i].position == "top-right" ? widgetIndexTopRight + 2 : undefined
                  });

                  if (!array[i].index) {
                    widgetIndexTopLeft = widgetIndexTopLeft + 1;
                    widgetIndexTopRight = widgetIndexTopRight + 1;
                  }
                }
                // widget X/Y
                if (array[i].title === "xyWidget") {
                  mapView.ui.add(xyWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }
                // widget basemap
                if (array[i].title === "basemapWidget") {
                  mapView.ui.add(basemapWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }
                // widget layerList
                if (array[i].title === "layerListWidget") {
                  mapView.ui.add(layerListWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }
                //widget bookmark
                if (array[i].title === "bookmarksWidget") {
                  mapView.ui.add(bookmarksWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }
                // widget legend
                if (array[i].title === "legendWidget") {
                  mapView.ui.add(legendWidget, {
                    position: array[i].position,
                    index: array[i].index ? array[i].index : undefined,
                  });
                }
              } else {
                //TODO Display a notification
                console.log("Widget not added: ", array[i].title);
              }
            }
          }
        }
      } catch (e) {
        errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMsgWidgetList);
      }
    }
    widgets(this.mapView, this.BookmarksKey);

    this.initOverlaysWidget();
    this.initRouteWidget();
    this.initAddDataWidget();
    this.initLocationTransportationWidget();

    // # STEP 1: Get the list of the ArcGIS Online BaseMap to add in the BasemapGallery Widget
    // Get it from the WebMapConfig, BaseMapList field
    // or use the defaultBaseMapTitles

    const defaultBaseMapTitles: string[] = ConfigService.getInstance()
      .webmapConfig
      ? ConfigService.getInstance().webmapConfig.attributes.BaseMapList
        ? ConfigService.getInstance().webmapConfig.attributes.BaseMapList
        : Constants.defaultBaseMapTitles
      : Constants.defaultBaseMapTitles;
    const allowedBasemapTitles = defaultBaseMapTitles;

    // # STEP 2: Get the Custtom BaseMap to add in the BaseMap Widget

    // Get it from the WebMapConfig, CustomBaseMapList field or use the defaultCustomBaseMapList
    // TODO: CREATE A FUNCTION
    const customBaseMapList: any = ConfigService.getInstance().webmapConfig
      ? ConfigService.getInstance().webmapConfig.attributes.CustomBaseMapList
        ? ConfigService.getInstance().webmapConfig.attributes.CustomBaseMapList
          .length
          ? ConfigService.getInstance().webmapConfig.attributes
            .CustomBaseMapList
          : JSON.stringify(Constants.defaultCustomBaseMapList)
        : JSON.stringify(Constants.defaultCustomBaseMapList)
      : JSON.stringify(Constants.defaultCustomBaseMapList);

    // # STEP 3: Parse the customBaseMapList dictionnary
    // for each elements in the dictionny, create a WebTileLayer (vector tile) and it in the vector tile array
    // Array containing the custom basemap

    const vectorBaseMapArray: any[] = [];
    // must catch error if the CustomBaseMapList is not well formatted
    function customBaseMap() {
      try {
        //Parse the string to Json format
        const arr = JSON.parse(customBaseMapList);
        // Loop throught the array.
        for (let i = 0; i < arr.length; i++) {
          // Fetch the first
          const customBaseMapItem: ICustomBaseMap = arr[i] as ICustomBaseMap;
          // check if the mandatory fields have populated value
          if (
            customBaseMapItem.templateURL &&
            customBaseMapItem.title &&
            customBaseMapItem.thumbnailURL
          ) {
            let vtLayer: any;
            let thumbnailURL: any;
            let title: any;
            // Create a vector tile from a third party source
            if (customBaseMapItem.title.toUpperCase() == "QMIC_MAP") {
              console.log("QMIC Basemap")
              
              title = customBaseMapItem.title.replace("_", " ");
              thumbnailURL = customBaseMapItem.thumbnailURL;

              vtLayer = new WMSLayer({
                url: customBaseMapItem.templateURL,
                title: title,
                visible: true,
                copyright: customBaseMapItem.copyright
                ? customBaseMapItem.copyright
                : customBaseMapItem.title,
                sublayers: [{
                  name: "qmic:QMIC_MAP",
                }]
              });
            }
            else {
              
              vtLayer = new WebTileLayer({
                urlTemplate: customBaseMapItem.templateURL,
                title: customBaseMapItem.title,
                visible: true,
                copyright: customBaseMapItem.copyright
                  ? customBaseMapItem.copyright
                  : customBaseMapItem.title,
              });

              thumbnailURL = customBaseMapItem.thumbnailURL;
              title = customBaseMapItem.title;
            }

            // Create a new basemap from the created vector tile
            const lcBaseMap = new Basemap({
              baseLayers: [vtLayer],
              thumbnailUrl: thumbnailURL,
              title: title,
            });

            // add the vectore basemap to the array
            vectorBaseMapArray.push(lcBaseMap);
          } else {
            //TODO Display a notification
            console.log("Custom vector tile not added");
          }
        }
      } catch (e) {
        errorMsg = standardizeErrorMsg(errorMsg, Constants.errorMsgCustomBaseMaps);
      }

    }

    customBaseMap();

    // # STEP 5: Creating the BasemapGallery widget

    this.pointFrom = reactive({ lat: null, lng: null });
    this.pointTo = reactive({ lat: null, lng: null });
    this.mapView.on("click", async (event) => {
      if (this.isFromEndActive == "start" || this.isFromEndActive == "end") {
        if (this.isFromEndActive == "start") {
          this.pointFrom.lat = event.mapPoint.latitude;
          this.pointFrom.lng = event.mapPoint.longitude;
        } else if (this.isFromEndActive == "end") {
          this.pointTo.lat = event.mapPoint.latitude;
          this.pointTo.lng = event.mapPoint.longitude;
        }
        this.isFromEndActive = null;
        this.mapView.container.style.cursor = "default";
      }
    });
    this.drawToolBar = new Draw({ view: this.mapView });
    this.isDrawing = false;

    const TmpLocalStorage = localStorage.getItem(this.BookmarksKey) || null;
    this.mapView.when((): any => {
      LoaderService.getInstance().hideLoader();
      this.setSketchView();

      // this.setupClickHandler();

      BookmarksConfig = (this.map.bookmarks.get("items") as any[]).length;
      if (TmpLocalStorage) {
        for (let index = 0; index < JSON.parse(TmpLocalStorage).length; index++) {
          this.map.bookmarks.add(JSON.parse(TmpLocalStorage)[index])
        }
      }

    });

    //this.addVectorTile();

    // Nofitify error if any
    if (errorMsg && errorMsg.length) {
      NotificationService.getInstance().showNotification(
        "Error",
        errorMsg,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
    }
  }

  static getInstance(options?: { containerElement: HTMLDivElement }) {
    if (!MapService.instance && options) {
      MapService.instance = new MapService(options.containerElement);
    }
    return MapService.instance;
  }

  /**
   * Register token
   */
  private registerToken(): void {
    esriId.registerToken({
      server: ConfigService.getInstance().config.portal.url,
      token: ConfigService.getInstance().webmapConfig?.esritoken || "",
    });
  }

  /**
   * Init map
   */
  initMap(): WebMap {
    // Register a token for the app
    this.registerToken();

    if (!ConfigService.getInstance().webmapConfig || !ConfigService.getInstance().webmapConfig.attributes || ConfigService.getInstance().webmapConfig.attributes.length < 1 || !ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.portal.webmapIdField]) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMsgInvalidWebMapConfig,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
      return null;
    }

    //Init map
    const map = new WebMap({
      portalItem: {
        id: ConfigService.getInstance().webmapConfig.attributes[
          ConfigService.getInstance().config.portal.webmapIdField
        ],
      },
    });

    map.watch("loadStatus", (loadStatus: string) => {
      if (loadStatus == "failed") {
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMsgInvalidWebMapItem + "\n" +
          "WebMap Item ID:" + map.portalItem.id,
          "red",
          false,
          Constants.mediumNotificationTimeOut
        );
        this.mapView.ui.empty("top-left");
        this.mapView.ui.empty("bottom-left");
        this.mapView.ui.empty("top-right");
        this.mapView.ui.empty("bottom-right");
      }
    });

    return map;
  }

  initRouteWidget() {
    const div = document.createElement("div");
    const routeComponent = createApp(Route).mount(div);
    this.routeExpandComponent = new Expand({
      expandIconClass: "esri-icon-map-pin",
      view: this.mapView,
      content: routeComponent.$el,
      expandTooltip: "Route Planning",
      group: "top-left",
    });
    if (this.mapView) {
      this.mapView.ui.add(this.routeExpandComponent, "top-left");
    }
  }

  initOverlaysWidget() {
    const div = document.createElement("div");
    const overlayComponent = createApp(Overlays).mount(div);
    this.overlayExpandComponent = new Expand({
      expandIconClass: "esri-icon-collection",
      view: this.mapView,
      content: overlayComponent.$el,
      expandTooltip: "Overlay Management",
      group: "top-right",
    });

    if (this.mapView) {
      this.mapView.ui.add(
        this.overlayExpandComponent,
        {
          position: "top-right",
          index: 0,
        });
    }
  }

  initMatrixWidget(): void {
    const div = document.createElement("div");
    const matrixComponent = createApp(Matrix).mount(div);
    this.matrixExpandComponent = new Expand({
      expandIconClass: "esri-icon-dashboard",
      view: this.mapView,
      content: matrixComponent.$el,
      expandTooltip: "Origin-Destination Travel Time matrix",
      group: "top-left",
    });
    if (this.mapView) {
      this.mapView.ui.add(this.matrixExpandComponent, "top-left");
    }
  }

  initAddDataWidget(): void {
    const div = document.createElement("div");
    const addDataComponent = createApp(AddDataWidget).mount(div);
    this.addDataComponent = new Expand({
      expandIconClass: "esri-icon-upload",
      view: this.mapView,
      content: addDataComponent.$el,
      expandTooltip: "Add Data",
      group: "top-left",
    });
    if (this.mapView) {
      this.mapView.ui.add(this.addDataComponent, "top-left");
    }
  }

  initLocationTransportationWidget(): void {
    const div = document.createElement("div");
    const locationTransportationComponent = createApp(LocationTransportation).mount(div);
    this.locationTransportationComponent = new Expand({
      expandIconClass: "esri-icon-table",
      view: this.mapView,
      content: locationTransportationComponent.$el,
      expandTooltip: "Manage Event Locations",
      group: "top-left",
    });
    if (this.mapView) {
      this.mapView.ui.add(this.locationTransportationComponent, "top-left");
    }
  }

  zoomToFeature(geometry: any): void {
    this.mapView.goTo(geometry.extent);
  }

  addDataAddLayer(layer: any) {
    this.addDataGroupLayer.add(layer);
    eventBus().emitter.emit("addLayer");
  }

  drawJSON(inputGeoJSON: string, type?: string) {
    this.cleanRoute();

    const renderer = ({
      type: "simple",
      symbol: ({
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        width: 5,
        color: type == null ? "#0075D1" : "black",
        style: type == null ? "solid" : "dash-dot",
      } as any) as SimpleRenderer,
    } as any) as SimpleRenderer;

    this.geoJSONLayer = new GeoJSONLayer({
      url: inputGeoJSON,

      renderer: renderer,
      title: "Computed route",
    });

    watchUtils.whenTrue(this.geoJSONLayer, "loaded", () => {
      this.mapView.goTo(this.geoJSONLayer.fullExtent);
    });

    this.RouteGroupLayer.layers.add(this.geoJSONLayer, 0); // add layer computed route in the grouplayer
  }

  cleanMap() {
    this.cleanRoute();
    this.cleanGroupLayerRoute();
    this.cleanAllPin();
  }

  cleanAllPin() {
    //this.mapView.graphics.removeAll()
    this.startEndLayer.removeAll();
    this.map.remove(this.startEndLayer);
  }

  cleanPin(pin: any) {
    // this.mapView.graphics.remove(pin)
    this.startEndLayer.remove(pin);
    if (pin === this.graphicFrom) {
      this.graphicFrom = null;
    } else if (pin === this.graphicTo) {
      this.graphicTo = null;
    }
  }
  cleanRoute() {
    this.map.remove(this.startEndLayer);
    this.map.remove(this.geoJSONLayer);
    this.RouteGroupLayer.remove(this.geoJSONLayer);
  }

  cleanGroupLayer() {
    this.map.layers.remove(this.RouteGroupLayer);
    this.RouteGroupLayer = null;
  }
  cleanGroupLayerRoute() {
    this.map.layers.remove(this.RouteGroupLayer);
    this.RouteGroupLayer = null;
  }

  drawPoints(latFrom: number, lngFrom: number, latTo: number, lngTo: number) {
    this.drawStartPoint(latFrom, lngFrom);
    this.drawEndPoint(latTo, lngTo);
  }

  drawStartPoint(latFrom: number, lngFrom: number): void {
    this.createRouteGroupLayer();

    const graphicFrom = new Point({
      longitude: lngFrom,
      latitude: latFrom,
    });

    const convertedGeom = webMercatorUtils.geographicToWebMercator(graphicFrom);

    if (this.graphicFrom) {
      this.graphicFrom.geometry = convertedGeom as Point;
    } else {
      // Create a graphic and add the geometry and symbol to it
      this.graphicFrom = new Graphic({
        geometry: convertedGeom as Point,
        symbol: this.markerSymbolFrom,
      });
    }
    this.startEndLayer.graphics.add(this.graphicFrom); // add symbole point start

    this.RouteGroupLayer.add(this.startEndLayer);
  }

  addVectorTile() {
    // load a new vector tile layer from JSON object
    console.log("Loading vector tile")
    /*const vtLayer = new VectorTileLayer({
      //url: "https://dev-gisdatamanagement.citec.ch/NationalGeographicStyle.json",
    //});
    style: {
      "version": 8,
      "center":[
                51.53539213391332,
                25.279706759897394
                ],
      "zoom":12,
      "name": "Qmic Map",
      "sources": {
        "qmic": {
          "type": "vector",
          "tiles":[ "https://maps.masrak.com/maptiles/qmic9/{z}/{x}/{y}.pbf?macid=51QATARddfbgjhjljDFIFAzxvfdghytiDWORLDsdasczxvfhgfDCUPsafggvbfddfDpkemblfmsDhfk02kl5Dd9xl6g549gs"]
        }
      },
      "sprite":"https://maps.masrak.com/static/wain_sprite_metro_6_7",
      "glyphs":"https://maps.masrak.com/font/{fontstack}/{range}.pbf",  
      "layers": [
                 {
                   "id": "background",
                   "type": "background",
                   "paint":{
                   "background-color":"#a1cff2"
                 }
                 },
                 {
                   "id":"m_layer",
                   "type":"line",
                   "source":"qmic",
                   "source-layer":"m_layer",
                   "layout":{
                   "line-cap":"butt",
                   "visibility":"none"
                 },
                   "paint":{
                   "line-color":"#0000FF",
                   "line-width":1
                 }
                 }
                 ],
      "id": "qmic-map"
      }
      );
  
      /*style: {
        layers: [
          {
            layout: {},
            paint: {
              "fill-color": "#F0ECDB"
            },
            source: "esri",
            minzoom: 0,
            "source-layer": "Land",
            type: "fill",
            id: "Land/0"
          },
          {
            layout: {},
            paint: {
              "fill-pattern": "Landpattern",
              "fill-opacity": 0.25
            },
            source: "esri",
            minzoom: 0,
            "source-layer": "Land",
            type: "fill",
            id: "Land/1"
          },
          {
            layout: {},
            paint: {
              "fill-color": "#93CFC7"
            },
            source: "esri",
            minzoom: 0,
            "source-layer": "Marine area",
            type: "fill",
            id: "Marine area/1"
          },
          {
            layout: {},
            paint: {
              "fill-pattern": "Marine area",
              "fill-opacity": 0.08
            },
            source: "esri",
            "source-layer": "Marine area",
            type: "fill",
            id: "Marine area/2"
          },
          {
            layout: {
              "line-cap": "round",
              "line-join": "round"
            },
            paint: {
              "line-color": "#cccccc",
              "line-dasharray": [7, 5.33333],
              "line-width": 1
            },
            source: "esri",
            minzoom: 1,
            "source-layer": "Boundary line",
            type: "line",
            id: "Boundary line/Admin0/0"
          },
          {
            layout: {
              "text-font": ["Risque Regular"],
              "text-anchor": "center",
              "text-field": "{_name_global}"
            },
            paint: {
              "text-halo-blur": 1,
              "text-color": "#AF420A",
              "text-halo-width": 1,
              "text-halo-color": "#f0efec"
            },
            source: "esri",
            "source-layer": "Continent",
            type: "symbol",
            id: "Continent"
          },
          {
            layout: {
              "text-font": ["Atomic Age Regular"],
              "text-field": "{_name}",
              "text-transform": "none"
            },
            paint: {
              "text-halo-blur": 1,
              "text-color": "#AF420A",
              "text-halo-width": 1,
              "text-halo-color": "#f0efec"
            },
            source: "esri",
            minzoom: 2,
            "source-layer": "Admin0 point",
            maxzoom: 10,
            type: "symbol",
            id: "Admin0 point/large"
          }
        ],
        glyphs:
          "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/resources/fonts/{fontstack}/{range}.pbf",
        version: 8,
        sprite:
          "https://www.arcgis.com/sharing/rest/content/items/7675d44bb1e4428aa2c30a9b68f97822/resources/sprites/sprite",
        sources: {
          esri: {
            url:
              "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer",
            type: "vector"
          }
        }
      }
    });*/

    const vtLayer = new WMSLayer({
      //url: "https://ows.terrestris.de/osm/service",
      url: "http://maps.masrak.com/geoserver/gwc/service",
      //url: "https://maps.masrak.com/geoserver/gwc/service/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=qmic:QMIC_MAP&TILED=true&SRS=EPSG:900913&WIDTH=256&HEIGHT=256&CRS=EPSG:3857",
      sublayers: [{
        name: "qmic:QMIC_MAP",
      }]
    });

    vtLayer.load().then(() => {
      const names = vtLayer.allSublayers
        .filter((sublayer) => !sublayer.sublayers) // Non-grouping layers will not have any "sublayers".
        .map((sublayer) => sublayer.name);
      console.log("Names of all child sublayers", names.join());
    });
    /*const vtLayer = new VectorTileLayer({
      url: "https://dev-gisdatamanagement.citec.ch/NationalGeographicStyle.json",
    });*/
    this.map.add(vtLayer);
  }

  createRouteGroupLayer() {
    if (this.RouteGroupLayer === null) {
      this.RouteGroupLayer = new GroupLayer({
        title: "Route planning",
        visible: true,
        visibilityMode: "independent",
        layers: [],
      });

      //this.mapView.map.add(RouteGroupLayer);
      if (this.mapView.map.layers.length > 0) {
        this.mapView.map.add(
          this.RouteGroupLayer,
          this.mapView.map.layers.length
        ); // adds the layer to the map
      } else {
        this.mapView.map.add(this.RouteGroupLayer);
      }
    }
  }

  drawEndPoint(latTo: number, lngTo: number): void {
    this.createRouteGroupLayer();

    const graphicTo = new Point({
      longitude: lngTo,
      latitude: latTo,
    });

    const convertedGeom = webMercatorUtils.geographicToWebMercator(graphicTo);

    if (this.graphicTo) {
      this.graphicTo.geometry = convertedGeom;
    } else {
      // Create a graphic and add the geometry and symbol to it
      this.graphicTo = new Graphic({
        geometry: convertedGeom,
        symbol: this.markerSymbolTo,
      });
    }
    this.startEndLayer.graphics.add(this.graphicTo);
    this.RouteGroupLayer.add(this.startEndLayer);
  }

  pinLocation(startEnd: "start" | "end" | null) {
    this.isFromEndActive = startEnd;
    this.mapView.container.style.cursor = "crosshair";
  }

  stopPinLocation() {
    this.isFromEndActive = null;
    this.mapView.container.style.cursor = "default";
  }
  /*
   ** Draw methods
   */

  startDrawing(type: "overlay" | "area" | "areaV8" | "custom-road", version?: string): void {
    this.currentDraw = type;
    this.overlayExpandComponent.collapse();
    this.isDrawing = true;
    this.drawLayer.graphics.removeAll();
    let action: __esri.DrawAction;
    if (type === "overlay") {
      action = this.drawToolBar.create("polygon", { mode: "click" });
      NotificationService.getInstance().showNotification(
        "Draw new Overlay",
        "Click on the map to draw an area where to locate the area. Double click to terminate the drawing of the area",
        "blue",
        false
      );
      action.on(["vertex-add", "cursor-update"], (evt) => {
        this.updatePolygonVertices(evt);
      });
      action.on(["draw-complete"], (evt) => {
        this.onDrawEnd(evt);
      });
    } else if (type === "area") {
      action = this.drawToolBar.create("polygon", { mode: "click" });
      NotificationService.getInstance().showNotification(
        "Draw new Restricted Area",
        "Click on the map to draw a restricted area. Double click to terminate the drawing of the area.<br/>The geometry must build a clockwise polygon (without holes, not selfintersecting).<br/>Minimum 3 points and maximum 10 points.",
        "blue",
        false
      );
      action.on(["vertex-add", "cursor-update"], (evt) => {
        this.updatePolygonVertices(evt);
      });
      action.on(["draw-complete"], (evt) => {
        this.onDrawEnd(evt, version);
      });
    } else if (type === "areaV8") {
      action = this.drawToolBar.create("rectangle", { mode: "click" });
      NotificationService.getInstance().showNotification(
        "Draw new Restricted Area",
        "Click on the map to draw a restricted area.<br/>The geometry is a Rectangle.",
        "blue",
        false
      );
      action.on(["vertex-add", "cursor-update"], (evt) => {
        this.updateRectangleVertices(evt);
      });
      action.on(["draw-complete"], (evt) => {
        this.onDrawEnd(evt);
      });
    } else if (type === "custom-road") {
      action = this.drawToolBar.create("polyline", { mode: "click" });
      NotificationService.getInstance().showNotification(
        "Draw new custom road",
        "Click on the map to draw a new custom road. Double click to terminate the drawing of the road",
        "blue",
        false
      );
      action.on(["vertex-add", "cursor-update"], (evt) => {
        this.updatePolylineVertices(evt);
      });
      action.on(["draw-complete"], (evt) => {
        this.onDrawEnd(evt);
      });
    }
  }

  onDrawEnd(event: any, version?: string): void {
    NotificationService.getInstance().hideNotification();
    if (this.currentDraw === "custom-road") {
      this.updatePolylineVertices(event);
    } else {
      this.updatePolygonVertices(event);
    }
    if (this.currentDraw === "overlay") {
      const div = document.createElement("div");
      const overlayComponent = createApp(NewOverlayDialog).mount(div);
      document.body.appendChild(div);
    } else if (this.currentDraw === "area") {
      const geometry = this.drawLayer.graphics.getItemAt(0).geometry;
      let bounding;
      if (version === "V7;V8") {
        let min = webMercatorUtils.xyToLngLat(geometry.extent.xmin, geometry.extent.ymin);
        min = [min[1], min[0]];
        let max = webMercatorUtils.xyToLngLat(geometry.extent.xmax, geometry.extent.ymax);
        max = [max[1], max[0]];
        bounding = "[[" + max[0] + ", " + max[1] + "], [" + min[0] + ", " + min[1] + "]]";
      }
      const area: IRestrictedArea = {
        Name: "Restriction " + (RestrictedAreasService.getInstance().tableSize.size + 1),
        StartDate: new Date(new Date().setHours(0, 1, 0, 0)),
        EndDate: new Date(new Date().setHours(23, 59, 0, 0)),
        OVERLAYID: store.state.overlay.OBJECTID,
        geometry,
        BoundingBox: bounding,
      } as IRestrictedArea;
      RestrictedAreasService.getInstance().addRestrictedArea(area);
      this.drawLayer.graphics.removeAll();
      this.overlayExpandComponent.expand();
    } else if (this.currentDraw === "areaV8") {
      const geometry = this.drawLayer.graphics.getItemAt(0).geometry;

      let min = webMercatorUtils.xyToLngLat(geometry.extent.xmin, geometry.extent.ymin);
      min = [min[1], min[0]];
      let max = webMercatorUtils.xyToLngLat(geometry.extent.xmax, geometry.extent.ymax);
      max = [max[1], max[0]];
      const bounding = "[[" + max[1] + ", " + min[0] + "], [" + min[1] + ", " + max[0] + "]]";

      const [startX, startY] = event.vertices[0];
      const [endX, endY] = event.vertices[1];

      const corners = [
        [startX, startY],
        [endX, startY],
        [endX, endY],
        [startX, endY],
        [startX, startY]
      ];

      const geometry2 = {
        type: "polygon",
        rings: corners,
        spatialReference: this.mapView.spatialReference,
      };

      const area: IRestrictedArea = {
        Name: "Restriction " + (RestrictedAreasService.getInstance().tableSize.size + 1),
        StartDate: new Date(new Date().setHours(0, 1, 0, 0)),
        EndDate: new Date(new Date().setHours(23, 59, 0, 0)),
        OVERLAYID: store.state.overlay.OBJECTID,
        geometry: geometry2,
        BoundingBox: bounding,
      } as IRestrictedArea;
      RestrictedAreasService.getInstance().addRestrictedArea(area);
      this.drawLayer.graphics.removeAll();
      this.overlayExpandComponent.expand();
    } else if (this.currentDraw === "custom-road") {
      const geometry = this.drawLayer.graphics.getItemAt(0).geometry;
      const customRoad: ICustomRoads = {
        Name: "Custom Road " + (CustomRoadsService.getInstance().tableSize.size + 1),
        OVERLAYID: store.state.overlay.OBJECTID,
        geometry,
      } as ICustomRoads;
      CustomRoadsService.getInstance().addCustomRoad(customRoad);
      this.drawLayer.graphics.removeAll();
      this.overlayExpandComponent.expand();
    }
    this.currentDraw = null;
    //createApp(NewOverlayDialog).mount(document.body);
  }

  updatePolygonVertices(event: any) {
    // create a polyline from returned vertices
    if (event.vertices.length > 1) {
      const result = this.createPolygonGraphic(event);
    }
  }

  // create a new graphic presenting the polyline that is being drawn on the view
  createPolygonGraphic(event: any) {
    const vertices = event.vertices;
    this.drawLayer.graphics.removeAll();

    // a graphic representing the polyline that is being drawn
    const graphic = new Graphic({
      geometry: {
        type: "polygon",
        rings: vertices,
        spatialReference: this.mapView.spatialReference,
      } as any,
      symbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol
        color: [4, 90, 141],
        style: "backward-diagonal",
        outline: {
          // autocasts as SimpleLineSymbol
          color: "grey",
          width: 2,
        },
      } as any,
    });

    this.drawLayer.add(graphic);
  }
  updatePolylineVertices(event: any) {
    // create a polyline from returned vertices
    if (event.vertices.length > 1) {
      const result = this.createPolylineGraphic(event);
    }
  }

  // create a new graphic presenting the polyline that is being drawn on the view
  createPolylineGraphic(event: any) {
    const vertices = event.vertices;
    this.drawLayer.graphics.removeAll();

    // a graphic representing the polyline that is being drawn
    const graphic = new Graphic({
      geometry: {
        type: "polyline",
        paths: vertices,
        spatialReference: this.mapView.spatialReference,
      } as any,
      symbol: {
        type: "simple-line", // autocasts as new SimpleFillSymbol
        color: [4, 90, 141],
        width: 4,
        cap: "round",
        join: "round",
      } as any,
    });

    this.drawLayer.add(graphic);
  }

  updateRectangleVertices(event: any) {
    // create a polyline from returned vertices
    if (event.vertices.length > 1) {
      const result = this.createRectangleGraphic(event);
    }
  }

  // create a new graphic presenting the polyline that is being drawn on the view
  createRectangleGraphic(event: any) {
    const vertices = event.vertices;
    this.drawLayer.graphics.removeAll();

    if (vertices.length === 2) {
      const [startX, startY] = vertices[0];
      const [endX, endY] = vertices[1];

      const corners = [
        [startX, startY],
        [endX, startY],
        [endX, endY],
        [startX, endY],
        [startX, startY]
      ];

      const graphic = new Graphic({
        geometry: {
          type: "polygon",
          rings: corners,
          spatialReference: this.mapView.spatialReference,
        } as any,
        symbol: {
          type: "simple-fill", // autocasts as new SimpleFillSymbol
          color: [4, 90, 141],
          style: "backward-diagonal",
          outline: {
            // autocasts as SimpleLineSymbol
            color: "grey",
            width: 2,
          },
        } as any,
      });

      this.drawLayer.add(graphic);
    }
  }

  public findLayerByTitle(title: string): any {
    return this.map.layers.find((layer) => {
      return layer.title === title;
    });
  }

  // Setup sketchview
  setSketchView() {
    this.sketchViewModel = new SketchViewModel({
      view: this.mapView,
      layer: this.startEndLayer,

      updateOnGraphicClick: true,
      pointSymbol: {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "circle",
      },

      defaultUpdateOptions: {
        tool: "move",
      },
    });

    this.sketchViewModel.on("update", (evt) => {
      if (evt.toolEventInfo && evt.toolEventInfo.type === "move-stop") {
        const x = evt.graphics[0].geometry.get("x") as number;
        const y = evt.graphics[0].geometry.get("y") as number;
        const coord = webMercatorUtils.xyToLngLat(x, y);
        if (evt.graphics[0] === this.graphicFrom) {
          this.pointFrom.lat = coord[1];
          this.pointFrom.lng = coord[0];
          setTimeout(() => {
            this.sketchViewModel.update(this.graphicFrom);
          }, 400);
        } else if (evt.graphics[0] === this.graphicTo) {
          this.pointTo.lat = coord[1];
          this.pointTo.lng = coord[0];
          setTimeout(() => {
            this.sketchViewModel.update(this.graphicTo);
          }, 400);
        }
      }
    });
  }

  // // Move pin
  setupClickHandler() {
    //     this.mapView.on("click", (event) => {
    //         this.mapView.hitTest(event).then((response) => {
    //             const results = response.results;
    //             if (results.length > 0) {
    //                 for (const result of results) {
    //                     if (result.graphic === this.graphicFrom) {
    //                         console.log(result.graphic);
    //                         this.sketchViewModel.update(result.graphic);
    //                     }
    //                 }
    //             }
    //         });
    //     });
  }

  setMaker() {
    this.markerSymbolFrom = new PictureMarkerSymbol({
      url: "http://static.arcgis.com/images/Symbols/Basic/GreenStickpin.png",
      width: "30px",
      height: "30px",
    });

    this.markerSymbolTo = new PictureMarkerSymbol({
      url: "http://static.arcgis.com/images/Symbols/Basic/RedStickpin.png",
      width: "30px",
      height: "30px",
    });
  }

  async CreateBookmarksKey() {
    const sysEnv = ConfigService.getInstance().targetEnv
    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();
    const userId = ContextService.getInstance().userId

    if (sysEnv && orga && event && userId) {
      this.BookmarksKey = sysEnv + orga.id + event.id + userId
    }
  }
}
