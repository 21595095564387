
import { OverlayService } from "@/services/OverlayService";
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import { IOverlay } from "../../../../../interfaces/IOverlay";
import { MapService } from "@/services/MapService";
import { ContextService } from "@/services/ContextService";
import eventBus from "@/services/EventBus";

@Options({
  props: {
    overlay: null,
  },
})
export default class OverlayCard extends Vue {
  isEditor = false;

  mounted() {
    this.isEditor = ContextService.getInstance().isEditor();
    eventBus().emitter.emit("isEditorEvent", {
      isEditor: ContextService.getInstance().isEditor(),
    });
  }

  openOverlay() {
    store.commit("setOverlay", { ...(this.$props as any).overlay });
  }

  async onDeleteOverlay() {
    await OverlayService.getInstance().deleteOverlay(
      (this.$props as any).overlay.name,
      (this.$props as any).overlay.OBJECTID
    );
    store.commit("deleteOverlay");
  }

  onZoomOverlay() {
    MapService.getInstance().zoomToFeature(
      { ...(this.$props as any).overlay }.geometry
    );
  }
}
