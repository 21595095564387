
import { IOverlay } from "@/interfaces/IOverlay";
import { MapService } from "@/services/MapService";
import { OverlayService } from "@/services/OverlayService";
import { UserService } from "@/services/UserService";
import { store } from "@/store";
import { Options, Vue } from "vue-class-component";
import { IEvent } from "@/interfaces/IEvent";
import { ApiService } from "@/services/ApiService";
import { IOrganization } from "@/interfaces/IOrganization";
import { ContextService } from "@/services/ContextService";
import { Model } from "vue-property-decorator";
import { ConfigService } from "@/services/ConfigService";
import { NotificationService } from "@/services/NotificationService";

@Options({
  props: {},
})
export default class NewOverlayDialog extends Vue {
  //overlay: IOverlay = {} as IOverlay;
  overlay: IOverlay = {
    Name: "",
    Organisation: null,
    Comments: "",
    Event: null,
  } as IOverlay;
  overlayName = "hey";
  overlayComments: string;
  error: string;
  isError = false;
  isInput = false;
  isSelect = true;
  saveOverlay = false;

  async mounted() {
    (this.$refs.nameRef as any).addEventListener("calciteInputInput", () => {
      if((this.$refs.nameRef as any).value.length == 1)
        this.isInput = true;
      else if ((this.$refs.nameRef as any).value.length == 0)
        this.isInput = false;
    })

    // TODO IMPROVE THE API ERROR IF getCurrentOrg or getCurrentEvent does not return a value
    // An error notification message should be displayed
    // This page should be closed

    // Retrieve the organisation Name and ID
    const organisation = await ApiService.getInstance().getCurrentOrg();
    if (organisation) {
      this.overlay.Organisation = organisation.name;
      this.overlay.OrganisationID = organisation.id;

      // retrieve the event Name and ID
      const event = await ApiService.getInstance().getCurrentEvent();
      if (event == null) {
        this.overlay.Event = "Error retreiving the event";
        this.overlay.EventID = "-1";
      }
      else {
        this.overlay.Event = event.name;
        this.overlay.EventID = event.id;
      }
    }
    else {
      this.overlay.Organisation = "Error retreiving the organisation";
      this.overlay.OrganisationID = "-1";
    }
    
    $(this.$refs.vehicleTypesRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: []
    });
    $(this.$refs.dayLabelsRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: []
    });
    $(this.$refs.oslRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: []
    });
    $(this.$refs.routingServiceVersionRef).kendoMultiSelect({
      dataSource: ["V8", "V7"]
    });
    const vehicleTypes = await ApiService.getInstance().getVehicleTypes();
    $(this.$refs.vehicleTypesRef).data("kendoMultiSelect").setDataSource(vehicleTypes);
    const dayLabels = await ApiService.getInstance().getDayLabels();
    $(this.$refs.dayLabelsRef).data("kendoMultiSelect").setDataSource(dayLabels);
    const osl = await ApiService.getInstance().getOsl();
    $(this.$refs.oslRef).data("kendoMultiSelect").setDataSource(osl);
    $(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").value("V8");
    const multiselect_change = (e : any) => {
      if(e.sender.value().length == 1)
        this.isSelect = true;
      else if (e.sender.value().length == 0)
        this.isSelect = false;
    }
    $(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").bind("change", multiselect_change);
  }

  async onSave() {
    this.resetForm();
    if (this.isValidForm()) {
      this.saveOverlay = true;
      let organization= this.overlay.Organisation;
      let event = this.overlay.Event;
      let overlayName = (this.$refs.nameRef as any).value;
      const isNameOk = await this.checkName(overlayName, organization, event);
      if(!isNameOk) {
        if ($(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").value() != "") {
          if (
          MapService.getInstance().drawLayer.graphics &&
          MapService.getInstance().drawLayer.graphics.length > 0
          ) {
            this.error = "";
            this.isError = false;
            const geometry =
              MapService.getInstance().drawLayer.graphics.getItemAt(0).geometry;
            const organizationId = this.overlay.OrganisationID;
            const eventId = this.overlay.EventID;
            let vehicleType: string;
            let vehicleType2: string;
            let dayLabel: string;
            let dayLabel2: string;
            let osl: string;
            let osl2: string;
            let routingServiceV;
            var idList : any[] = [];
            var vehicleTypes = $(this.$refs.vehicleTypesRef).data("kendoMultiSelect").value();
            vehicleTypes.forEach((element : any) => {
              idList.push(element.id);
            });
            vehicleType = idList.join(";");
            var nameList : any[] = [];
            vehicleTypes.forEach((element : any) => {
              nameList.push(element.name);
            });
            vehicleType2 = nameList.join(";");
            idList = [];
            var dayLabels = $(this.$refs.dayLabelsRef).data("kendoMultiSelect").value();
            dayLabels.forEach((element : any) => {
              idList.push(element.id);
            });
            dayLabel = idList.join(";");
            nameList = [];
            dayLabels.forEach((element : any) => {
              nameList.push(element.name);
            });
            dayLabel2 = nameList.join(";");
            idList = [];
            var osll = $(this.$refs.oslRef).data("kendoMultiSelect").value();
            osll.forEach((element : any) => {
              idList.push(element.id);
            });
            osl = idList.join(";");
            nameList = [];
            osll.forEach((element : any) => {
              nameList.push(element.name);
            });
            osl2 = nameList.join(";");
            nameList = [];
            var routingServiceV2 = $(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").value();
            routingServiceV2.forEach((element : any) => {
              nameList.push(element);
            });
            if(nameList.length > 1) {
              routingServiceV = "V7;V8";
            } else {
              routingServiceV = nameList.join();
            }
            const creator = ContextService.getInstance().user.name+" "+ContextService.getInstance().user.surname;
            const overlay: IOverlay = {
              Name: (this.$refs.nameRef as any).value,
              Comments: (this.$refs.commentsRef as any).value,
              Organisation: organization,
              OrganisationID: organizationId,
              VehicleTypesIDs: vehicleType,
              VehicleTypes: vehicleType2,
              DayLabelIDs: dayLabel,
              DayLabels: dayLabel2,
              OperationalServiceLevelIDs: osl,
              OperationalServiceLevels: osl2,
              PublishedStatus: 0,
              Event: event,
              EventID: eventId,
              Active: 1,
              CreationDate: new Date(),
              CreatedBy: creator,
              geometry,
              RoutingServiceVersion: routingServiceV,
            } as IOverlay;
            try {
              await OverlayService.getInstance().addOverlay(overlay);
            } catch(e) {
              NotificationService.getInstance().showNotification(
                "New Overlay",
                "The new overlay cannot be created.",
                "red",
                false
              );
            } finally {
              NotificationService.getInstance().showNotification(
                "New Overlay",
                "The new overlay has been successfully created.",
                "green",
                false
              );
            }
            
            this.saveOverlay = false;
            store.commit("addOverlay");
            MapService.getInstance().drawLayer.removeAll();
            this.$el.remove();
            MapService.getInstance().overlayExpandComponent.expanded = true;
          }
        } else {
          this.error = "You must select a routing service version."
          this.isError = true;
          this.saveOverlay = false;
        }
      } else {
        this.error = "This overlay name already exist, please change it."
        this.isError = true;
        this.saveOverlay = false;
      }
    }

    /* if (this.overlayName) {
      
    } */
  }

  isValidForm(): boolean {
    let valid = true;
    var name: any = this.$refs.nameRef;
    if(name && name.value) {
      var newName = name.value.replace(/[^a-z0-9]/gi,'');
      (this.$refs.nameRef as any).value = newName;
      if (!newName || newName.length < 1) {
        (this.$refs.nameRef as any).status = "invalid";
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  }

  updateOverlayFromForm(): void {
    this.resetForm();
    const name: any = this.$refs.nameRef;
    const events = this.$refs.eventRef;
    if (!name.value || name.value.length < 1) {
      (this.$refs.nameRef as any).status = "invalid";
    }
  }

  resetForm(): void {
    (this.$refs.nameRef as any).status = "idle";
  }

  onClose() {
    MapService.getInstance().drawLayer.removeAll();
    this.$el.remove();
    MapService.getInstance().overlayExpandComponent.expanded = true;
  }

  async checkName(name : string, organization : string, event : string): Promise<boolean> {
    const result = await OverlayService.getInstance().getOverlays();
    return result.some((data: any) => data.Name === name && data.Organisation === organization && data.Event === event);
  }

}
