
import { IEventLocation } from "@/interfaces/IEventLocation";
import {
  ISlotAsFeature,
  IStatus,
  ITravelDefinitionAsFeature,
  ITravelTimePerSlotAsFeature,
} from "@/interfaces/ITravelTime";
import { MapService } from "@/services/MapService";
import { Options, Vue } from "vue-class-component";
import { MatrixServices } from "../../../services/MatrixServices";
import { MapForMatrixService } from "../../../services/MapForMatrixService";
import Graphic from "@arcgis/core/Graphic";
import { ILatLng } from "../../../interfaces/IVia";
import { ViaR } from "../../../model/viaR";
import {
  secondsToReadable,
  timeStringToSeconds,
} from "../../../utils/dateUtils";
import { NotificationService } from "@/services/NotificationService";
import { ContextService } from "@/services/ContextService";
import { ApiService } from "../../../services/ApiService";
import eventBus from "@/services/EventBus";
import { Constants } from "@/services/Constants";
import { LoaderService } from "@/services/LoaderService";

@Options({
  components: {},
  props: {},
})
export default class Matrix extends Vue {
  matrixService: MatrixServices;
  eventLocations: IEventLocation[] = [];
  transportations: any[] = [];
  eventTransportation: any[] = [];
  selectedTravelDefinition: ITravelDefinitionAsFeature;
  selectedTravelsTimePerSlot: ITravelTimePerSlotAsFeature[] = [];
  fromIdSelected: IEventLocation = null;
  toIdSelected: IEventLocation = null;
  slots: ISlotAsFeature[] = [];
  days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  slotToDelete: ISlotAsFeature;
  travelTimeToDelete: ITravelTimePerSlotAsFeature;
  loaderCalculateARoute = false;
  loaderGenerateMatrix = false;
  mapForMatriceService: MapForMatrixService;
  isAddingStep: any; // is IHandle
  isAddingStepInfo = false;
  viaPoints: ViaR[] = [];
  viaPointsHtml: ViaR[] = [];
  valueListVia: any = null;
  isSavingVia = false;
  listsAreValid = false;
  viaIsDirty = false;
  Osl: any[] = [];
  ClientGroups: any[] = [];
  colors = [
    "#d92b30",
    "#0095ba",
    "#3cccb4",
    "#ab52b3",
    "#ffb259",
    "#ffdf3c",
    "#eb82eb",
    "#c27c30",
    "#a0d17d",
    "#f260a1",
  ];
  currentStatus: IStatus = null;

  secondsToReadable = secondsToReadable;
  changeListener: any = null;
  validateForm: any = null;
  formatedArray: number[][];
  isImportDisable = false;
  viaIsRemove = false;
  id: any;
  mode = "car";
  traffic: string;
  slotValidityAlreadyChecked = false;

  // to generate table id and select it by name
  tabList = ["route", "calculate", "slots", "import", "settings"];

  beforeCreate(): void {
    this.matrixService = new MatrixServices();
  }

  async mounted() {
    const overlays = await ApiService.getInstance().getCustomOverlays(
      null,
      null,
      null,
      ContextService.getInstance().orgId,
      ContextService.getInstance().eventId
    );
    let overlaysList: any[] = [];
    if (overlays && overlays.data && overlays.data.length > 0) {
      overlays.data.forEach((element: any) => {
        if (
          element.overlay.Attributes.PublishedStatus == "2" &&
          element.overlay.Attributes.Active == 1 &&
          element.overlay.Attributes.RoutingServiceVersion != null &&
          element.overlay.Attributes.RoutingServiceVersion.includes("V8")
        ) {
          const name = element.overlay.Attributes.PublishedName.split("-")[3];
          overlaysList.push({
            realName: element.overlay.Attributes.PublishedName,
            name: name,
          });
        }
      });
    }

    $("#multiselectOverlayMatrix").kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "realName",
      dataSource: overlaysList,
    });
    const mapService = MapService.getInstance();
    this.mapForMatriceService = new MapForMatrixService(mapService);
    this.getDataAtStart();
    eventBus().emitter.on("isOverlayEvent", async (data: any) => {
      if (data.isPublished == true) {
        const overlaysList: any = await this.setOverlaysList(false);
        $("#multiselectOverlayMatrix")
          .data("kendoMultiSelect")
          .setDataSource(null);
        $("#multiselectOverlayMatrix")
          .data("kendoMultiSelect")
          .setDataSource(overlaysList);
      }
    });
    this.traffic = "enabled";
  }

  async getDataAtStart(): Promise<void> {
    this.populateEventLists();

    // refresh status each minute
    this.getStatus();

    setInterval(this.getStatus, 60000);
  }

  async populateEventLists(): Promise<any> {
    this.eventLocations = await this.matrixService.getLocations();
    this.transportations = await this.matrixService.getTransportations();
    this.eventLocations.forEach((event) => {
      this.eventTransportation.push(event);
      this.transportations.forEach((transportation) => {
        if (
          event.attributes.UUID == transportation.attributes.ParentLocationID
        ) {
          this.eventTransportation.push(transportation);
        }
      });
    });
    this.subscribeToEvents();
  }

  subscribeToEvents(): void {
    (this.$refs.fromIdSelect as any).addEventListener(
      "calciteSelectChange",
      (evt: any) => {
        this.anEventLocationIsSelected("from");
      }
    );
    (this.$refs.toIdSelect as any).addEventListener(
      "calciteSelectChange",
      (evt: any) => {
        this.anEventLocationIsSelected("to");
      }
    );

    // (this.$refs.btnCalculate as any).addEventListener("click", (evt: any) => {
    //   this.runCalcul();
    // });

    (this.$refs.btnAddSlot as any).addEventListener("click", (evt: any) => {
      (this.$refs.addSlotWindow as any).active = true;
      this.razDaysForm();
    });

    (this.$refs.saveSlot as any).addEventListener("click", (evt: any) => {
      this.validateForm = this.validateAddSlotForm();

      if (this.validateForm != "") {
        (this.$refs.errorAddSlot as any).active = true;
      } else {
        this.saveSlot();
      }
    });

    (this.$refs.tabs as any).addEventListener(
      "calciteTabChange",
      (evt: any) => {
        if (evt.detail && evt.detail.tab) {
          if (this.tabList[evt.detail.tab] === "slots") {
            this.getSlots();
          }
        }
      }
    );

    this.$el.addEventListener("calciteSelectChange", (evt: any) => {
      this.onSelectChange(evt.target.selectedOption.value);
    });
    this.$el.addEventListener("calciteSwitchChange", (evt: any) => {
      this.onSwitchChange(evt.target.name, evt.target.switched);
    });
  }

  onSelectChange(val: string): void {
    if (val == "car") this.mode = val;
    else if (val == "taxi") this.mode = val;
    else if (val == "bus") this.mode = val;
    else if (val == "truck") this.mode = val;
    else if (val == "bicycle") this.mode = val;
    else if (val == "pedestrian") this.mode = val;
  }

  onSwitchChange(name: string, val: boolean): void {
    /*if (name == "switchTraffic") {
      if(val == true)
        this.traffic = "enabled";
      else
        this.traffic = "disabled";
    }*/
  }

  anEventLocationIsSelected(fromOrTo: string): void {
    this.mapForMatriceService.createGraphicLayer();
    this.mapForMatriceService.razRoutes();
    if (fromOrTo === "from") {
      this.fromIdSelected = (this.$refs.fromIdSelect as any).selectedOption
        .value as IEventLocation;
      this.mapForMatriceService.createOrMovePoint(
        fromOrTo,
        this.fromIdSelected
      );
    } else {
      this.toIdSelected = (this.$refs.toIdSelect as any).selectedOption
        .value as IEventLocation;
      this.mapForMatriceService.createOrMovePoint(fromOrTo, this.toIdSelected);
    }
    this.runSelectTravelTime();
  }

  async runSelectTravelTime(): Promise<void> {
    if (this.checkLocationsSelectedAreValid()) {
      this.listsAreValid = true;

      this.Osl = [];
      this.ClientGroups = [];

      this.selectedTravelDefinition =
        await this.matrixService.getATravelDefinition(
          this.fromIdSelected,
          this.toIdSelected
        );

      if (
        this.selectedTravelDefinition &&
        this.selectedTravelDefinition.attributes
      ) {
        this.populateViaPoints();
        // get travel time per slot
        if (this.selectedTravelDefinition.attributes.OperationalServiceLevels) {
          this.selectedTravelDefinition.attributes.OperationalServiceLevels.forEach(
            async (element) => {
              this.Osl.push(
                (await ApiService.getInstance().getOsl()).find(
                  (osl: any) => osl.id == element
                )
              );
            }
          );
        }

        if (this.selectedTravelDefinition.attributes.ClientGroups) {
          this.selectedTravelDefinition.attributes.ClientGroups.forEach(
            async (element) => {
              this.ClientGroups.push(
                (await ApiService.getInstance().getClients()).find(
                  (client: any) => client.id == element
                )
              );
            }
          );
        }

        const selectedTravelsTimePerSlot =
          await this.matrixService.getATravelTimePerSlotsByDefinition(
            this.selectedTravelDefinition.attributes.OBJECTID
          );
        this.selectedTravelsTimePerSlot = selectedTravelsTimePerSlot;
        this.forceStopAddingStep();
        this.getSlotsNameOfSelectedTravels();
      } else {
        this.selectedTravelsTimePerSlot = [];
        this.listsAreValid = false;
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMessageODMatrixInvalidATravelDefinition,
          "red",
          false
        );
      }
    } else {
      this.selectedTravelsTimePerSlot = [];
      this.listsAreValid = false;
    }
  }

  closeErrorAddSlot(): void {
    (this.$refs.errorAddSlot as any).active = false;
  }

  addSelectionChange(ref: any): void {
    if (ref) {
      if (this.changeListener) {
        this.changeListener.removeEventListener(
          "calciteListOrderChange",
          this.eventListener,
          false
        );
      }
      this.changeListener = ref.addEventListener(
        "calciteListOrderChange",
        this.eventListener,
        false
      );
      this.valueListVia = ref;
    }
  }

  eventListener(): void {
    this.viaIsDirty = true;
    this.viaIsRemove = true;
  }

  // check if locations start and from are different
  checkLocationsSelectedAreValid(): boolean {
    let areValid = false;
    if (this.fromIdSelected && this.toIdSelected) {
      if (this.fromIdSelected !== this.toIdSelected) {
        areValid = true;
      }
    }
    return areValid;
  }

  async getSlotsNameOfSelectedTravels(): Promise<void> {
    await this.getSlots();
    // revers array to add route in order
    this.selectedTravelsTimePerSlot = this.selectedTravelsTimePerSlot.reverse();
    for (const selectedTravelTimePerSlot of this.selectedTravelsTimePerSlot) {
      const slot = this.slots.find(
        (slot) =>
          parseInt(slot.attributes.OBJECTID, 10) ===
          selectedTravelTimePerSlot.attributes.SlotId
      );
      if (slot) {
        selectedTravelTimePerSlot.attributes.slotName = slot.attributes.Name;
        const route = this.mapForMatriceService.drawRoute(
          selectedTravelTimePerSlot.geometry
        );

        selectedTravelTimePerSlot.graphic = route.toJSON();
      }
    }
    // restore slot orders
    this.selectedTravelsTimePerSlot = this.selectedTravelsTimePerSlot.reverse();
  }

  showHideGraphic(evt: any): void {
    if (
      evt.srcElement &&
      evt.srcElement.value &&
      evt.srcElement.value.graphic
    ) {
      const graphic = Graphic.fromJSON(evt.srcElement.value.graphic);
      const fifaId = graphic.getAttribute("fifaId");

      if (evt.srcElement.checked) {
        this.mapForMatriceService.addGraphic(fifaId);
      } else {
        this.mapForMatriceService.removeGraphic(fifaId);
      }
    }
  }

  async getSlots(): Promise<void> {
    this.slots = await this.matrixService.getSlots();

    // check slot validatify, only once
    if (this.slots.length > 1) {
      
      if (
        typeof this.slots[0].attributes.Name != "string" ||
        typeof this.slots[0].attributes.FromTimeSlot != "number"||
        typeof this.slots[0].attributes.ToTimeSlot != "number"||
        typeof this.slots[0].attributes.Days != "string" 
      ) {
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMessageODInvalidSlotData,
          "red",
          false,
          Constants.mediumNotificationTimeOut
        );
        (this.$refs.btnAddSlot as any).disabled = true;

        this.slots = [];
      }
    }
  }

  async saveSlot(): Promise<void> {
    const selectedDays: string[] = [];
    // close modal
    (this.$refs.addSlotWindow as any).active = false;

    const checkbox = (this.$refs.addSlotWindow as any).getElementsByTagName(
      "calcite-checkbox"
    );
    const arrayOfCheckbox: any[] = Array.from(checkbox);
    for (const aCheckbox of arrayOfCheckbox) {
      if (aCheckbox.checked) {
        selectedDays.push(aCheckbox.name);
      }
    }

    const body = {
      fromTimeSlots: parseInt((this.$refs.startAt as any).value, 10),
      toTimeSlots: parseInt((this.$refs.to as any).value, 10),
      name: (this.$refs.slotName as any).value,
      days: selectedDays,
    };

    await this.matrixService.saveSlot(body);
    this.razDaysForm();
    // Refresh slots
    await this.getSlots();
  }

  openDeleteSlot(slot: ISlotAsFeature): void {
    this.slotToDelete = slot;
    (this.$refs.confirmSlotDeletion as any).active = true;
  }

  cancelAddSlot(): void {
    (this.$refs.addSlotWindow as any).active = false;
  }

  cancelDeleteSlot(): void {
    this.slotToDelete = null;
    (this.$refs.confirmSlotDeletion as any).active = false;
  }

  async confirmDeleteSlot(): Promise<void> {
    await this.matrixService.deleteSlot(this.slotToDelete.attributes.OBJECTID);
    // refresh slots
    await this.getSlots();
    // use like cancel to close modal
    this.cancelDeleteSlot();
    this.cancelAddSlot();
  }

  validateAddSlotForm(): string {
    if ((this.$refs.slotName as any).value == "") {
      return "Please enter a slot name";
    }

    const startHourAt = parseInt((this.$refs.startAt as any).value);
    if (startHourAt < 0 || startHourAt > 24) {
      return "Start at hour must be between 0 to 24";
    }

    const toHourAt = parseInt((this.$refs.to as any).value);
    if (toHourAt < 0 || toHourAt > 24) {
      return "To hour must be between 0 to 24";
    }

    const checkbox = (this.$refs.addSlotWindow as any).getElementsByTagName(
      "calcite-checkbox"
    );
    const arrayOfCheckbox: any[] = Array.from(checkbox);
    for (const aCheckbox of arrayOfCheckbox) {
      if (aCheckbox.checked) {
        return "";
      }
    }

    return "Please select at least one day";
  }

  razDaysForm(): void {
    (this.$refs.startAt as any).value = "0";
    (this.$refs.to as any).value = "24";
    (this.$refs.slotName as any).value = "";

    const checkbox = (this.$refs.addSlotWindow as any).getElementsByTagName(
      "calcite-checkbox"
    );
    const arrayOfCheckbox: any[] = Array.from(checkbox);
    for (const aCheckbox of arrayOfCheckbox) {
      aCheckbox.checked = false;
    }
  }

  async runCalcul(): Promise<void> {
    // display snack bar run
    // Overlays
    let overlays = "";
    let avoid;
    var multiselect = $("#multiselectOverlayMatrix").data("kendoMultiSelect");
    if (multiselect) {
      overlays = multiselect.value();
      avoid = await this.getAvoid(true);
    }

    const body = {
      mode: "fastest;" + this.mode + ";traffic:" + this.traffic,
      overlays: overlays,
      details: true,
      traverseGate: (this.$refs["traverseGate"] as any).switched,
      usetraveltimeestimateservice: (this.$refs["useTravelTimeService"] as any)
        .switched,
      avoid: avoid,
    };

    await this.matrixService.runCalcul(body);
    this.getStatus();
    (this.$refs.confirmComputeAllRoutes as any).active = false;
  }

  async calculateARoute(): Promise<void> {
    // get the route

    if (this.slots.length < 1) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageODMatrixNoSlotDefined,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
      return;
    }

    if (
      this.selectedTravelDefinition &&
      this.selectedTravelDefinition.attributes.OBJECTID
    ) {
      this.loaderCalculateARoute = true;

      let overlays;
      let avoid;
      var multiselect = $("#multiselectOverlayMatrix").data("kendoMultiSelect");
      if (multiselect) {
        overlays = multiselect.value();
        avoid = await this.getAvoid(true);
      }

      const body = {
        mode: "fastest;" + this.mode + ";traffic:" + this.traffic,
        overlays: overlays,
        details: true,
        traverseGate: (this.$refs["traverseGate"] as any).switched,
        usetraveltimeestimateservice: (
          this.$refs["useTravelTimeService"] as any
        ).switched,
        avoid: avoid,
      };
      await this.matrixService.runACalcul(
        parseInt(this.selectedTravelDefinition.attributes.OBJECTID, 10),
        body
      );
      this.mapForMatriceService.razRoutes(); // for refresh
      this.runSelectTravelTime();
      this.loaderCalculateARoute = false;
    } else {
      NotificationService.getInstance().showNotification(
        "Impossible to compute a route for this orgin and destination",
        "Invalid travel definition",
        "red",
        false
      );
    }
  }

  async getStatus(): Promise<void> {
    this.currentStatus = await this.matrixService.getStatus();
    if (this.currentStatus.isComputingRoutes) {
      (this.$refs.btnCalculate as any).disabled = true;
      if (this.$refs.btnDeleteAll as any)
        (this.$refs.btnDeleteAll as any).disabled = true;
      if (this.$refs.btnDeleteAllSlot as any)
        (this.$refs.btnDeleteAllSlot as any).disabled = true;
      (this.$refs.btnCalculate as any).loading = true;
    } else {
      if (this.$refs.btnDeleteAll as any)
        (this.$refs.btnDeleteAll as any).disabled = false;
      if (this.$refs.btnDeleteAllSlot as any)
        (this.$refs.btnDeleteAllSlot as any).disabled = false;
      (this.$refs.btnCalculate as any).disabled = false;
      (this.$refs.btnCalculate as any).loading = false;
    }
    this.$forceUpdate;
  }

  public startStopAddingStep(): void {
    if (!this.isAddingStep) {
      this.isAddingStepInfo = true;

      this.isAddingStep = this.mapForMatriceService.mapService.mapView.on(
        "click",
        (evt) => {
          const coord = {
            lat: evt.mapPoint.latitude,
            lng: evt.mapPoint.longitude,
          };
          this.addViaPoint(coord);
        }
      );
    } else {
      this.isAddingStep.remove();
      this.isAddingStep = null;
      this.isAddingStepInfo = false;
    }
    this.currentStatus.isComputingRoutes = true;
  }

  public forceStopAddingStep(): void {
    if (this.isAddingStep) {
      this.isAddingStep.remove();
      this.isAddingStep = null;
      this.isAddingStepInfo = false;
    }
  }

  populateViaPoints(): void {
    // clean via points
    this.razViaPoints();

    // Add clean via
    try {
      const vias = this.selectedTravelDefinition.attributes.Via;
      if (Array.isArray(vias) && vias.length > 0) {
        for (const via of vias) {
          const coords = {
            lat: via[0],
            lng: via[1],
          };
          this.addViaPoint(coords);
        }
      }
      this.viaIsDirty = false;
    } catch (e) {
      //pass
    }
  }

  razViaPoints(): void {
    if (this.viaPoints.length > 0) {
      this.mapForMatriceService.razViaPoints();
      this.viaPoints = [];
      this.viaPointsHtml = this.viaPoints.slice();
    }
  }

  getJsonValueOfVia(): number[][] {
    try {
      const vias = JSON.parse(this.selectedTravelDefinition.attributes.Via);
      return vias;
    } catch (e) {
      return [];
    }
  }

  addViaPoint(coords: ILatLng): void {
    this.viaIsDirty = true;

    // const index = this.viaPoints.length;
    // const selectedColor = this.colors[indexColor];
    const aVia = new ViaR(
      coords,
      this.mapForMatriceService.graphicLayerViaPoints,
      (this.viaPoints.length + 1).toString(),
      "#1e90ff",
      null
    );

    this.viaPoints.push(aVia);
    if (this.viaPoints.length > 1) {
      this.viaIsRemove = true;
    }
    this.viaPointsHtml = this.viaPoints.slice();
  }

  /**
    Have to pass by graphic as json to get attributes
   */
  removeViaPoint(via: ViaR): void {
    this.viaIsDirty = true;
    this.viaIsRemove = true;
    this.currentStatus.isComputingRoutes = true;
    const oid = (via.graphicAsJson as any).attributes.oid;
    const graphic =
      this.mapForMatriceService.graphicLayerViaPoints.graphics.find(
        (graphic) => (graphic as any).uid === oid
      );
    if (graphic) {
      this.mapForMatriceService.graphicLayerViaPoints.remove(graphic);
    }

    const indexViaPoint = this.viaPoints.findIndex(
      (viaPoints) => viaPoints.graphicAsJson === via.graphicAsJson
    );

    this.viaPoints.splice(indexViaPoint, 1);
    this.viaPointsHtml = this.viaPoints.slice();
  }

  async deleteAllPoints(via: ViaR): Promise<void> {
    if (
      this.selectedTravelDefinition &&
      this.fromIdSelected &&
      this.toIdSelected
    ) {
      if (this.formatedArray) {
        while (this.formatedArray.length) {
          this.formatedArray = [];
        }
      }

      this.razViaPoints();
      this.viaIsRemove = false;
      this.viaIsDirty = true;
    }
  }

  getCoordRounded(latOrLng: number): number {
    const rounded = Math.round(latOrLng * 10000) / 10000;
    return rounded;
  }

  async saveViaEdits(): Promise<void> {
    this.isSavingVia = true;

    if (
      this.selectedTravelDefinition &&
      this.fromIdSelected &&
      this.toIdSelected
    ) {
      // generate array
      this.formatedArray = [];
      // const valueListVia = this.valueListVia as unknown as any;
      const valuesVia = this.valueListVia.getElementsByTagName(
        "calcite-value-list-item"
      );
      for (const valueVia of valuesVia) {
        if (valueVia.value) {
          const valueViaTyped = valueVia.value as ViaR;
          const coords = [valueViaTyped.lat, valueViaTyped.lng];
          this.formatedArray.push(coords);
        }
      }
      try {
        await this.matrixService.saveVia(
          this.fromIdSelected.attributes.UUID,
          this.toIdSelected.attributes.UUID,
          this.formatedArray
        );

        this.isSavingVia = false;
        this.viaIsDirty = false;
        this.viaIsRemove = false;
        this.currentStatus.isComputingRoutes = false;
        this.viaPointsHtml = [];
        this.mapForMatriceService.razRoutes(); // for refresh
        this.runSelectTravelTime();
      } catch (e) {
        this.isSavingVia = false;
      }
    }
  }

  editPredefinedTime(
    index: string,
    selectedTravelTimePerSlot: ITravelTimePerSlotAsFeature
  ): void {
    (this.$refs["input" + index] as any).disabled = false;
    (this.$refs["input-cancel" + index] as any).classList.remove("isHidden");
    (this.$refs["input-validate" + index] as any).classList.remove("isHidden");
    (this.$refs["input-underline" + index] as any).classList.remove("hideIt");
    (this.$refs["input-hint" + index] as any).classList.remove("isHidden");
    (this.$refs["input-unit" + index] as any).classList.remove("isHidden");
    (this.$refs["input-edit" + index] as any).classList.add("isHidden");

    if (!selectedTravelTimePerSlot.attributes.PredefinedTime) {
      (this.$refs["input" + index] as any).value =
        selectedTravelTimePerSlot.attributes.CalculatedTime;
    } else {
      (this.$refs["input" + index] as any).value =
        selectedTravelTimePerSlot.attributes.PredefinedTime;
    }

    this.updateTimeHint(index);
    this.currentStatus.isComputingRoutes = true;
  }

  cancelEditPredefinedTime(
    index: string,
    selectedTravelTimePerSlot: ITravelTimePerSlotAsFeature
  ): void {
    (this.$refs["input" + index] as any).value = secondsToReadable(
      selectedTravelTimePerSlot.attributes.PredefinedTime
    );
    this.disableEditPredefinedTime(index);
    this.currentStatus.isComputingRoutes = false;
  }

  disableEditPredefinedTime(index: string): void {
    (this.$refs["input" + index] as any).disabled = true;
    (this.$refs["input-unit" + index] as any).classList.add("isHidden");
    (this.$refs["input-cancel" + index] as any).classList.add("isHidden");
    (this.$refs["input-validate" + index] as any).classList.add("isHidden");
    (this.$refs["input-underline" + index] as any).classList.add("hideIt");
    (this.$refs["input-hint" + index] as any).classList.add("isHidden");
    (this.$refs["input-edit" + index] as any).classList.remove("isHidden");
  }

  async validateEditPredefinedTime(
    index: string,
    selectedTravelTimePerSlot: ITravelTimePerSlotAsFeature
  ): Promise<any> {
    (this.$refs["input" + index] as any).disabled = true;
    (this.$refs["input-cancel" + index] as any).disabled = true;

    // showLoader
    this.showHideLoaderOfEdit(
      this.$refs["input-validate" + index] as any,
      true
    );

    const predefinedTimestr = (this.$refs["input" + index] as any).value;

    const predefinedTime = timeStringToSeconds(predefinedTimestr);

    // validation
    if (!isNaN(predefinedTime) && predefinedTime > -1) {
      try {
        // disable during save
        await this.matrixService.editTravelTimePerSlot(
          predefinedTime.toString(),
          selectedTravelTimePerSlot.attributes.OBJECTID
        );
        // update variable
        selectedTravelTimePerSlot.attributes.PredefinedTime = predefinedTime;
        (this.$refs["input" + index] as any).value =
          secondsToReadable(predefinedTime);
        this.showHideLoaderOfEdit(
          this.$refs["input-validate" + index] as any,
          false
        );
      } catch (e) {
        this.showHideLoaderOfEdit(
          this.$refs["input-validate" + index] as any,
          false
        );
        this.disableEditPredefinedTime(index);
        (this.$refs["input-cancel" + index] as any).disabled = false;
      }
    } else {
      // restore default value
      (this.$refs["input" + index] as any).value = secondsToReadable(
        selectedTravelTimePerSlot.attributes.PredefinedTime
      );
    }
    (this.$refs["input-cancel" + index] as any).disabled = false;
    this.disableEditPredefinedTime(index);
    this.currentStatus.isComputingRoutes = false;
  }

  showHideLoaderOfEdit(el: any, showOrHide: boolean): void {
    const loader = el.getElementsByTagName("calcite-loader")[0];
    const icon = el.getElementsByTagName("calcite-icon")[0];
    if (showOrHide) {
      // hide validation button
      icon.classList.add("isHidden");
      loader.classList.remove("isHidden");
    } else {
      icon.classList.remove("isHidden");
      loader.classList.add("isHidden");
    }
  }

  updateTimeHint(index: string): void {
    const inputValue = (this.$refs["input" + index] as any).value;

    if (!isNaN(inputValue)) {
      (this.$refs["input-hint" + index] as any).innerHTML = inputValue + " sec";
    } else {
      (this.$refs["input-hint" + index] as any).innerHTML = "";
    }
  }

  async generateTravelsFile(): Promise<void> {
    (this.$refs.btnGenerateFile as any).loading = true;
    try {
      await this.matrixService.getTravelFile();
      (this.$refs.btnGenerateFile as any).loading = false;
    } catch (e) {
      console.error(e);
      (this.$refs.btnGenerateFile as any).loading = false;
    }
  }

  openSelectFile(): void {
    // simulate click on input
    (this.$refs.inputSelectFile as any).click();
  }

  updateDisplayDir(): void {
    this.isImportDisable = true;
    const filename = (this.$refs.inputSelectFile as any).value.replace(
      /^.*[\\/]/,
      ""
    );
    (this.$refs.displayDir as any).innerHTML = filename;
  }

  async importSelectedFile(): Promise<void> {
    const notificationService = NotificationService.getInstance();
    (this.$refs.btnImport as any).loading = true;
    try {
      const file = (this.$refs.inputSelectFile as any).files[0];
      await this.matrixService.importFileToEditPredefined(file);
      (this.$refs.btnImport as any).loading = false;
      this.razDisplayDir();
      notificationService.showNotification(
        "Import successful",
        "predefined time updated",
        "green",
        true
      );
    } catch (e) {
      this.errorImport();
    }
  }

  errorImport(): void {
    const notificationService = NotificationService.getInstance();
    notificationService.showNotification(
      "Error",
      "file imported is not valid",
      "red",
      true
    );
    (this.$refs.btnImport as any).loading = false;
    this.razDisplayDir();
  }

  razDisplayDir(): void {
    (this.$refs.inputSelectFile as any).value = "";
    this.updateDisplayDir();
    this.isImportDisable = false;
  }

  openDeleteValidation(): void {
    (this.$refs.confirmDeleteAllRoutes as any).active = true;
  }

  openGenerateMatrixValidation(): void {
    (this.$refs.confirmGenerateMatrix as any).active = true;
  }

  cancelDeleteRoute(): void {
    (this.$refs.confirmDeleteAllRoutes as any).active = false;
  }

  cancelGenerateMatrix(): void {
    (this.$refs.confirmGenerateMatrix as any).active = false;
  }

  openDeleteSlotValidation(): void {
    (this.$refs as any).confirmDeleteAllSlots.active = true;
  }
  cancelDeleteSlots(): void {
    (this.$refs.confirmDeleteAllSlots as any).active = false;
  }

  async confirmDeleteAllSlots(): Promise<void> {
    const notificationService = NotificationService.getInstance();
    (this.$refs.confirmSlotDeletionBtn as any).loading = true;
    try {
      this.slots.forEach((element) => {
        this.matrixService.deleteSlot(element.attributes.OBJECTID);
      });
      this.slots = [];
      this.closeDeleteModal();
      notificationService.showNotification(
        "Operation succeeded",
        Constants.messageODMatrixSlotDeletionWithSuccess,
        "green",
        false
      );
    } catch (e) {
      this.closeDeleteModal();
      notificationService.showNotification(
        "Error",
        Constants.errorMessageODMatrixSlotDeletion,
        "red",
        false
      );
    }
  }

  async confirmDeleteRoutes(): Promise<void> {
    const notificationService = NotificationService.getInstance();
    (this.$refs.confirmSlotDeletionBtn as any).loading = true;
    try {
      await this.matrixService.deleteAllRoutes();
      this.closeDeleteModal();
      notificationService.showNotification(
        "Routes deletion",
        Constants.messageODMatrixAllRoutesDeletionWithSuccess,
        "green",
        true
      );
    } catch (e) {
      this.closeDeleteModal();
      notificationService.showNotification(
        "Error",
        Constants.errorMessageODMatrixAllRoutesDeletion,
        "red",
        true
      );
    }
  }

  closeDeleteModal(): void {
    (this.$refs.confirmDeleteAllRoutes as any).active = false;
    (this.$refs.confirmDeleteAllSlots as any).active = false;
    (this.$refs.confirmSlotDeletionBtn as any).loading = false;
  }

  openDeleteRouteValidation(travel: ITravelTimePerSlotAsFeature): void {
    this.travelTimeToDelete = travel;
    (this.$refs.confirmDeleteARoute as any).active = true;
  }

  cancelDeleteARoute(): void {
    (this.$refs.confirmDeleteARoute as any).active = false;
    this.travelTimeToDelete = null;
  }

  async confirmDeleteARoute(): Promise<void> {
    const notificationService = NotificationService.getInstance();
    // const notificationService = new NotificationService();
    (this.$refs.confirmDeleteARouteBtn as any).loading = true;
    if (this.travelTimeToDelete) {
      try {
        await this.matrixService.deleteATravelTimePerSlot(
          this.travelTimeToDelete.attributes.OBJECTID
        );
        const graphic = Graphic.fromJSON(this.travelTimeToDelete.graphic);
        const fifaId = graphic.getAttribute("fifaId");
        this.mapForMatriceService.removeGraphic(fifaId);
        this.closeRouteDelete();
        notificationService.showNotification(
          "Route deleted",
          "The route has been deleted",
          "green",
          true
        );
        this.runSelectTravelTime();
      } catch (e) {
        this.closeRouteDelete();
        notificationService.showNotification(
          "Delete error",
          "Error during route deletion",
          "red",
          true
        );
      }
    }
  }

  closeRouteDelete(): void {
    (this.$refs.confirmDeleteARoute as any).active = false;
    (this.$refs.confirmDeleteARouteBtn as any).loading = false;
    this.travelTimeToDelete = null;
  }

  async runRefresh(): Promise<void> {
    (this.$refs.refreshBtn as any).loading = true;
    await this.runSelectTravelTime();
    (this.$refs.refreshBtn as any).loading = false;
  }

  openRunCalculateRoute(): void {
    if (this.slots.length < 1) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageODMatrixNoSlotDefined,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
    } else {
      (this.$refs.confirmComputeAllRoutes as any).active = true;
    }
  }

  cancelComputeAllRoute(): void {
    (this.$refs.confirmComputeAllRoutes as any).active = false;
  }

  clearSelection(): void {
    this.mapForMatriceService.razRoutes();
    this.mapForMatriceService.razFromToPoints();
    this.mapForMatriceService.razViaPoints();
    this.mapForMatriceService.cleanGroupLayerMatrix();
    this.fromIdSelected = null;
    this.toIdSelected = null;
    this.razViaPoints();
    this.$forceUpdate();
    this.runSelectTravelTime();
    this.viaIsDirty = false;
    this.Osl = [];
    this.ClientGroups = [];
  }

  async GenerateMatrix() {
    this.loaderGenerateMatrix = true;

    // TODO Add parameters in the interface
    const body = {
      eventLocations: true, // Optional --> default value is true. If true, EventLocations are use to generateMatrix
      transportationPoints: true, // Optional --> default value is true.
      isOriginDestinationOnly: false, // Optional --> default value is false. If set to true, only transportations point or event location with "isOriginDestination" are include
    };

    await this.matrixService.GenerateMatrix(body);
    this.loaderGenerateMatrix = false;
  }

  async setOverlaysList(route: boolean): Promise<any[]> {
    const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();
    const overlays = await ApiService.getInstance().getCustomOverlays(
      null,
      null,
      null,
      orga.id,
      event.id
    );
    const overlaysList: any = [];

    if (overlays && overlays.data && overlays.data.length > 0) {
      if (route) {
        overlays.data.forEach((element: any) => {
          overlaysList.push(element);
        });
      } else {
        overlays.data.forEach((element: any) => {
          if (
            element.overlay.Attributes.PublishedStatus == "2" &&
            element.overlay.Attributes.Active == 1 &&
            element.overlay.Attributes.RoutingServiceVersion != null &&
            element.overlay.Attributes.RoutingServiceVersion.includes("V8")
          ) {
            const name = element.overlay.Attributes.PublishedName.split("-")[3];
            overlaysList.push({
              realName: element.overlay.Attributes.PublishedName,
              name: name,
            });
          }
        });
      }
    }
    return overlaysList;
  }

  async getAvoid(route: boolean): Promise<any[]> {
    let avoid: any;
    let areas: any[] = [];
    let areas2 = "";

    const multiselect = $("#multiselectOverlayMatrix").data("kendoMultiSelect");
    if (!multiselect) return null;

    const overlays = $("#multiselectOverlayMatrix")
      .data("kendoMultiSelect")
      .value();

    const overlaysList = await this.setOverlaysList(route);
    Object.keys(overlays).forEach(async (key: any) => {
      overlaysList.forEach((element: any) => {
        if (element.overlay.Attributes.PublishedName == overlays[key]) {
          if (
            element.overlay.Attributes.Active == 1 &&
            element.overlay.Attributes.RoutingServiceVersion.includes("V8") &&
            element.restrictedAreas.length > 0
          ) {
            element.restrictedAreas.forEach((area: any) => {
              const mode = (
                this.mode.charAt(0).toUpperCase() + this.mode.slice(1)
              ).toString();
              if (area.BoundingBox != null) {
                if (
                  (mode == Object.keys(area)[4] &&
                    area.Car == "N" &&
                    mode != Object.keys(area)[5] &&
                    mode != Object.keys(area)[6] &&
                    mode != Object.keys(area)[7]) ||
                  (mode != Object.keys(area)[4] &&
                    mode == Object.keys(area)[5] &&
                    area.Bus == "N" &&
                    mode != Object.keys(area)[6] &&
                    mode != Object.keys(area)[7]) ||
                  (mode != Object.keys(area)[4] &&
                    mode != Object.keys(area)[5] &&
                    mode == Object.keys(area)[6] &&
                    area.Truck == "N" &&
                    mode != Object.keys(area)[7]) ||
                  (mode != Object.keys(area)[4] &&
                    mode != Object.keys(area)[5] &&
                    mode != Object.keys(area)[6] &&
                    mode == Object.keys(area)[7] &&
                    area.Pedestrian == "N") ||
                  (mode != Object.keys(area)[4] &&
                    mode != Object.keys(area)[5] &&
                    mode != Object.keys(area)[6] &&
                    mode != Object.keys(area)[7])
                ) {
                  areas.push(area.BoundingBox);
                }
              }
            });
          }
        }
      });
    });
    if (areas.length < 1) avoid = areas2;
    else avoid = areas;
    return avoid;
  }

  openResetValidation(): void {
    (this.$refs.confirmResetMatrix as any).active = true;
  }

  cancelResetValidation(): void {
    (this.$refs.confirmResetMatrix as any).active = false;
  }

  async resetMatrix(): Promise<void> {
    await this.matrixService.purgeMatrix();
  }
}
