
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { MapService } from "./MapService";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import MapView from "@arcgis/core/views/MapView";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import { ViaR } from "@/model/viaR";


export class MapForRouteService {
  graphicFrom!: Graphic;
  pointFrom!: { lat: number; lng: number };
  mapView!: MapView;
  mapService: MapService;
  graphicLayerViaPoints: GraphicsLayer = null;
  RouteGroupLayer: GroupLayer = null;
  fromGraphic: Graphic;
  toGraphic: Graphic;
  graphicTo!: Graphic;
  pointTo!: { lat: number; lng: number };
  graphicRoutes: Graphic[];
  colors: string[];
  viaSketchViewModel: SketchViewModel;
  markerSymbolFrom: PictureMarkerSymbol;
  viaR: ViaR;
  viaPointsMapForRouteService: ViaR[] = [];
  RouteWidget: any;

  constructor(mapService: MapService) {
    this.mapService = mapService;
    this.setColors();
    this.mapService.mapView.when(() => {
      //this.createGraphicLayer();
      this.setSketchView()
    });
  }

  initRouteWidget(RouteWidget: any): void {
    this.RouteWidget = RouteWidget;
  }

  setSketchView(): void {
    this.viaSketchViewModel = new SketchViewModel({
      view: this.mapService.mapView,
      layer: this.graphicLayerViaPoints,

      updateOnGraphicClick: true,
      pointSymbol: {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "square",
      },

      defaultUpdateOptions: {
        tool: "move",
      },
    });

    this.viaSketchViewModel.on("update", (evt) => {
      if (evt.toolEventInfo && evt.toolEventInfo.type === "move-stop") {
        const x = evt.graphics[0].geometry.get("x") as number;
        const y = evt.graphics[0].geometry.get("y") as number;
        const coord = webMercatorUtils.xyToLngLat(x, y);
        for (let i = 0; i < this.viaPointsMapForRouteService.length; i++) {
          if (evt.graphics[0] === this.viaPointsMapForRouteService[i].graphic) {
            this.viaPointsMapForRouteService[i].lat = coord[1];
            this.viaPointsMapForRouteService[i].lng = coord[0];
            this.viaPointsMapForRouteService[i].coords.lat = coord[1];
            this.viaPointsMapForRouteService[i].coords.lng = coord[0];
            this.RouteWidget.updateViaAfterMove();
          }
        }
      }
    })
  }

  private setColors(): void {
    //extract from colors ramp esri
    this.colors = [
      "#d92b30",
      "#0095ba",
      "#3cccb4",
      "#ab52b3",
      "#ffb259",
      "#ffdf3c",
      "#eb82eb",
      "#c27c30",
      "#a0d17d",
      "#f260a1",
    ];
  }

  public createGraphicLayer(): void {
    this.mapService.createRouteGroupLayer();
    
    if (this.mapService.RouteGroupLayer != null && this.graphicLayerViaPoints == null) {
      this.graphicLayerViaPoints = new GraphicsLayer({
        title: "Via Points",
        graphics: [],
      });

      this.mapService.map.add(
        this.graphicLayerViaPoints,
        this.mapService.map.layers.length
      ); // adds the layer to the map
      this.mapService.RouteGroupLayer.add(this.graphicLayerViaPoints);
    }

    if (this.mapService.RouteGroupLayer != null) {
      this.mapService.RouteGroupLayer.add(this.graphicLayerViaPoints);
    }
  }


  public createOrMovePoint(): void {
    // First create a point geometry
    this.graphicLayerViaPoints;
  }

  public razViaPoints(): void {
    this.viaPointsMapForRouteService = []
    this.graphicLayerViaPoints.removeAll();
  }

  public removePointsFromGraphic(): void {
    if (this.graphicLayerViaPoints) {
      this.graphicLayerViaPoints.graphics.removeAll();
    }
  }

  cleanGroupLayerRoute(): void {
    this.mapService.map.layers.remove(this.RouteGroupLayer);
    this.RouteGroupLayer = null;
  }

}
