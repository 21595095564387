
import "@esri/calcite-components";
export class NotificationService {

    private static instance: NotificationService;

    public notificationWidget: HTMLCalciteAlertElement;
    private readonly notificationId = 'notification-widget';


    constructor() {
        //this.notificationContainer.className = 'app-spinner';
        this.notificationWidget = document.createElement('calcite-alert');
        this.notificationWidget.id = this.notificationId;
        this.notificationWidget.active = false;
        document.body.appendChild(this.notificationWidget);

    }

    static getInstance() {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    public showNotification(title: string, message: string, color: any, autoDismiss : boolean, timeOut?: number): void {
        //const notification = document.getElementById(this.notificationContainer.id);
        this.notificationWidget.innerHTML = null;
        this.notificationWidget.active = true;
        this.notificationWidget.autoDismiss = autoDismiss;
        this.notificationWidget.color = color;
        
        const titleEl = document.createElement('div');
        titleEl.setAttribute('slot', 'title');
        titleEl.innerHTML = title;
        this.notificationWidget.appendChild(titleEl);

        const messageEl = document.createElement('div');
        messageEl.setAttribute('slot', 'message');
        messageEl.innerHTML = message;
        this.notificationWidget.appendChild(messageEl);

        /* this.notificationContainer.appendChild(notification);
        if (!notification) {
            document.body.appendChild(this.notificationContainer);
        } */
        setTimeout(() => {
            this.notificationWidget.active = false;
        }, timeOut? timeOut:5000);
    }

    public hideNotification(): void {
        if (this.notificationWidget) {
            this.notificationWidget.innerHTML = null;
            this.notificationWidget.active = false;
        }
    }
}