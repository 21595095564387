import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Auth = _resolveComponent("Auth")!
  const _component_Header = _resolveComponent("Header")!
  const _component_MapContainer = _resolveComponent("MapContainer")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.showUnauthorized)
      ? (_openBlock(), _createBlock(_component_Auth, {
          key: 0,
          onLoginSucceed: _ctx.onLoginSucceed
        }, null, 8, ["onLoginSucceed"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Header),
    (_ctx.contextLoaded && !_ctx.showUnauthorized && _ctx.configLoaded)
      ? (_openBlock(), _createBlock(_component_MapContainer, {
          key: 1,
          onMounted: _ctx.onMapContainerMounted
        }, null, 8, ["onMounted"]))
      : _createCommentVNode("", true)
  ], 64))
}