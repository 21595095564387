
import { MapService } from "@/services/MapService";
import { ApiService } from "@/services/ApiService";
import { Options, prop, Vue } from "vue-class-component";
import { NotificationService } from "@/services/NotificationService";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import eventBus from "@/services/EventBus";
import { LoaderService } from "@/services/LoaderService";
import { ILocationType } from "@/interfaces/ILocationTypes";
import { ITransportationPoint } from "@/interfaces/ITransportationPoints";
import { IBadLocation } from "@/interfaces/IBadLocation";
import { LocationTransportationService } from "@/services/LocationTransportationService";
import { ContextService } from "@/services/ContextService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { ConfigService } from "@/services/ConfigService";
import Graphic from "@arcgis/core/Graphic";
import { watch } from "@arcgis/core/core/watchUtils";
import { standardizeErrorMsg } from "@/utils/errorManagement";
import { Constants } from "@/services/Constants";
import EsriGeoJSONUtils from "@/utils/EsriGeoJSONUtils";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import FieldColumnConfig from "@arcgis/core/widgets/FeatureTable/FieldColumnConfig";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";

@Options({
  components: {},
  props: {},
})
export default class LocationTransportation extends Vue {
  proxyLocationTypes: any[] = [];
  proxyTransportationPoints: any[] = [];
  proxyClusters: any[];
  title = "";
  reportSize = 0;
  locationTableSize = { size: 0 };
  transportationTableSize = { size: 0 };
  importTableSize = { size: 0 };
  assignTableSize = { size: 0 };
  reportTableSize = { size: 0 };
  syncTableSize = { size: 0 };
  locationSelectedItems = { size: 0 };
  transportationSelectedItems = { size: 0 };
  reportSelectedItems = { size: 0 };
  syncSelectedItems = { size: 0 };
  showFT = false;
  allLayers: any;
  locationLayers: any;
  transportationLayers: any;
  tableFieldConfig: FieldConfig[] = [
    {
      name: "UUID",
      label: "UUID",
    } as FieldConfig,
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "Address",
      label: "Address",
    } as FieldConfig,
    {
      name: "Type",
      label: "Type",
    } as FieldConfig,
  ];
  appendData = true;
  overwriteData = false;
  isEditor = false;
  totalFeatures = 0;
  validation = false;
  report = false;
  wrongTPs = false;
  itemsNotImported: any[] = [];
  itemsImported: any[] = [];
  exportedData = "Locations";
  exportType = "Layer";
  proxyType = "Locations";
  eRebusLocationLayerFields = [
    {
      name: "UUID",
      type: "string",
    },
    {
      name: "Name",
      type: "string",
    },
    {
      name: "Address",
      type: "string",
    },
    {
      name: "TypeName",
      type: "string",
    },
    {
      name: "TypeID",
      type: "string",
    },
    {
      name: "ClusterName",
      type: "string",
    },
    {
      name: "ClusterID",
      type: "string",
    },
    {
      name: "UpdatedAt",
      type: "date",
    },
  ];
  eRebusLocationPopupTemplate = {
    title: "{Name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "UUID",
            label: "UUID",
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "ClusterName",
            label: "Cluster Name",
          },
          {
            fieldName: "ClusterID",
            label: "Cluster ID",
          },
          {
            fieldName: "TypeName",
            label: "Type Name",
          },
          {
            fieldName: "TypeID",
            label: "Type ID",
          },
        ],
      },
    ],
  };
  eRebusTransportationLayerFields = [
    {
      name: "UUID",
      type: "string",
    },
    {
      name: "Name",
      type: "string",
    },
    {
      name: "Address",
      type: "string",
    },
    {
      name: "ParentLocationID",
      type: "string",
    },
  ];
  eRebusTransportationPopupTemplate = {
    title: "{Name}",
    content: [
      {
        type: "fields",
        fieldInfos: [
          {
            fieldName: "UUID",
            label: "UUID",
          },
          {
            fieldName: "Address",
            label: "Address",
          },
          {
            fieldName: "ParentLocationID",
            label: "Parent Location ID",
          },
        ],
      },
    ],
  };
  eRebusDataLoaded = false;

  async mounted(): Promise<void> {
    const errMsg =
      LocationTransportationService.getInstance().initFeatureTables(
        (this.$refs as any).locationFeatureTableRef,
        (this.$refs as any).transportationFeatureTableRef,
        (this.$refs as any).importFeatureTableRef,
        (this.$refs as any).assignFeatureTableRef,
        (this.$refs as any).reportFeatureTableRef,
        (this.$refs as any).syncFeatureTableRef
      );

    if (!errMsg) {
      this.locationTableSize =
        LocationTransportationService.getInstance().locationTableSize;
      this.transportationTableSize =
        LocationTransportationService.getInstance().transportationTableSize;
      this.importTableSize =
        LocationTransportationService.getInstance().importTableSize;
      this.assignTableSize =
        LocationTransportationService.getInstance().assignTableSize;
      this.reportTableSize =
        LocationTransportationService.getInstance().reportTableSize;
      this.syncTableSize =
        LocationTransportationService.getInstance().syncTableSize;
      this.locationSelectedItems =
        LocationTransportationService.getInstance().locationSelectedItems;
      this.transportationSelectedItems =
        LocationTransportationService.getInstance().transportationSelectedItems;
      this.reportSelectedItems =
        LocationTransportationService.getInstance().reportSelectedItems;
      this.syncSelectedItems =
        LocationTransportationService.getInstance().syncSelectedItems;
      this.isEditor = ContextService.getInstance().isEditor();
      this.allLayers = MapService.getInstance().map.allLayers;
      this.locationLayers = [];
      this.transportationLayers = [];
      this.geteRebusClusters();
      eventBus().emitter.on("addLayer", () => {
        this.allLayers = MapService.getInstance().map.allLayers;
      });
    } else {
      NotificationService.getInstance().showNotification(
        "Error",
        errMsg,
        "red",
        false,
        Constants.mediumNotificationTimeOut
      );
    }
  }

  async onRemoveLocation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedLocation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async onRemoveTransportation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedTransportation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async onRemoveBadLocation(): Promise<void> {
    LoaderService.getInstance().showLoader();
    try {
      await LocationTransportationService.getInstance().deleteSelectedBadLocation();
    } finally {
      LoaderService.getInstance().hideLoader();
    }
  }

  async geteRebusData(type: string): Promise<void> {
    if (!this.eRebusDataLoaded) {
      try {
        const locations =
          await ApiService.getInstance().getProxyLocationTypes();
        this.proxyLocationTypes = locations;
        const transportations =
          await ApiService.getInstance().getProxyTransportationPoints();
        this.proxyTransportationPoints = transportations;
      } catch (e) {
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMessageManageLocationsGeteRebusData,
          "red",
          false
        );
      }
      this.eRebusDataLoaded = true;
    }
    this.proxyType = type;
    this.showSyncModal();
    this.showFT = true;
  }

  async geteRebusClusters(): Promise<void> {
    try {
      this.proxyClusters = await ApiService.getInstance().getProxyClusters();
    } catch (e) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageManageLocationsGetClusters,
        "red",
        false
      );
    }
  }

  showDeleteModal(): void {
    (this.$refs as any).deleteModal.active = true;
  }

  hideDeleteModal(): void {
    (this.$refs as any).deleteModal.active = false;
  }

  showImportModal(): void {
    this.$forceUpdate();
    (this.$refs as any).importModal.active = true;
  }

  hideImportModal(): void {
    (this.$refs as any).importModal.active = false;
  }

  showOverwriteModal(): void {
    (this.$refs as any).overwriteModal.active = true;
  }

  hideOverwriteModal(): void {
    (this.$refs as any).overwriteModal.active = false;
  }

  showAssignModal(): void {
    this.showAssignFeatureTable();
    (this.$refs as any).assignModal.active = true;
  }

  hideAssignModal(): void {
    this.hideAssignFeatureTable();
    (this.$refs as any).assignModal.active = false;
  }

  showUnassignModal(): void {
    (this.$refs as any).unassignModal.active = true;
  }

  hideUnassignModal(): void {
    (this.$refs as any).unassignModal.active = false;
  }

  showExportModal(): void {
    (this.$refs as any).exportModal.active = true;
  }

  hideExportModal(): void {
    (this.$refs as any).exportModal.active = false;
  }

  showSyncModal(): void {
    this.showSyncFeatureTable();
    (this.$refs as any).syncModal.active = true;
  }

  hideSyncModal(): void {
    this.hideSyncFeatureTable();
    (this.$refs as any).syncModal.active = false;
  }

  showImportFeatureTable(layer: any): void {
    const importFT =
      LocationTransportationService.getInstance().importFeatureTable;
    importFT.layer = layer;
    importFT.visible = true;
  }

  hideImportFeatureTable(): void {
    LocationTransportationService.getInstance().importFeatureTable.visible =
      false;
  }

  showAssignFeatureTable(): void {
    const assignFT =
      LocationTransportationService.getInstance().assignFeatureTable;
    let featureLayer: FeatureLayer;
    featureLayer = this.checkID(featureLayer);
    if (assignFT && !this.wrongTPs) {
      featureLayer.title = "";
      assignFT.layer = featureLayer;
      assignFT.visible = true;
    }
  }

  hideAssignFeatureTable(): void {
    LocationTransportationService.getInstance().assignFeatureTable.visible =
      false;
  }

  showReportFeatureTable(): void {
    LocationTransportationService.getInstance().reportFeatureTable.visible =
      true;
    LocationTransportationService.getInstance().reportFeatureTable.refresh();
  }

  hideReportFeatureTable(): void {
    LocationTransportationService.getInstance().reportFeatureTable.visible =
      false;
  }

  showSyncFeatureTable(): void {
    LocationTransportationService.getInstance().syncFeatureTable.layer =
      this.createLayer();
    LocationTransportationService.getInstance().syncFeatureTable.visible = true;
  }

  hideSyncFeatureTable(): void {
    LocationTransportationService.getInstance().syncFeatureTable.visible =
      false;
  }

  addLayer(layer: any): void {
    this.createManageEventLocationsLayerGroup();
    MapService.getInstance().manageEventLocationsGroupLayer.layers.forEach(
      (l: any) => {
        if (l.title == layer.title)
          MapService.getInstance().manageEventLocationsGroupLayer.remove(l);
      }
    );
    MapService.getInstance().manageEventLocationsGroupLayer.add(layer);
  }

  createManageEventLocationsLayerGroup(): void {
    if (!MapService.getInstance().manageEventLocationsGroupLayer) {
      MapService.getInstance().manageEventLocationsGroupLayer = new GroupLayer({
        title: "Manage Event Locations",
        visible: true,
        visibilityMode: "independent",
        layers: [],
      });
      MapService.getInstance().map.add(
        MapService.getInstance().manageEventLocationsGroupLayer
      );
    }
  }

  createLayer(): any {
    const graphicAttributes = this.createGraphicAttributes(
      this.proxyType == "Locations"
        ? this.proxyLocationTypes
        : this.proxyType == "Transportations"
        ? this.proxyTransportationPoints
        : null
    );
    const renderer = this.createRenderer();

    const layer = new FeatureLayer({
      source: graphicAttributes,
      objectIdField: "OBJECTID",
      renderer: renderer,
    });

    if (this.proxyType == "Locations") {
      layer.title = "Locations from eRebus";
      layer.fields = this.eRebusLocationLayerFields as any;
      layer.popupTemplate = this.eRebusLocationPopupTemplate as any;
    } else if (this.proxyType == "Transportations") {
      layer.title = "Transportation Points from eRebus";
      layer.fields = this.eRebusTransportationLayerFields as any;
      layer.popupTemplate = this.eRebusTransportationPopupTemplate as any;
    }

    this.addLayer(layer);

    return layer;
  }

  createGraphicAttributes(event: any): Graphic[] {
    let graphicAttributes: Graphic[] = [];
    let point: Point;
    let attributes: any;

    event.forEach((element: any, index: any) => {
      if (element.latitude != null && element.longitude != null) {
        point = new Point({
          latitude: parseFloat(element.latitude),
          longitude: parseFloat(element.longitude),
        });
      } else {
        point = new Point({
          latitude: 34.361108,
          longitude: -38.250909,
        });
      }

      if (
        this.proxyType == "Locations" &&
        element.id &&
        element.name &&
        element.location_type &&
        element.cluster
      ) {
        LocationTransportationService.getInstance().syncFeatureTable.fieldConfigs =
          LocationTransportationService.getInstance()
            .syncLocationsFieldConfig as FieldColumnConfig[];
        attributes = {
          OBJECTID: index + 1,
          UUID: element.id,
          Name: element.name,
          TypeID: element.location_type,
          TypeName: "",
          ClusterID: element.cluster,
          ClusterName: "",
          Address: "",
          UpdatedAt: Date.now(),
        };
      } else if (
        this.proxyType == "Transportations" &&
        element.id &&
        element.name &&
        element.address &&
        element.location_id
      ) {
        LocationTransportationService.getInstance().syncFeatureTable.fieldConfigs =
          LocationTransportationService.getInstance()
            .syncTransportationsFieldConfig as FieldColumnConfig[];
        attributes = {
          OBJECTID: index + 1,
          UUID: element.id,
          Name: element.name,
          Address: element.address,
          ParentLocationID: element.location_id,
        };
      }

      const graphic = new Graphic({
        geometry: point,
        attributes: attributes,
      });

      graphicAttributes.push(graphic);
    });

    return graphicAttributes;
  }

  createRenderer(): SimpleRenderer {
    let renderer;

    if (this.proxyType == "Locations") {
      renderer = {
        type: "simple",
        symbol: {
          type: "simple-marker",
          color: "red",
          size: 5,
          outline: {
            color: "red",
          },
        },
        label: "Locations",
      } as any as SimpleRenderer;
    } else if (this.proxyType == "Transportations") {
      renderer = {
        type: "simple",
        symbol: {
          type: "simple-marker",
          color: "black",
          size: 5,
        },
        label: "Transportation Points",
      } as any as SimpleRenderer;
    }

    return renderer;
  }

  refresheRebusData(): void {
    LocationTransportationService.getInstance().syncFeatureTable.layer =
      this.createLayer();
    LocationTransportationService.getInstance().syncFeatureTable.refresh();
  }

  deleteeRebusData(): void {
    const table = LocationTransportationService.getInstance()
      .syncFeatureTable as any;
    let itemsID: any[] = [];
    (table as any).grid.selectedItems.forEach((item: any) => {
      itemsID.push({
        objectId: item.objectId,
      });
    });
    console.log(itemsID);
    LocationTransportationService.getInstance()
      .syncFeatureTable.layer.applyEdits({
        deleteFeatures: itemsID,
      })
      .then((results: any) => {
        console.log(results);
      });
    table.refresh();
  }

  checkID(featureLayer: FeatureLayer): FeatureLayer {
    const id = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.items[0].feature.attributes.ParentLocationID;
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((element: any) => {
      if (element.feature.attributes.ParentLocationID == id) check++;
    });
    if (
      check ==
      (
        LocationTransportationService.getInstance()
          .transportationFeatureTable as any
      ).grid.selectedItems.length
    ) {
      featureLayer = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[
          ConfigService.getInstance().config.featuresServer.transportationPoints
            .urlField
        ],
        definitionExpression:
          "TypeName = 'Check point' AND ParentLocationID = '" + id + "'",
      });
      this.wrongTPs = false;
    } else {
      this.wrongTPs = true;
    }
    return featureLayer;
  }

  checkLocationFieldValidity(element: any, UUID?: any | null, checkParentLocationID?: boolean) {
    if (this.itemsNotImported.includes(element)) return false;

    if (UUID && element.feature.attributes.UUID == UUID) return true;

    if (
      typeof element.feature.attributes.OBJECTID != "number" ||
      typeof element.feature.attributes.UUID != "string" ||
      element.feature.attributes.UUID == ""
    )
      return true;

    if (
      typeof element.feature.attributes.Name != "string" ||
      element.feature.attributes.Name == ""
    )
      return true;

    if (
      typeof element.feature.attributes.Address != "string" ||
      element.feature.attributes.Address == ""
    )
      return true;

    if (
      element.feature.attributes.TypeName &&
      (typeof element.feature.attributes.TypeName != "string" ||
        element.feature.attributes.TypeName == "")
    ) 
      return true;
    
    if (
      element.feature.attributes.Type &&
      (typeof element.feature.attributes.Type != "string" ||
        element.feature.attributes.Type == "")
    ) 
      return true;

    if (checkParentLocationID &&
      (typeof element.feature.attributes.ParentLocationID != "string" ||
        element.feature.attributes.ParentLocationID == "")
    ) 
      return true;

    return false;
  }

  async importData(): Promise<void> {
    this.report = false;
    this.itemsImported = [];
    this.itemsNotImported = [];
    const importGrid = (
      LocationTransportationService.getInstance().importFeatureTable as any
    ).grid;

    if (
      (this.title == "Locations" && this.appendData == true) ||
      (this.title == "Locations" &&
        this.overwriteData == true &&
        this.validation)
    ) {
      const locations =
        await LocationTransportationService.getInstance().getCurrentLocation();
      if (this.appendData === true && this.overwriteData === false) {
        if (
          importGrid &&
          importGrid.selectedItems &&
          importGrid.selectedItems.items
        ) {
          LoaderService.getInstance().showLoader();
          try {
            importGrid.selectedItems.items.forEach(async (element: any) => {
              const query =
                LocationTransportationService.getInstance().importFeatureTable.layer.createQuery();
              query.where = "1=1";
              query.outFields = ["*"];
              const response =
                await LocationTransportationService.getInstance().importFeatureTable.layer.queryFeatures(
                  query
                );
              response.features.forEach((loc: any) => {
                if (
                  loc.attributes.OBJECTID == element.feature.attributes.OBJECTID
                )
                  element.feature.geometry = loc.geometry;
              });
              locations.forEach(async (location: any) => {
                if (this.checkLocationFieldValidity(element, location.UUID)) {
                  this.itemsNotImported.push(element);
                  const localID = element.feature.attributes.OBJECTID
                    ? element.feature.attributes.OBJECTID
                    : element.feature.attributes.ID
                    ? element.feature.attributes.ID
                    : element.feature.attributes.OID
                    ? element.feature.attributes.OID
                    : null;
                  const other: any[] = [];
                  Object.keys(element.feature.attributes).forEach(
                    (key: any) => {
                      if (
                        element.feature.attributes[key] !=
                          element.feature.attributes.UUID &&
                        element.feature.attributes[key] !=
                          element.feature.attributes.Name &&
                        element.feature.attributes[key] !=
                          element.feature.attributes.Address &&
                        element.feature.attributes[key] !=
                          (element.feature.attributes.Type ? element.feature.attributes.Type : element.feature.attributes.TypeName ? element.feature.attributes.TypeName: null)
                      ) {
                        other.push(
                          key + " : " + element.feature.attributes[key]
                        );
                      }
                    }
                  );
                  const badLocation: IBadLocation = {
                    UUID: element.feature.attributes.UUID,
                    IDSource: localID,
                    Name: element.feature.attributes.Name,
                    Address: element.feature.attributes.Address,
                    Type: element.feature.attributes.Type
                      ? element.feature.attributes.Type
                      : element.feature.attributes.TypeName
                      ? element.feature.attributes.TypeName
                      : null,
                    Other: other.join("; "),
                    UserName: ContextService.getInstance().user.name,
                    ImportedDate: Date.now(),
                    Comments: "Failed to import",
                    geometry: element.feature.geometry,
                  } as IBadLocation;
                  await LocationTransportationService.getInstance().addBadLocation(
                    badLocation
                  );
                }
              });
              if (!this.itemsNotImported.includes(element)) {
                this.itemsImported.push(element);
                const location: ILocationType = {
                  UUID: element.feature.attributes.UUID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  TypeName: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  TypeID: element.feature.attributes.TypeID,
                  ClusterName: element.feature.attributes.ClusterName,
                  ClusterID: element.feature.attributes.ClusterID,
                  IsOriginDestination: element.feature.IsOriginDestination,
                  LocationValidity: element.feature.LocationValidity,
                  SynchronizationStatus: 1,
                  UpdatedAt: Date.now(),
                  geometry: element.feature.geometry,
                } as ILocationType;
                await LocationTransportationService.getInstance().addLocation(
                  location
                );
              }
            });
          } catch (error) {
            LoaderService.getInstance().hideLoader();
            NotificationService.getInstance().showNotification(
              "Import " + this.title,
              error.data.error,
              "red",
              false
            );
          } finally {
            LoaderService.getInstance().hideLoader();
            if (this.itemsNotImported.length > 0) {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added but some " +
                  this.title +
                  " can't be imported.",
                "green",
                false
              );
            } else {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added.",
                "green",
                false
              );
            }
            LocationTransportationService.getInstance().reportFeatureTable.refresh();
            this.report = true;
          }
        }
      } else if (this.overwriteData === true && this.appendData === false) {
        if (
          importGrid &&
          importGrid.selectedItems &&
          importGrid.selectedItems.items
        ) {
          LoaderService.getInstance().showLoader();
          try {
            const locationsID: any[] = [];
            locations.forEach(async (location: any) => {
              locationsID.push(location.OBJECTID);
            });
            await LocationTransportationService.getInstance().deleteLocation(
              locationsID
            );
            importGrid.selectedItems.items.forEach(async (element: any) => {
              const query =
                LocationTransportationService.getInstance().importFeatureTable.layer.createQuery();
              query.where = "1=1";
              query.outFields = ["*"];
              const response =
                await LocationTransportationService.getInstance().importFeatureTable.layer.queryFeatures(
                  query
                );
              response.features.forEach((loc: any) => {
                if (
                  loc.attributes.OBJECTID == element.feature.attributes.OBJECTID
                )
                  element.feature.geometry = loc.geometry;
              });
              if (this.checkLocationFieldValidity(element)) {
                this.itemsNotImported.push(element);
                const localID = element.feature.attributes.OBJECTID
                  ? element.feature.attributes.OBJECTID
                  : element.feature.attributes.ID
                  ? element.feature.attributes.ID
                  : element.feature.attributes.OID
                  ? element.feature.attributes.OID
                  : null;
                const other: any[] = [];
                Object.keys(element.feature.attributes).forEach((key: any) => {
                  if (
                    element.feature.attributes[key] !=
                      element.feature.attributes.UUID &&
                    element.feature.attributes[key] !=
                      element.feature.attributes.Name &&
                    element.feature.attributes[key] !=
                      element.feature.attributes.Address &&
                    element.feature.attributes[key] !=
                      (element.feature.attributes.Type ? element.feature.attributes.Type : element.feature.attributes.TypeName ? element.feature.attributes.TypeName: null)
                  ) {
                    other.push(key + " : " + element.feature.attributes[key]);
                  }
                });
                const badLocation: IBadLocation = {
                  UUID: element.feature.attributes.UUID,
                  IDSource: localID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  Type: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  Other: other.join("; "),
                  UserName: ContextService.getInstance().user.name,
                  ImportedDate: Date.now(),
                  Comments: "Failed to import",
                  geometry: element.feature.geometry,
                } as IBadLocation;
                await LocationTransportationService.getInstance().addBadLocation(
                  badLocation
                );
              } else {
                this.itemsImported.push(element);
                const location: ILocationType = {
                  UUID: element.feature.attributes.UUID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  TypeName: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  TypeID: element.feature.attributes.TypeID,
                  ClusterName: element.feature.attributes.ClusterName,
                  ClusterID: element.feature.attributes.ClusterID,
                  IsOriginDestination: element.feature.IsOriginDestination,
                  LocationValidity: element.feature.LocationValidity,
                  SynchronizationStatus: 1,
                  UpdatedAt: Date.now(),
                  geometry: element.feature.geometry,
                } as ILocationType;
                await LocationTransportationService.getInstance().addLocation(
                  location
                );
              }
            });
          } catch (error) {
            LoaderService.getInstance().hideLoader();
            NotificationService.getInstance().showNotification(
              "Import " + this.title,
              error.data.error,
              "red",
              false
            );
          } finally {
            LoaderService.getInstance().hideLoader();
            if (this.itemsNotImported.length > 0) {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added but some " +
                  this.title +
                  " can't be imported.",
                "green",
                false
              );
            } else {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added.",
                "green",
                false
              );
            }
            LocationTransportationService.getInstance().reportFeatureTable.refresh();
            this.report = true;
          }
        }
      }
    } else if (
      (this.title == "Transportation Points" && this.appendData == true) ||
      (this.title == "Transportation Points" &&
        this.overwriteData == true &&
        this.validation)
    ) {
      const transportations =
        await LocationTransportationService.getInstance().getCurrentTransportation();
      if (this.appendData === true && this.overwriteData === false) {
        if (
          importGrid &&
          importGrid.selectedItems &&
          importGrid.selectedItems.items
        ) {
          LoaderService.getInstance().showLoader();
          try {
            importGrid.selectedItems.items.forEach(async (element: any) => {
              const query =
                LocationTransportationService.getInstance().importFeatureTable.layer.createQuery();
              query.where = "1=1";
              query.outFields = ["*"];
              const response =
                await LocationTransportationService.getInstance().importFeatureTable.layer.queryFeatures(
                  query
                );
              response.features.forEach((loc: any) => {
                if (
                  loc.attributes.OBJECTID == element.feature.attributes.OBJECTID
                )
                  element.feature.geometry = loc.geometry;
              });
              transportations.forEach(async (transportation: any) => {
                if (this.checkLocationFieldValidity(element, transportation.UUID, true)) {
                  this.itemsNotImported.push(element);
                  const localID = element.feature.attributes.OBJECTID
                    ? element.feature.attributes.OBJECTID
                    : element.feature.attributes.ID
                    ? element.feature.attributes.ID
                    : element.feature.attributes.OID
                    ? element.feature.attributes.OID
                    : null;
                  const other: any[] = [];
                  Object.keys(element.feature.attributes).forEach(
                    (key: any) => {
                      if (
                        element.feature.attributes[key] !=
                          element.feature.attributes.UUID &&
                        element.feature.attributes[key] !=
                          element.feature.attributes.Name &&
                        element.feature.attributes[key] !=
                          element.feature.attributes.Address &&
                        element.feature.attributes[key] !=
                          (element.feature.attributes.Type ? element.feature.attributes.Type : element.feature.attributes.TypeName ? element.feature.attributes.TypeName: null)
                      ) {
                        other.push(
                          key + " : " + element.feature.attributes[key]
                        );
                      }
                    }
                  );
                  const badLocation: IBadLocation = {
                    UUID: element.feature.attributes.UUID,
                    IDSource: localID,
                    Name: element.feature.attributes.Name,
                    Address: element.feature.attributes.Address,
                    Type: element.feature.attributes.Type
                      ? element.feature.attributes.Type
                      : element.feature.attributes.TypeName
                      ? element.feature.attributes.TypeName
                      : null,
                    Other: other.join("; "),
                    UserName: ContextService.getInstance().user.name,
                    ImportedDate: Date.now(),
                    Comments: "Failed to import",
                    geometry: element.feature.geometry,
                  } as IBadLocation;
                  await LocationTransportationService.getInstance().addBadLocation(
                    badLocation
                  );
                }
              });
              if (!this.itemsNotImported.includes(element)) {
                this.itemsImported.push(element);
                const transportation: ITransportationPoint = {
                  UUID: element.feature.attributes.UUID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  TypeName: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  ParentLocationName:
                    element.feature.attributes.ParentLocationName,
                  ParentLocationID: element.feature.attributes.ParentLocationID,
                  CheckPointName: element.feature.attributes.CheckPointName,
                  CheckPointID: element.feature.attributes.CheckPointID,
                  IsOriginDestination:
                    element.feature.attributes.IsOriginDestination,
                  LocationValidity: element.feature.attributes.LocationValidity,
                  TimePenalty: element.feature.attributes.TimePenalty
                    ? element.feature.attributes.TimePenalty
                    : null,
                  SynchronizationStatus: 1,
                  UpdatedAt: Date.now(),
                  geometry: element.feature.geometry,
                } as ITransportationPoint;
                await LocationTransportationService.getInstance().addTransportation(
                  transportation
                );
              }
            });
          } catch (error) {
            LoaderService.getInstance().hideLoader();
            NotificationService.getInstance().showNotification(
              "Import " + this.title,
              error.data.error,
              "red",
              false
            );
          } finally {
            LoaderService.getInstance().hideLoader();
            if (this.itemsNotImported.length > 0) {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added but some " +
                  this.title +
                  " can't be imported.",
                "green",
                false
              );
            } else {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added.",
                "green",
                false
              );
            }
            LocationTransportationService.getInstance().reportFeatureTable.refresh();
            this.report = true;
          }
        }
      } else if (this.overwriteData === true && this.appendData === false) {
        if (
          importGrid &&
          importGrid.selectedItems &&
          importGrid.selectedItems.items
        ) {
          LoaderService.getInstance().showLoader();
          try {
            const transportationsID: any[] = [];
            transportations.forEach(async (transportation: any) => {
              transportationsID.push(transportation.OBJECTID);
            });
            await LocationTransportationService.getInstance().deleteTransportation(
              transportationsID
            );
            importGrid.selectedItems.items.forEach(async (element: any) => {
              const query =
                LocationTransportationService.getInstance().importFeatureTable.layer.createQuery();
              query.where = "1=1";
              query.outFields = ["*"];
              const response =
                await LocationTransportationService.getInstance().importFeatureTable.layer.queryFeatures(
                  query
                );
              response.features.forEach((loc: any) => {
                if (
                  loc.attributes.OBJECTID == element.feature.attributes.OBJECTID
                )
                  element.feature.geometry = loc.geometry;
              });
              if (this.checkLocationFieldValidity(element, null, true)) {
                this.itemsNotImported.push(element);
                const localID = element.feature.attributes.OBJECTID
                  ? element.feature.attributes.OBJECTID
                  : element.feature.attributes.ID
                  ? element.feature.attributes.ID
                  : element.feature.attributes.OID
                  ? element.feature.attributes.OID
                  : null;
                const other: any[] = [];
                Object.keys(element.feature.attributes).forEach((key: any) => {
                  if (
                    element.feature.attributes[key] !=
                      element.feature.attributes.UUID &&
                    element.feature.attributes[key] !=
                      element.feature.attributes.Name &&
                    element.feature.attributes[key] !=
                      element.feature.attributes.Address &&
                    element.feature.attributes[key] !=
                      (element.feature.attributes.Type ? element.feature.attributes.Type : element.feature.attributes.TypeName ? element.feature.attributes.TypeName: null)
                  ) {
                    other.push(key + " : " + element.feature.attributes[key]);
                  }
                });
                const badLocation: IBadLocation = {
                  UUID: element.feature.attributes.UUID,
                  IDSource: localID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  Type: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  Other: other.join("; "),
                  UserName: ContextService.getInstance().user.name,
                  ImportedDate: Date.now(),
                  Comments: "Failed to import",
                  geometry: element.feature.geometry,
                } as IBadLocation;
                await LocationTransportationService.getInstance().addBadLocation(
                  badLocation
                );
              } else {
                this.itemsImported.push(element);
                const transportation: ITransportationPoint = {
                  UUID: element.feature.attributes.UUID,
                  Name: element.feature.attributes.Name,
                  Address: element.feature.attributes.Address,
                  TypeName: element.feature.attributes.Type
                    ? element.feature.attributes.Type
                    : element.feature.attributes.TypeName
                    ? element.feature.attributes.TypeName
                    : null,
                  ParentLocationName:
                    element.feature.attributes.ParentLocationName,
                  ParentLocationID: element.feature.attributes.ParentLocationID,
                  CheckPointName: element.feature.attributes.CheckPointName,
                  CheckPointID: element.feature.attributes.CheckPointID,
                  IsOriginDestination:
                    element.feature.attributes.IsOriginDestination,
                  LocationValidity: element.feature.attributes.LocationValidity,
                  SynchronizationStatus: 1,
                  TimePenalty: element.feature.attributes.TimePenalty
                    ? element.feature.attributes.TimePenalty
                    : null,
                  UpdatedAt: Date.now(),
                  geometry: element.feature.geometry,
                } as ITransportationPoint;
                await LocationTransportationService.getInstance().addTransportation(
                  transportation
                );
              }
            });
          } catch (error) {
            LoaderService.getInstance().hideLoader();
            NotificationService.getInstance().showNotification(
              "Import " + this.title,
              error.data.error,
              "red",
              false
            );
          } finally {
            LoaderService.getInstance().hideLoader();
            if (this.itemsNotImported.length > 0) {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added but some " +
                  this.title +
                  " can't be imported.",
                "green",
                false
              );
            } else {
              NotificationService.getInstance().showNotification(
                "Import " + this.title,
                "The data has been successfully added.",
                "green",
                false
              );
            }
            LocationTransportationService.getInstance().reportFeatureTable.refresh();
            this.report = true;
          }
        }
      }
      this.appendData = true;
      this.overwriteData = false;
    } else if (this.validation == false) {
      this.totalFeatures = importGrid.selectedItems.length;
      this.$forceUpdate();
      this.showOverwriteModal();
    }
  }

  assignCheckPoint(): void {
    const transporationGrid = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid;
    const assignGrid = (
      LocationTransportationService.getInstance().assignFeatureTable as any
    ).grid;
    if (
      transporationGrid &&
      transporationGrid.selectedItems &&
      transporationGrid.selectedItems.items &&
      assignGrid &&
      assignGrid.selectedItems &&
      assignGrid.selectedItems.items
    ) {
      LoaderService.getInstance().showLoader();
      try {
        const cp = assignGrid.selectedItems.items[0];
        transporationGrid.selectedItems.forEach(async (tp: any) => {
          const transportation = new Graphic({
            attributes: {
              OBJECTID: tp.feature.attributes.OBJECTID,
              CheckPointID: cp.attributes.UUID,
              CheckPointName: cp.attributes.Name,
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer
                .transportationPoints.urlField
            ],
            [transportation],
            ApiService.editMode.UPDATE
          );
        });
      } catch (error) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Assign checkpoint ",
          error.data.error,
          "red",
          false
        );
      } finally {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Assign checkpoint ",
          "The checkpoint has been assigned to the transportation point(s).",
          "green",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  unassignCheckPoint(): void {
    const transporationGrid = (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid;
    if (
      transporationGrid &&
      transporationGrid.selectedItems &&
      transporationGrid.selectedItems.items
    ) {
      LoaderService.getInstance().showLoader();
      try {
        transporationGrid.selectedItems.forEach(async (tp: any) => {
          const transportation = new Graphic({
            attributes: {
              OBJECTID: tp.feature.attributes.OBJECTID,
              CheckPointID: "",
              CheckPointName: "",
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer
                .transportationPoints.urlField
            ],
            [transportation],
            ApiService.editMode.UPDATE
          );
        });
      } catch (error) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Unassign checkpoint ",
          error.data.error,
          "red",
          false
        );
      } finally {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Unassign checkpoint ",
          "The checkpoint(s) have been removed from the transportation point(s).",
          "green",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  async exportData(): Promise<void> {
    let layer;
    let features: any[] = [];
    let featureLayer: FeatureLayer;
    let featureTable: FeatureTable;
    try {
      LoaderService.getInstance().showLoader();
      if (this.exportedData == "Locations") {
        featureLayer =
          LocationTransportationService.getInstance().locationTypesFL;
        featureTable =
          LocationTransportationService.getInstance().locationFeatureTable;
      } else if (this.exportedData == "Transportation Points") {
        featureLayer =
          LocationTransportationService.getInstance().transportationPointsFL;
        featureTable =
          LocationTransportationService.getInstance()
            .transportationFeatureTable;
      } else if (this.exportedData == "Report") {
        featureLayer = LocationTransportationService.getInstance().reportFL;
        featureTable =
          LocationTransportationService.getInstance().reportFeatureTable;
      } else if (this.exportedData == "Synchronize") {
        featureLayer = LocationTransportationService.getInstance()
          .syncFeatureTable.layer as FeatureLayer;
        featureTable =
          LocationTransportationService.getInstance().syncFeatureTable;
      }
      if (this.exportType == "Layer") {
        const query = featureLayer.createQuery();
        query.where = "1=1";
        query.outFields = ["*"];
        const response = await featureLayer.queryFeatures(query);
        response.features.forEach((f: any) => {
          if (this.exportedData == "Locations") {
            const feat = {
              type: "Feature",
              objectId: f.attributes.OBJECTID,
              geometry: {
                type:
                  f.geometry.type.charAt(0).toUpperCase() +
                  f.geometry.type.slice(1),
                coordinates: [f.geometry.x, f.geometry.y],
              },
              properties: {
                UUID: f.attributes.UUID,
                Name: f.attributes.Name,
                Address: f.attributes.Address,
                TypeName: f.attributes.TypeName,
                TypeID: f.attributes.TypeID,
                ClusterName: f.attributes.ClusterName,
                ClusterID: f.attributes.ClusterID,
                IsOriginDestination: f.attributes.IsOriginDestination,
                LocationValidity: f.attributes.LocationValidity,
                SynchronizationStatus: f.attributes.SynchronizationStatus,
                UpdatedAt: f.attributes.UpdatedAt,
              },
            };
            features.push(feat);
          } else if (this.exportedData == "Transportation Points") {
            const feat = {
              type: "Feature",
              objectId: f.attributes.OBJECTID,
              geometry: {
                type:
                  f.geometry.type.charAt(0).toUpperCase() +
                  f.geometry.type.slice(1),
                coordinates: [f.geometry.x, f.geometry.y],
              },
              properties: {
                UUID: f.attributes.UUID,
                Name: f.attributes.Name,
                Address: f.attributes.Address,
                TypeName: f.attributes.Type,
                ParentLocationName: f.attributes.ParentLocationName,
                ParentLocationID: f.attributes.ParentLocationID,
                CheckPointName: f.attributes.CheckPointName,
                CheckPointID: f.attributes.CheckPointID,
                IsOriginDestination: f.attributes.IsOriginDestination,
                LocationValidity: f.attributes.LocationValidity,
                SynchronizationStatus: f.attributes.SynchronizationStatus,
                TimePenalty: f.attributes.TimePenalty,
                UpdatedAt: f.attributes.UpdatedAt,
              },
            };
            features.push(feat);
          } else if (this.exportedData == "Synchronize") {
            if (this.proxyType == "Locations") {
              const feat = {
                type: "Feature",
                objectId: f.attributes.OBJECTID,
                geometry: {
                  type:
                    f.geometry.type.charAt(0).toUpperCase() +
                    f.geometry.type.slice(1),
                  coordinates: [f.geometry.x, f.geometry.y],
                },
                properties: {
                  UUID: f.attributes.UUID,
                  Name: f.attributes.Name,
                  TypeName: f.attributes.TypeName,
                  TypeID: f.attributes.TypeID,
                  ClusterName: f.attributes.ClusterName,
                  ClusterID: f.attributes.ClusterID,
                  Address: f.attributes.Address,
                  UpdatedAt: f.attributes.UpdatedAt,
                },
              };
              features.push(feat);
            } else if (this.proxyType == "Transportations") {
              const feat = {
                type: "Feature",
                objectId: f.attributes.OBJECTID,
                geometry: {
                  type:
                    f.geometry.type.charAt(0).toUpperCase() +
                    f.geometry.type.slice(1),
                  coordinates: [f.geometry.x, f.geometry.y],
                },
                properties: {
                  UUID: f.attributes.UUID,
                  Name: f.attributes.Name,
                  Address: f.attributes.Address,
                  ParentLocationID: f.attributes.ParentLocationID,
                },
              };
              features.push(feat);
            }
          }
        });
        layer = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326",
            },
          },
          features: features,
        };
      } else if (
        this.exportType == "Selected" &&
        (featureTable as any).grid.selectedItems.length > 0
      ) {
        const table = (featureTable as any).grid.selectedItems.items;
        let where;
        if ((featureTable as any).grid.selectedItems.length > 0) {
          where = "OBJECTID in ";
          const list: any[] = [];
          table.forEach((feat: any) => {
            list.push(feat.feature.attributes.OBJECTID);
          });
          where += "(" + list.join(", ") + ")";
        }
        const query = featureLayer.createQuery();
        query.where = where;
        query.outFields = ["*"];
        const response = await featureLayer.queryFeatures(query);
        response.features.forEach((f: any) => {
          if (this.exportedData == "Locations") {
            const feat = {
              type: "Feature",
              objectId: f.attributes.OBJECTID,
              geometry: {
                type: (
                  f.geometry.type.charAt(0).toUpperCase() +
                  f.geometry.type.slice(1)
                ).toString(),
                coordinates: [f.geometry.x, f.geometry.y],
              },
              properties: {
                UUID: f.attributes.UUID,
                Name: f.attributes.Name,
                Address: f.attributes.Address,
                TypeName: f.attributes.TypeName,
                TypeID: f.attributes.TypeID,
                ClusterName: f.attributes.ClusterName,
                ClusterID: f.attributes.ClusterID,
                IsOriginDestination: f.attributes.IsOriginDestination,
                LocationValidity: f.attributes.LocationValidity,
                SynchronizationStatus: f.attributes.SynchronizationStatus,
                UpdatedAt: f.attributes.UpdatedAt,
              },
            };
            features.push(feat);
          } else if (this.exportedData == "Transportation Points") {
            const feat = {
              type: "Feature",
              objectId: f.attributes.OBJECTID,
              geometry: {
                type: (
                  f.geometry.type.charAt(0).toUpperCase() +
                  f.geometry.type.slice(1)
                ).toString(),
                coordinates: [f.geometry.x, f.geometry.y],
              },
              properties: {
                UUID: f.attributes.UUID,
                Name: f.attributes.Name,
                Address: f.attributes.Address,
                TypeName: f.attributes.TypeName,
                ParentLocationName: f.attributes.ParentLocationName,
                ParentLocationID: f.attributes.ParentLocationID,
                CheckPointName: f.attributes.CheckPointName,
                CheckPointID: f.attributes.CheckPointID,
                IsOriginDestination: f.attributes.IsOriginDestination,
                LocationValidity: f.attributes.LocationValidity,
                SynchronizationStatus: f.attributes.SynchronizationStatus,
                TimePenalty: f.attributes.TimePenalty,
                UpdatedAt: f.attributes.UpdatedAt,
              },
            };
            features.push(feat);
          } else if (this.exportedData == "Synchronize") {
            if (this.proxyType == "Locations") {
              const feat = {
                type: "Feature",
                objectId: f.attributes.OBJECTID,
                geometry: {
                  type:
                    f.geometry.type.charAt(0).toUpperCase() +
                    f.geometry.type.slice(1),
                  coordinates: [f.geometry.x, f.geometry.y],
                },
                properties: {
                  UUID: f.attributes.UUID,
                  Name: f.attributes.Name,
                  TypeName: f.attributes.TypeName,
                  TypeID: f.attributes.TypeID,
                  ClusterName: f.attributes.ClusterName,
                  ClusterID: f.attributes.ClusterID,
                  Address: f.attributes.Address,
                  UpdatedAt: f.attributes.UpdatedAt,
                },
              };
              features.push(feat);
            } else if (this.proxyType == "Transportations") {
              const feat = {
                type: "Feature",
                objectId: f.attributes.OBJECTID,
                geometry: {
                  type:
                    f.geometry.type.charAt(0).toUpperCase() +
                    f.geometry.type.slice(1),
                  coordinates: [f.geometry.x, f.geometry.y],
                },
                properties: {
                  UUID: f.attributes.UUID,
                  Name: f.attributes.Name,
                  Address: f.attributes.Address,
                  ParentLocationID: f.attributes.ParentLocationID,
                },
              };
              features.push(feat);
            }
          }
        });
        layer = {
          type: "FeatureCollection",
          crs: {
            type: "name",
            properties: {
              name: "EPSG:4326",
            },
          },
          features: features,
        };
      }
      if (
        this.exportType == "Layer" ||
        (this.exportType == "Selected" &&
          (featureTable as any).grid.selectedItems.length > 0)
      ) {
        let name = this.exportedData + " - ";
        name += Date.now().toString();
        var hiddenElement = document.createElement("a");
        var data = new Blob([JSON.stringify(layer)], { type: "text/plain" });
        var url = window.URL.createObjectURL(data);
        hiddenElement.href = url;
        hiddenElement.target = "_blank";
        hiddenElement.download = name + ".geojson";
        hiddenElement.click();
      }
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Export " + this.exportType,
        "Can't export the data.",
        "red",
        false
      );
    } finally {
      if (
        (featureTable as any).grid.selectedItems.length == 0 &&
        this.exportType == "Selected"
      ) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Export " + this.exportedData,
          "Impossible to export the data because there is no selected item in the table. Please select at least one item.",
          "red",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Export " + this.exportedData,
          "The data has been exported successfully.",
          "green",
          false
        );
      }
    }
  }

  async checkLocations(): Promise<void> {
    let notValid = 0;
    try {
      LoaderService.getInstance().showLoader();
      LocationTransportationService.getInstance().locationFeatureTable.clearSelection();
      const query =
        LocationTransportationService.getInstance().locationTypesFL.createQuery();
      query.where = "1=1";
      query.outFields = ["*"];
      const locations =
        await LocationTransportationService.getInstance().locationTypesFL.queryFeatures(
          query
        );
      locations.features.forEach(async (location: any) => {
        if (location.attributes.ClusterID && !location.attributes.ClusterName) {
          this.proxyClusters.forEach(async (cluster: any) => {
            if (cluster.id == location.attributes.ClusterID) {
              const loc = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  ClusterName: cluster.name,
                  UpdatedAt: Date.now(),
                },
              });
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc],
                ApiService.editMode.UPDATE
              );
            }
          });
        }
        let binaire = "000";
        if (
          !location.attributes.UUID ||
          location.attributes.UUID == "" ||
          !location.attributes.TypeID ||
          location.attributes.TypeID == "" ||
          !location.geometry
        ) {
          if (!location.attributes.UUID || location.attributes.UUID == "")
            binaire = this.replaceCharAt(0, binaire, "1");
          if (!location.attributes.TypeID || location.attributes.TypeID == "")
            binaire = this.replaceCharAt(1, binaire, "1");
          if (!location.geometry) binaire = this.replaceCharAt(2, binaire, "1");
          switch (binaire) {
            case "100": {
              const loc1 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 1,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc1],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "010": {
              const loc2 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 2,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc2],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "001": {
              const loc3 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 3,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc3],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "110": {
              const loc4 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 4,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc4],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "101": {
              const loc5 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 5,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc5],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "011": {
              const loc6 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 6,
                  UpdatedAt: Date.now(),
                },
              });
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc6],
                ApiService.editMode.UPDATE
              );
              notValid++;
              break;
            }
            case "111": {
              const loc7 = new Graphic({
                attributes: {
                  OBJECTID: location.attributes.OBJECTID,
                  LocationValidity: 7,
                  UpdatedAt: Date.now(),
                },
              });
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .locationTypes.urlField
                ],
                [loc7],
                ApiService.editMode.UPDATE
              );
              notValid++;
              break;
            }
          }
          LocationTransportationService.getInstance().locationFeatureTable.selectRows(
            location
          );
        } else {
          const loc = new Graphic({
            attributes: {
              OBJECTID: location.attributes.OBJECTID,
              LocationValidity: 0,
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer.locationTypes
                .urlField
            ],
            [loc],
            ApiService.editMode.UPDATE
          );
        }
      });
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Check locations",
        error.data.error,
        "red",
        true
      );
    } finally {
      if (notValid == 0) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check locations",
          "All locations are valid",
          "green",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check locations",
          notValid +
            " issue(s) found in the locations. Please, check the Location Validity field in the table to analyse it or them.",
          "red",
          false
        );
      }
      LocationTransportationService.getInstance().locationFeatureTable.refresh();
    }
  }

  async checkTransportationPoints(): Promise<void> {
    let notValid = 0;
    try {
      LoaderService.getInstance().showLoader();
      LocationTransportationService.getInstance().transportationFeatureTable.clearSelection();
      const query1 =
        LocationTransportationService.getInstance().locationTypesFL.createQuery();
      query1.where = "1=1";
      query1.outFields = ["*"];
      const locations =
        await LocationTransportationService.getInstance().locationTypesFL.queryFeatures(
          query1
        );
      const parentLocationIDs: any[] = [];
      const parentLocations: any[] = [];
      locations.features.forEach((location: any) => {
        if (location.attributes.UUID)
          parentLocationIDs.push(location.attributes.UUID);
        if (location.attributes.UUID && location.attributes.Name)
          parentLocations.push({
            ID: location.attributes.UUID,
            Name: location.attributes.Name,
          });
      });
      const query2 =
        LocationTransportationService.getInstance().transportationPointsFL.createQuery();
      query2.where = "1=1";
      query2.outFields = ["*"];
      const tps =
        await LocationTransportationService.getInstance().transportationPointsFL.queryFeatures(
          query2
        );
      const checkPointIDs: any[] = [];
      const checkPoints: any[] = [];
      tps.features.forEach((tp: any) => {
        if (tp.attributes.UUID) checkPointIDs.push(tp.attributes.UUID);
        if (tp.attributes.UUID && tp.attributes.Name)
          checkPoints.push({
            ID: tp.attributes.UUID,
            Name: tp.attributes.Name,
          });
      });
      tps.features.forEach(async (tp: any) => {
        if (
          tp.attributes.ParentLocationID &&
          !tp.attributes.ParentLocationName
        ) {
          parentLocations.forEach(async (parent: any) => {
            if (parent.ID == tp.attributes.ParentLocationID) {
              const tp1 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  ParentLocationName: parent.Name,
                  UpdatedAt: Date.now(),
                },
              });
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp1],
                ApiService.editMode.UPDATE
              );
            }
          });
        }
        if (tp.attributes.CheckPointID && !tp.attributes.CheckPointName) {
          checkPoints.forEach(async (checkpoint: any) => {
            if (checkpoint.ID == tp.attributes.CheckPointID) {
              const tp2 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  CheckPointName: checkpoint.Name,
                  UpdatedAt: Date.now(),
                },
              });
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp2],
                ApiService.editMode.UPDATE
              );
            }
          });
        }
        let binaire = "00000";
        if (
          !tp.attributes.UUID ||
          tp.attributes.UUID == "" ||
          !tp.attributes.TypeName ||
          tp.attributes.TypeName == "" ||
          !tp.attributes.ParentLocationID ||
          tp.attributes.ParentLocationID == "" ||
          !parentLocationIDs.includes(tp.attributes.ParentLocationID) ||
          (tp.attributes.CheckPointID &&
            !checkPointIDs.includes(tp.attributes.CheckPointID)) ||
          !tp.geometry
        ) {
          if (!tp.attributes.UUID || tp.attributes.UUID == "")
            binaire = this.replaceCharAt(0, binaire, "1");
          if (!tp.attributes.Type || tp.attributes.Type == "")
            binaire = this.replaceCharAt(1, binaire, "1");
          if (
            !tp.attributes.ParentLocationID ||
            tp.attributes.ParentLocationID == "" ||
            !parentLocationIDs.includes(tp.attributes.ParentLocationID)
          )
            binaire = this.replaceCharAt(2, binaire, "1");
          if (
            tp.attributes.CheckPointID &&
            !checkPointIDs.includes(tp.attributes.CheckPointID)
          )
            binaire = this.replaceCharAt(3, binaire, "1");
          if (!tp.geometry) binaire = this.replaceCharAt(4, binaire, "1");
          switch (binaire) {
            case "10000": {
              const tp1 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 1,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp1],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01000": {
              const tp2 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 2,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp2],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00100": {
              const tp3 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 3,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp3],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00010": {
              const tp4 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 4,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp4],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00001": {
              const tp5 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 5,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp5],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11000": {
              const tp6 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 6,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp6],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10100": {
              const tp7 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 7,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp7],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10010": {
              const tp8 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 8,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp8],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10001": {
              const tp9 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 9,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp9],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01100": {
              const tp10 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 10,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp10],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01010": {
              const tp11 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 11,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp11],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01001": {
              const tp12 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 12,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp12],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00110": {
              const tp13 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 13,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp13],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00101": {
              const tp14 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 14,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp14],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00011": {
              const tp15 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 15,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp15],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11100": {
              const tp16 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 16,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp16],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11010": {
              const tp17 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 17,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp17],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11001": {
              const tp18 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 18,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp18],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10110": {
              const tp19 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 19,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp19],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10101": {
              const tp20 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 20,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp20],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10011": {
              const tp21 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 21,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp21],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01110": {
              const tp22 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 22,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp22],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01101": {
              const tp23 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 23,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp23],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01011": {
              const tp24 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 24,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp24],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "00111": {
              const tp25 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 25,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp25],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11110": {
              const tp26 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 26,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp26],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11101": {
              const tp27 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 27,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp27],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11011": {
              const tp28 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 28,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp28],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "10111": {
              const tp29 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 29,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp29],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "01111": {
              const tp30 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 30,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp30],
                ApiService.editMode.UPDATE
              );
              break;
            }
            case "11111": {
              const tp31 = new Graphic({
                attributes: {
                  OBJECTID: tp.attributes.OBJECTID,
                  LocationValidity: 31,
                  UpdatedAt: Date.now(),
                },
              });
              notValid++;
              await ApiService.callEsriApplyEdits(
                ConfigService.getInstance().webmapConfig.attributes[
                  ConfigService.getInstance().config.featuresServer
                    .transportationPoints.urlField
                ],
                [tp31],
                ApiService.editMode.UPDATE
              );
              break;
            }
          }
          LocationTransportationService.getInstance().transportationFeatureTable.selectRows(
            tp
          );
        } else {
          const trp = new Graphic({
            attributes: {
              OBJECTID: tp.attributes.OBJECTID,
              LocationValidity: 0,
              UpdatedAt: Date.now(),
            },
          });
          await ApiService.callEsriApplyEdits(
            ConfigService.getInstance().webmapConfig.attributes[
              ConfigService.getInstance().config.featuresServer
                .transportationPoints.urlField
            ],
            [trp],
            ApiService.editMode.UPDATE
          );
        }
      });
    } catch (error) {
      LoaderService.getInstance().hideLoader();
      NotificationService.getInstance().showNotification(
        "Check transportaion points",
        error.data.error,
        "red",
        false
      );
    } finally {
      if (notValid == 0) {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check transportaion points",
          "All transportation points are valid",
          "green",
          false
        );
      } else {
        LoaderService.getInstance().hideLoader();
        NotificationService.getInstance().showNotification(
          "Check transportaion points",
          notValid +
            " issue(s) found in the transportation points. Please, check the Location Validity field in the table to analyse it or them.",
          "red",
          false
        );
      }
      LocationTransportationService.getInstance().transportationFeatureTable.refresh();
    }
  }

  replaceCharAt(index: number, original: string, replace: string): string {
    return (
      original.substring(0, index) +
      replace +
      original.substring(index + replace.length)
    );
  }

  checkLocationLayers(): void {
    const fieldsList = ["UUID", "Name", "Address", "TypeID"];
    const badFieldsList = [
      "ParentLocationID",
      "ParentLocationsName",
      "CheckPointID",
      "CheckPointName",
    ];
    let locs: any[] = [];
    this.allLayers.forEach((layer: any) => {
      let check = 0;
      if (layer.geometryType == "point") {
        layer.fields.forEach((element: any) => {
          if (fieldsList.includes(element.name)) check++;
          else if (
            badFieldsList.includes(element.name) ||
            layer.title == "Locations"
          )
            check--;
        });
        if (check == fieldsList.length) locs.push(layer);
      }
    });
    this.locationLayers = locs;
    this.showImportModal();
  }

  checkTransportationLayers(): void {
    const fieldsList = ["UUID", "Name", "Address", "Type", "ParentLocationID"];
    const transps: any[] = [];
    this.allLayers.forEach((layer: any) => {
      let check = 0;
      if (layer.geometryType == "point") {
        layer.fields.forEach((element: any) => {
          if (fieldsList.includes(element.name)) check++;
          else if (layer.title == "Transportation Points") check--;
        });
        if (check == fieldsList.length) transps.push(layer);
      }
    });
    this.transportationLayers = transps;
    this.showImportModal();
  }

  get isAssignable() {
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((element: any) => {
      if (
        element &&
        element.feature &&
        element.feature.attributes &&
        element.feature.attributes.Type != "Check Point"
      )
        check++;
      else if (
        element &&
        element.attributes &&
        element.attributes.Type != "Check Point"
      )
        check++;
    });
    if (
      this.transportationSelectedItems.size > 0 &&
      check == this.transportationSelectedItems.size
    )
      return false;
    else return true;
  }

  get isUnassignable() {
    let check = 0;
    (
      LocationTransportationService.getInstance()
        .transportationFeatureTable as any
    ).grid.selectedItems.forEach((tp: any) => {
      if (
        tp &&
        tp.feature &&
        tp.feature.attributes &&
        tp.feature.attributes.CheckPointID &&
        tp.feature.attributes.CheckPointName
      )
        check++;
    });
    if (
      this.transportationSelectedItems.size > 0 &&
      check ==
        (
          LocationTransportationService.getInstance()
            .transportationFeatureTable as any
        ).grid.selectedItems.length
    )
      return false;
    else return true;
  }
}
