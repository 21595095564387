import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";

export class PortalService {

    private static instance: PortalService;



    constructor() {
        esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;
    }

    static getInstance() {
        if (!PortalService.instance) {
            PortalService.instance = new PortalService();
        }

        return PortalService.instance;
    }

}