import { IOverlay } from "@/interfaces/IOverlay";
import esriConfig from "@arcgis/core/config";
import { ConfigService } from "./ConfigService";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import FieldConfig from "@arcgis/core/widgets/FeatureForm/FieldConfig";
import FeatureTable from "@arcgis/core/widgets/FeatureTable";
import { MapService } from "./MapService";
import { IRestrictedArea } from "@/interfaces/IRestrictedArea";
import { reactive } from "vue";
import { watch } from "@arcgis/core/core/watchUtils";
import { ApiService } from "@/services/ApiService";
import { store } from "@/store";
import eventBus from "./EventBus";
import ButtonMenuItem from "@arcgis/core/widgets/FeatureTable/Grid/support/ButtonMenuItem";

export class RestrictedAreasService {
  private static instance: RestrictedAreasService;
  featureTable: FeatureTable;
  featureLayer: FeatureLayer;

  tableSize: { size: number };
  tableSelectedItems: { size: number };

  addedAreaCounter: number;
  selectedFeature: any;

  tableFieldConfig: FieldConfig[] = [
    {
      name: "Name",
      label: "Name",
    } as FieldConfig,
    {
      name: "StartDate",
      label: "Start",
    } as FieldConfig,
    {
      name: "EndDate",
      label: "End",
    } as FieldConfig,
    {
      name: "Car",
      label: "Car",
    } as FieldConfig,
    {
      name: "Bus",
      label: "Bus",
    } as FieldConfig,
    {
      name: "Truck",
      label: "Truck",
    } as FieldConfig,
    {
      name: "Pedestrian",
      label: "Pedestrian",
    } as FieldConfig,
    {
      name: "Comments",
      label: "Comments",
    } as FieldConfig,
  ];

  constructor() {
    this.addedAreaCounter = 0;
    esriConfig.portalUrl = ConfigService.getInstance().config.portal.url;

    // Replaced because this the find by layer is not working
    this.featureLayer = new FeatureLayer({
      editingEnabled: true,
      url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.restrictedAreas.urlField]
    });

    /*this.featureLayer = MapService.getInstance().findLayerByTitle(
      ConfigService.getInstance().webmapConfig.attributes[
        ConfigService.getInstance().config.featuresServer.restrictedAreas.title
      ]
    );*/
    this.tableSize = reactive({ size: 0 });
    this.tableSelectedItems = reactive({ size: 0});
  }

  static getInstance() {
    if (!RestrictedAreasService.instance) {
      RestrictedAreasService.instance = new RestrictedAreasService();
    }

    return RestrictedAreasService.instance;
  }

  showFeatureTable(containerEl: HTMLElement, overlay: IOverlay): void {
    const zoom = new ButtonMenuItem({
      label: "Zoom to selected feature(s)",
      iconClass: "esri-icon-zoom-in-magnifying-glass",
      clickFunction: async () => {
        if ((this.featureTable as any).grid.selectedItems.length > 1) {
          const areas = (this.featureTable as any).grid.selectedItems.items;
          const query = this.featureLayer.createQuery();
          let where = "OBJECTID in ";
          const list: any[] = [];
          areas.forEach((area: any) => {
            list.push(area.feature.attributes.OBJECTID);
          });
          where += "(" + list.join(", ") + ")"
          query.where = where;
          query.outFields = ['*'];
          const response = await this.featureLayer.queryFeatures(query);
          MapService.getInstance().mapView.goTo(response.features);

        } else {
          const area = (this.featureTable as any).grid.selectedItems.items[0];
          const query = this.featureLayer.createQuery();
          query.where = "OBJECTID = '" + area.feature.attributes.OBJECTID + "'";
          query.outFields = ['*'];
          const response = await this.featureLayer.queryFeatures(query);
          if (response && response.features[0] && response.features[0].geometry) {
            MapService.getInstance().mapView.goTo(response.features[0]);
            MapService.getInstance().mapView.zoom = 15;
          }
        }
      }
    });
    const selectAll = new ButtonMenuItem ({
      label: "Select all",
      clickFunction: async () => {
          const response = await this.featureLayer.queryObjectIds();
          this.featureTable.selectRows(response);
      }
    });
    const clearSelection = new ButtonMenuItem ({
        label: "Clear selection",
        clickFunction: () => {
            this.featureTable.clearSelection();
        }
    });
    this.featureLayer.title = "Restricted Areas"
    this.featureTable = new FeatureTable({
      editingEnabled: true,
      view: MapService.getInstance().mapView,
      layer: this.featureLayer,
      fieldConfigs: this.tableFieldConfig,
      container: containerEl,
      menuConfig: {
        items: [
          zoom,
          selectAll,
          clearSelection
        ]
      }
    });

    const areaGrid = (this.featureTable as any).grid;
    watch(areaGrid, "size", async () => {
      this.tableSize.size = areaGrid.size;
      const res = await this.isRA();
      if((res && this.tableSize.size != 0 && store.state.overlay != null) || (!res && this.tableSize.size == 0 && store.state.overlay != null)) {
        if(overlay.RoutingServiceVersion == "V8")
          this.updatePublished(overlay);
      }
    });
    watch((this.featureTable as any).grid, "selectedItems.length", () => {
      this.tableSelectedItems.size = (this.featureTable as any).grid.selectedItems.length;
    });
  }

  async deleteSelectedArea(): Promise<any> {
    const areaGrid = (this.featureTable as any).grid;
    if (areaGrid && areaGrid.selectedItems && areaGrid.selectedItems.items) {
      const areas: any[] = [];
      areaGrid.selectedItems.items.forEach((element: any) => {
        areas.push(element.objectId);
      });
      await this.deleteRestrictedArea(areas);
    }
  }

  isRestrictedAreas() {
    const areaGrid = (this.featureTable as any).grid;
    if (areaGrid) {
      return areaGrid.size > 0;
    }
  }

  async isRA() {
    const res = await this.getCurrentRestrictedAreas();
    if(res.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // Map Operations
  filterRestrictedAreasByOverlayId(overlayID: number): void {
    this.featureLayer.definitionExpression = `OVERLAYID = ${overlayID}`;
  }

  async getCurrentRestrictedAreas(): Promise<any> {
    const areas: IRestrictedArea[] = [];
    const query = this.featureLayer.createQuery();
    query.where = this.featureLayer.definitionExpression;
    query.outFields = ["*"];
    const response = await this.featureLayer.queryFeatures(query);
    if (response && response.features) {
      response.features.forEach((feature) => {
        const area = feature.attributes;
        area.geometry = feature.geometry;
        areas.push(area);
      });
    }
    return areas;
    //const overlays: IOverlay;
  }

  //DB Operations

  async addRestrictedArea(
    area: IRestrictedArea
  ): Promise<{ objectId: number }> {
    const geometry = area.geometry;
    delete area.geometry;
    const areaGraphic: Graphic = new Graphic({
      attributes: area,
      geometry: geometry,
    });
    const result: any = await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.restrictedAreas
        .urlField
      ],
      [areaGraphic],
      ApiService.editMode.ADD
    );
    this.featureTable.refresh();
    MapService.getInstance().map.allLayers.forEach((layer: any) => {
      if(layer.title == "Restricted areas")
        layer.refresh();
    });
    return result && result.length ? result[0].objectId : null;
  }

  async deleteRestrictedArea(areasID: any[]): Promise<any> {
    await ApiService.callEsriApplyEdits(
      ConfigService.getInstance().webmapConfig.attributes[
      ConfigService.getInstance().config.featuresServer.restrictedAreas
        .urlField
      ],
      areasID,
      ApiService.editMode.DELETE
    );
    this.featureTable.refresh();
    MapService.getInstance().map.allLayers.forEach((layer: any) => {
      if(layer.title == "Restricted areas")
        layer.refresh();
    });
  }

  async updatePublished(overlay: IOverlay) {
    if (this.tableSize.size == 1) {
      const newOrganizationName = overlay.Organisation.replace(/[^a-z0-9]/gi, '');
      const newEventName = overlay.Event.replace(/[^a-z0-9]/gi, '');
      const overlayGraphic: Graphic = new Graphic({
        attributes: {
          "OBJECTID": overlay.OBJECTID,
          "PublishedName": ("OVERLAY-" + newOrganizationName + "-" + newEventName + "-" + overlay.Name).toUpperCase(),
          "PublishedStatus": "2"
        }
      });
      await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
    } else if (this.tableSize.size == 0) {
      const overlayGraphic: Graphic = new Graphic({
        attributes: {
          "OBJECTID": overlay.OBJECTID,
          "PublishedName": "",
          "PublishedStatus": "0"
        }
      });
      await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
    }
    eventBus().emitter.emit("isOverlayEvent", { isPublished: true });
    eventBus().emitter.emit("isOverlayEvent", { isPublished: false });
  }
}
