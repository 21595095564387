
import { Options, Vue, setup } from "vue-class-component";
import { Component, Watch } from "vue-property-decorator";
import { MapService } from "../../../services/MapService";
import OverlayEdition from "./overlay/overlay-edition/OverlayEdition.vue";
import OverlaysList from "./overlays-list/OverlaysList.vue";
import { store } from "@/store";
import { computed, reactive, watch, watchEffect } from "@vue/runtime-core";

@Options({
  components: {
    OverlaysList,
    OverlayEdition,
  },
  props: {},
})
//@Component
export default class Overlays extends Vue {
  count = 0;

  get mode() {
    let mode = "list";
    if (store && store.state && store.state.overlay) {
      this.count++;
      mode = "overlay";
    }

    return mode;
  }

  /* get mapClicked() {
    return MapService.getInstance().mapClicked;
  } */
  getOverlay() {
    let overlay = "";
    if (store && store.state && store.state.overlay) {
      overlay = store.state.overlay.Name;
    }
    return overlay;
  }
}
