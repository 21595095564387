
import { IOverlay } from "@/interfaces/IOverlay";
import { store } from "@/store";
import { Options, Vue} from "vue-class-component";
import { OverlayService} from "@/services/OverlayService";
import { Component } from "@vue/runtime-core";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import { MapService } from "@/services/MapService";
import { NotificationService } from '@/services/NotificationService';
import { CustomRoadsService } from "@/services/CustomRoadsService";
import { ConfigService } from "@/services/ConfigService";
import { RestrictedAreasService } from "@/services/RestrictedAreasService";
import { ApiService } from "@/services/ApiService";
import eventBus from "@/services/EventBus";
import { ContextService } from "@/services/ContextService";
import OverlayEdition from "../OverlayEdition.vue";

@Options({
  props: {},
})

export default class GeneralInformation extends Vue {
  overlay: IOverlay = {} as IOverlay;
  showModal = false;
  isClickable = true;
  deleteRestricted = false;
  deleteCustom = false;
  isEditable = false;
  isEditor = false;
  isError = false;
  error: string;
  overlayFL: FeatureLayer;
  customRoadsFL: FeatureLayer;
  restrictedAreasFL: FeatureLayer;

  restrictedAreasSize = {size: 0};
  customRoadsSize = {size: 0};

  mounted() {
    this.overlay = { ...store.state.overlay };
    this.isEditor = ContextService.getInstance().isEditor();
    this.restrictedAreasSize = RestrictedAreasService.getInstance().tableSize;
    this.customRoadsSize = CustomRoadsService.getInstance().tableSize;
    this.initFeatureLayers();
  }

  initFeatureLayers() {
    if(!this.overlayFL) {
      this.overlayFL = MapService.getInstance().findLayerByTitle(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.title]);
    }
    if(!this.customRoadsFL) {
      this.customRoadsFL = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.customRoads.urlField]
        });

    }
    if(!this.restrictedAreasFL) {
      this.restrictedAreasFL = new FeatureLayer({
        url: ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.restrictedAreas.urlField]
        });
    }
  }

  refreshFeatureLayers() {
    if(this.overlayFL) {
      this.overlayFL.refresh();
    }
    if(this.customRoadsFL) {
      this.customRoadsFL.refresh();
    }
    if(this.restrictedAreasFL) {
      this.restrictedAreasFL.refresh();
    }
  }

  async deleteOverlay(): Promise<any> {
    eventBus().emitter.emit("isClickableEvent", { isClickable: true });
    NotificationService.getInstance().showNotification("Delete overlay","The overlay will be removed", "blue", false);
    if(this.deleteRestricted == true) {
      this.deleteRestrictedAreas();
      this.deleteRestricted = !this.deleteRestricted;
    }
    if(this.deleteCustom == true) {
      this.deleteCustomRoads();
      this.deleteCustom = !this.deleteCustom;
    }
    const result = await OverlayService.getInstance().deleteOverlay(this.overlay.PublishedName, this.overlay.OBJECTID);
    if(result == true) {
      NotificationService.getInstance().hideNotification();
      NotificationService.getInstance().showNotification("Delete overlay","The overlay has been deleted successfully", "green", false);
      this.refreshFeatureLayers();
    } else {
      NotificationService.getInstance().hideNotification();
      NotificationService.getInstance().showNotification("Delete overlay","The overlay has not been deleted", "red", false);
    }
    this.isClickable = !this.isClickable;
    eventBus().emitter.emit("isOverlayEvent", { isPublished: true });
    eventBus().emitter.emit("isOverlayEvent", { isPublished: false });
    store.commit("setOverlay", null);
  }

  async deleteCustomRoads() {
    const roads = await CustomRoadsService.getInstance().getCurrentCustomRoads();
    const roadsIDs: any[] = [];
    roads.forEach(async (element: any) => {
      roadsIDs.push(element.OBJECTID)
    });
    await CustomRoadsService.getInstance().deleteCustomRoad(roadsIDs);
  }

  async deleteRestrictedAreas() {
    const areas = await RestrictedAreasService.getInstance().getCurrentRestrictedAreas();
    const areasIDs: any[] = [];
    areas.forEach(async (element: any) => {
      areasIDs.push(element.OBJECTID)
    });
    await RestrictedAreasService.getInstance().deleteRestrictedArea(areasIDs);
  }

  async isOverlayActive() {
    const overlayGraphic : Graphic = new Graphic({
      attributes: {
        "OBJECTID": this.overlay.OBJECTID,
        "Active": this.overlay.Active
      },
      geometry: null
    })
 
    await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
    await this.deleteCustomRoads();
  }

  async initMultiselect() {
    const vehicleTypes = await ApiService.getInstance().getVehicleTypes();
    const dayLabels = await ApiService.getInstance().getDayLabels();
    const osl = await ApiService.getInstance().getOsl();
    $(this.$refs.vehicleTypesRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: vehicleTypes,
    });
    var vehicleMultiselect = $(this.$refs.vehicleTypesRef).data("kendoMultiSelect");
    var values = $.map(vehicleMultiselect.dataSource.data(), (dataItem) =>  {
      if(this.overlay.VehicleTypes.includes(dataItem.name)) {
        return dataItem;
      }
    });
    vehicleMultiselect.value(values);
    $(this.$refs.dayLabelsRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: dayLabels,
    });
    var dayMultiselect = $(this.$refs.dayLabelsRef).data("kendoMultiSelect");
    values = $.map(dayMultiselect.dataSource.data(), (dataItem) =>  {
      if(this.overlay.DayLabels.includes(dataItem.name)) {
        return dataItem;
      }
    });
    dayMultiselect.value(values);
    $(this.$refs.oslRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: osl
    });
    var oslMultiselect = $(this.$refs.oslRef).data("kendoMultiSelect");
    values = $.map(oslMultiselect.dataSource.data(), (dataItem) =>  {
      if(this.overlay.OperationalServiceLevels.includes(dataItem.name)) {
        return dataItem;
      }
    });
    oslMultiselect.value(values);
    const version = [{name: "V8"}, {name: "V7"}];
    $(this.$refs.routingServiceVersionRef).kendoMultiSelect({
      dataTextField: "name",
      dataSource: version as any,
    });
    var routingServiceVersionMultiselect = $(this.$refs.routingServiceVersionRef).data("kendoMultiSelect");
    values = $.map(routingServiceVersionMultiselect.dataSource.data(), (dataItem) =>  {
      if(this.overlay.RoutingServiceVersion.includes(dataItem.name)) {
        return dataItem;
      }
    });
    routingServiceVersionMultiselect.value(values);
  }

  async editInformations() {
    this.error = "";
    if($(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").value() != "") {
      this.isEditable = !this.isEditable;
      let vehicleType: string;
      let vehicleType2: string;
      let dayLabel: string;
      let dayLabel2: string;
      let osl: string;
      let osl2: string;
      let routingServiceV;
      var idList : any[] = [];
      var nameList : any[] = [];
      var vehicleTypes = $(this.$refs.vehicleTypesRef).data("kendoMultiSelect").value();
      if(vehicleTypes) {
        vehicleTypes.forEach((element : any) => {
          idList.push(element.id);
        });
        vehicleType = idList.join(";");
        vehicleTypes.forEach((element : any) => {
          nameList.push(element.name);
        });
        vehicleType2 = nameList.join(";");
        idList = [];
      }
      var dayLabels = $(this.$refs.dayLabelsRef).data("kendoMultiSelect").value();
      if(dayLabels) {
        dayLabels.forEach((element : any) => {
          idList.push(element.id);
        });
        dayLabel = idList.join(";");
        nameList = [];
        dayLabels.forEach((element : any) => {
          nameList.push(element.name);
        });
        dayLabel2 = nameList.join(";");
        idList = [];
      }
      var osll = $(this.$refs.oslRef).data("kendoMultiSelect").value();
      if(osll) {
        osll.forEach((element : any) => {
          idList.push(element.id);
        });
        osl = idList.join(";");
        nameList = []
        osll.forEach((element : any) => {
          nameList.push(element.name);
        });
        osl2 = nameList.join(";");
        nameList = [];
        var routingServiceV2 = $(this.$refs.routingServiceVersionRef).data("kendoMultiSelect").value();
        routingServiceV2.forEach((element : any) => {
          nameList.push(element.name);
        });
        if(nameList.length > 1) {
          routingServiceV = "V7;V8";
        } else {
          routingServiceV = nameList.join();
        }
      }
      const comments = (this.$refs.commentsRef as any).value
      const overlayGraphic : Graphic = new Graphic({
        attributes: {
          "OBJECTID": this.overlay.OBJECTID,
          "VehicleTypes": vehicleType2,
          "VehicleTypesIDs": vehicleType,
          "DayLabels": dayLabel2,
          "DayLabelIDs": dayLabel,
          "OperationalServiceLevels": osl2,
          "OperationalServiceLevelIDs": osl,
          "RoutingServiceVersion": routingServiceV,
          "Comments": comments
        }
      });

      await ApiService.callEsriApplyEdits(ConfigService.getInstance().webmapConfig.attributes[ConfigService.getInstance().config.featuresServer.overlay.urlField], [overlayGraphic], ApiService.editMode.UPDATE);
      this.refreshUI(vehicleType2, dayLabel2, osl2, routingServiceV, comments);
    } else {
      this.isError = true;
      this.error = "You must select a Routing Service Version."
    }
  }

  refreshUI(vehicleTypes : string, dayLabels : string, osl : string, routingServiceVersion : string, comment : string) {
    this.overlay.VehicleTypes = vehicleTypes;
    this.overlay.DayLabels = dayLabels;
    this.overlay.OperationalServiceLevels = osl;
    this.overlay.RoutingServiceVersion = routingServiceVersion
    this.overlay.Comments = comment;
    store.commit("setApplyToOverlay", this.overlay);
  }

}
