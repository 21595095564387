import { IApiConfig } from "@/interfaces/IApiConfig";
import { IEventLocation } from "@/interfaces/IEventLocation";
import {
  ISlotAsFeature,
  IStatus,
  ITravelDefinitionAsFeature,
  ITravelTimePerSlotAsFeature,
} from "@/interfaces/ITravelTime";
import { ApiService } from "./ApiService";
import { ConfigService } from "./ConfigService";
import { Constants } from "./Constants";
import { NotificationService } from "./NotificationService";

export class MatrixServices {
  baseUrlMatrix: string;
  orgaEventParam: string;
  constructor() {
    this.baseUrlMatrix = ConfigService.getInstance().config.api.baseUrl + "travel-time";
    this.init();
  }

  async init() {
    /*const orga = await ApiService.getInstance().getCurrentOrg();
    const event = await ApiService.getInstance().getCurrentEvent();
    this.orgaEventParam = `orgaId=${orga.id}&eventId=${event.id}`;*/
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const organizationId = urlParams.get("organization_id");
    const eventId = urlParams.get("event_id");
    this.orgaEventParam = `orgaId=${organizationId}&eventId=${eventId}`;
  }

  async getLocations(): Promise<IEventLocation[]> {
    const url = this.baseUrlMatrix + `/event-locations?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async getTransportations(): Promise<any[]> {
    const url = this.baseUrlMatrix + `/transportation-points?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async getATravelDefinition(
    from: IEventLocation,
    to: IEventLocation
  ): Promise<ITravelDefinitionAsFeature> {
    const fromId = from.attributes.UUID;
    const toId = to.attributes.UUID;
    const url = this.baseUrlMatrix + `/travels-definition/${fromId}/${toId}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = (await res.json()) as ITravelDefinitionAsFeature;
    return result;
  }

  async getSlots(): Promise<ISlotAsFeature[]> {
    const url = this.baseUrlMatrix + `/slots?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = (await res.json()) as ISlotAsFeature[];
    return result;
  }

  async getATravelTimePerSlotsByDefinition(
    definitionId: string
  ): Promise<ITravelTimePerSlotAsFeature[]> {
    const url =
      this.baseUrlMatrix +
      `/travels-duration/search?travelDefinitionId=${definitionId}&${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = (await res.json()) as ITravelTimePerSlotAsFeature[];
    return result;
  }

  async runCalcul(body: any): Promise<any> {
    const url = this.baseUrlMatrix + `/compute?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async runACalcul(travelId: number, body: any): Promise<any> {
    const url = this.baseUrlMatrix + `/compute/${travelId}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async getStatus(): Promise<IStatus> {
    let res: any;
    let result: IStatus = {
      lastComputation: Constants.errorMessageODFailedComputationStatus,
      isComputingRoutes: false,
      routesComputed: -1,
      routesToComputePerSlot: -1,
      elapstedTime: -1,
      step: ""
    };

    try {
      const url = this.baseUrlMatrix + `/status?${this.orgaEventParam}`;
      const options: RequestInit = {
        method: "GET",
        credentials: "include", // to get cookie
      };
      res = await fetch(url, options);
      this.checkResponse(res);
      result = (await res.json()) as IStatus;
    }
    catch (e) {
      console.log("Error getting OD Matrix computation status")
      /*NotificationService.getInstance().showNotification(
        Constants.errorMessageFailedComputationStatus,
        e? e.data? e.data.error? e.data.error: Constants.errorMsgUnknownError: Constants.errorMsgUnknownError: Constants.errorMsgUnknownError,
        "red",
        false
      );*/
    }
    return result;
  }

  async saveSlot(body: any): Promise<any> {
    const url = this.baseUrlMatrix + `/slots?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async deleteSlot(id: any): Promise<any> {
    const url = this.baseUrlMatrix + `/slots/${id}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "DELETE",
      credentials: "include", // to get cookie
    };

    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  // Save Via is an edit travel time definition
  async saveVia(fromId: string, toId: string, via: number[][], OperationalServiceLevels?: any[], Clients?: any[]): Promise<any> {

    const body = {
      via: via,
      OperationalServiceLevels,
      ClientGroups: Clients,
    };

    if (OperationalServiceLevels) {
      body.OperationalServiceLevels = OperationalServiceLevels;
    }
    if (Clients) {
      body.ClientGroups = Clients;
    }

    const url = this.baseUrlMatrix + `/travels-definition/${fromId}/${toId}?${this.orgaEventParam}`;
    const options: any = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include", // to get cookie
    };
    let result: any;
    const response = await fetch(url, options)
    if (response.status == 400) {
      NotificationService.getInstance().showNotification(
        "Error",
        Constants.errorMessageODMatrixImpossibleSaveVia,
        "red",
        false
      );
    } else {
      this.checkResponse(response);
      result = await response.json();
      NotificationService.getInstance().showNotification(
        "",
        Constants.messageRouteViaSavedWithSuccess,
        "green",
        false
      )
    }
    return result;
  }

  async editTravelTimePerSlot(
    predefinedTime: string,
    id: string
  ): Promise<any> {
    const body = {
      predefinedTime: predefinedTime,
    };
    const url = this.baseUrlMatrix + `/travels-duration/${id}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async getTravelFile(): Promise<void> {
    const url = this.baseUrlMatrix + `/travels-duration/export?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "GET",
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    const blob = await res.blob();
    const file = window.URL.createObjectURL(blob);
    window.location.assign(file);
    // return result;
  }

  async importFileToEditPredefined(inputFile: string): Promise<void> {
    const url = this.baseUrlMatrix + `/travels-duration/import?${this.orgaEventParam}`;
    const formData = new FormData();
    formData.append("matrix", inputFile);
    const options: RequestInit = {
      method: "POST",
      body: formData,
      headers: {
        Accept: "*/*",
      },
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    res.json();
  }

  async deleteAllRoutes(): Promise<any> {
    const url = this.baseUrlMatrix + `/travels-duration?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "DELETE",
      headers: {
        Accept: "*/*",
      },
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
    return res;
  }

  async deleteAllSlots(id: any): Promise<void> {
    const url = this.baseUrlMatrix + `/slots/${id}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "DELETE",
      headers: {
        Accept: "*/*",
      },
      credentials: "include", // to get cookie
    };

    const res = await fetch(url, options);
    this.checkResponse(res);
    const result = await res.json();
    return result;
  }

  async deleteATravelTimePerSlot(id: string): Promise<void> {
    const url = this.baseUrlMatrix + `/travels-duration/${id}?${this.orgaEventParam}`;
    const options: RequestInit = {
      method: "DELETE",
      headers: {
        Accept: "*/*",
      },
      credentials: "include", // to get cookie
    };
    const res = await fetch(url, options);
    this.checkResponse(res);
  }

  checkResponse(res: Response): void {
    if (res && !res.ok) {
      if (res.status == 401) {
        NotificationService.getInstance().showNotification(
          "Error",
          Constants.errorMessageAuthorisationError,
          "red",
          false,
          Constants.mediumNotificationTimeOut
        );
      }

      throw JSON.stringify(res);
    }
  }

  async GenerateATravelDefinition(fromId: string, toId: string) {
    try {
      const url = this.baseUrlMatrix + `/travels-definition/${fromId}/${toId}/generate?${this.orgaEventParam}`;
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "*/*",
        },
        credentials: "include", // to get cookie
      };
      await fetch(url, options);
    } catch (e) {
      NotificationService.getInstance().showNotification(
        Constants.errorMessageODMatrixGenerateATravelTimeDefinition,
        e.data.error,
        "red",
        false
      );
    } finally {
      NotificationService.getInstance().showNotification(
        Constants.messageODMatrixGenerateATravelTimeDefinitionWithSuccess,
        "",
        "green",
        false
      );
    }
    return;
  }

  async GenerateMatrix(body: any) {
    try {
      const url = this.baseUrlMatrix + `/travels-definition/generate-matrix?${this.orgaEventParam}`;
      const options: RequestInit = {
        method: "POST",
        headers: {
          Accept: "*/*",
        },
        body: JSON.stringify(body),
        credentials: "include", // to get cookie
      };
      await fetch(url, options);
    } catch (e) {
      NotificationService.getInstance().showNotification(
        Constants.errorMessageODMatrixGenerateMatrix,
        e.data.error,
        "red",
        false
      );
    } finally {
      NotificationService.getInstance().showNotification(
        Constants.messageODMatrixGenerateMatrixWithSuccess,
        "",
        "green",
        false
      );
    }
    return;
  }

  async purgeMatrix(): Promise<any> {
    let res;
    try {
      const url = this.baseUrlMatrix + `/status/purge?${this.orgaEventParam}`;
      const options: RequestInit = {
        method: "GET",
        headers: {
          Accept: "*/*",
        },
        credentials: "include", // to get cookie
      };
      res = await fetch(url, options);
    } catch (e) {
      NotificationService.getInstance().showNotification(
        "Reset computation status",
        e.data.error,
        "red",
        false
      );
    } finally {
      if (res.status == 200) {
        NotificationService.getInstance().showNotification(
          Constants.messageODMatrixResetComputationStatus,
          Constants.messageODMatrixResetComputationStatusWithSuccess,
          "green",
          false
        );
      } else {
        NotificationService.getInstance().showNotification(
          Constants.messageODMatrixResetComputationStatus,
          "Code: " + res.status + " Status: " + res.statusText,
          "red",
          false
        );
      }
    }
  }
}
