
import { Options, Vue } from "vue-class-component";
import { MapService } from "../../../services/MapService";
import CSVLayer from "@arcgis/core/layers/CSVLayer";
import GroupLayer from "@arcgis/core/layers/GroupLayer";
import GeoJSONLayer from "@arcgis/core/layers/GeoJSONLayer";
import KMLLayer from "@arcgis/core/layers/KMLLayer";
import LayerList from "@arcgis/core/widgets/LayerList";
import Layer from "@arcgis/core/layers/Layer";
import { ConfigService } from "../../../services/ConfigService";
import { ApiService } from "@/services/ApiService";
import Graphic from "@arcgis/core/Graphic";
import AddData from "./addData";
import eventBus from "@/services/EventBus";
import { parseMode } from "@esri/calcite-components/dist/types/components/calcite-color-picker/utils";
import { NotificationService } from "@/services/NotificationService";
import { Constants } from "@/services/Constants";

@Options({
  props: {},
})
export default class AddDataWidget extends Vue {
  addDataGroupLayer: GroupLayer = null;
  AddData = new AddData(this);
  layerCreated: string[] = [];
  allLayer: any[] = [];
  allLayerSearch: any[] = [];
  showColor = false;
  canSave = false;
  editName: any = -1;
  layerColorIndex: any;

  mounted() {
    eventBus().emitter.on("isEditorEvent", (data: any) => {
      if (data.isEditor) {
        this.canSave = true;
      }
    });

    this.init();
    this.$el.addEventListener("calciteColorPickerChange", (evt: any) => {
      if (this.layerColorIndex != null) {
        this.allLayer[this.layerColorIndex].color = (
          this.$refs["colorPicker"] as any
        ).value;
        (
          MapService.getInstance()
            .addDataGroupLayer.layers.getItemAt(this.layerColorIndex)
            .get("renderer") as any
        )
          .get("symbol")
          .set(
            "color",
            this.hexToRgb((this.$refs["colorPicker"] as any).value)
          );
      }
    });
    this.$el.addEventListener("calciteColorPickerInput", (evt: any) => {
      if (this.layerColorIndex != null) {
        this.allLayer[this.layerColorIndex].color = (
          this.$refs["colorPicker"] as any
        ).value;
        (
          MapService.getInstance()
            .addDataGroupLayer.layers.getItemAt(this.layerColorIndex)
            .get("renderer") as any
        )
          .get("symbol")
          .set(
            "color",
            this.hexToRgb((this.$refs["colorPicker"] as any).value)
          );
      }
    });
    this.$el.addEventListener("calciteSelectChange", (evt: any) => {
      if (evt.target.label == "uiConfig") {
        this.allLayer[evt.path[0].id].uiConfig =
          evt.target.selectedOption.value;
      }
    });
    this.$el.addEventListener("calciteInputInput", (evt: any) => {
      if (evt.target.label == "nameEdit") {
        this.allLayer[evt.target.id].name = evt.target.value;
      }
    });
    this.$el.addEventListener("calciteInputChange", (evt: any) => {
      if (evt.target.label == "nameEdit") {
        this.allLayer[evt.target.id].name = evt.target.value;
        this.editName = -1;
      }
    });
  }

  init() {
    if (
      ConfigService.getInstance().webmapConfig.attributes.AddCustomLayers !=
      null
    ) {
      try {
        JSON.parse(
          ConfigService.getInstance().webmapConfig.attributes.AddCustomLayers
        ).forEach((element: any) => {
          this.allLayerSearch.push(element);
        });
      } catch (error) {
        NotificationService.getInstance().showNotification(
          "",
          Constants.errorMsgInvalidCustomLayers,
          "red",
          false,
          Constants.mediumNotificationTimeOut
        );
      }
    }
  }

  AddLayer(element: any) {
    switch (element.type) {
      case "csv":
        this.loadCsv(element.url, element.name, element.color);
        break;
      case "kml":
        //this.loadKml(element.url, element.name);
        break;
      case "geo":
        this.loadGeoJson(element.url, element.name, element.color);
        break;
    }
    
    let newLay = {
      name: element.name,
      uiConfig: element.uiConfig,
      url: element.url,
      color: element.color,
      visible: element.visible,
      canBeSaved: element.canBeSaved,
      type: element.type,
    };
    this.allLayer.push(newLay);
  }

  //#region BY URL
  addUrl() {
    let type = "";
    let name = "";
    const oldSize =
      MapService.getInstance().addDataGroupLayer != null
        ? MapService.getInstance().addDataGroupLayer.layers.length
        : 0;
    console.log((this.$refs["calciteInputUrl"] as any).value);
    switch ((this.$refs["fileTypes"] as any).selectedOption.value) {
      case "ArcGIS":
        break;
      case "WMS":
        break;
      case "WMTS":
        break;
      case "WFS":
        break;
      case "kml":
        //this.loadKml((this.$refs["calciteInputUrl"] as any).value, "KmlLayer");
        //type = "kml"
        break;
      case "GeoRSS":
        this.loadGeoJson(
          (this.$refs["calciteInputUrl"] as any).value,
          "GeoJsonLayer"
        );
        type = "geo";
        break;
      case "csv":
        this.loadCsv((this.$refs["calciteInputUrl"] as any).value, "CsvLayer");
        type = "csv";
        break;
    }

    if (MapService.getInstance().addDataGroupLayer != null) {
      if (oldSize != MapService.getInstance().addDataGroupLayer.layers.length) {
        let newLay = {
          name: "test",
          uiConfig: 2,
          url: (this.$refs["calciteInputUrl"] as any).value,
          color: "#00FFFF",
          visible: true,
          canBeSaved: true,
          type: type,
        };
        this.allLayer.push(newLay);
      }
    }
  }
  //#endregion

  //#region BY FILE
  addFile(e: any) {
    let droppedFiles;
    if (e.dataTransfer != null) {
      droppedFiles = e.dataTransfer.files;
    } else if (e.target != null) {
      droppedFiles = e.target.files;
    }

    if (!droppedFiles) return;
    // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
    [...droppedFiles].forEach(async (f): Promise<void> => {
      console.log("File to upload: ", f);
      const url = URL.createObjectURL(f);
      const name = f.name.split(".").slice(0, -1).join(".");
      try {
        switch (f.name.split(".").pop()) {
          case "csv":
            this.loadCsv(url, name);
            break;
          case "json":
          case "geojson":
            this.loadGeoJson(url, name);
            break;
          /*case "kmz":
            this.loadGeoJson(url, name);
            break;
          case "kml":
            this.loadKml(url, name);
            break;*/
          default:
            console.log("error type");
            break;
        }
      } catch (e) {
        console.log(e);
      }

      let newLay = {
        index: this.allLayer.length,
        name: name,
        uiConfig: 0,
        url: url,
        color: "#00FFFF",
        visible: true,
        canBeSaved: false,
      };
      this.allLayer.push(newLay);
      eventBus().emitter.emit("addLayer");
    });
  }

  //#endregion

  //#region LOAD LAYER
  loadCsv(url: string, name: string, color?: any) {
    let colortmp: any;
    if (color != null) {
      colortmp = this.hexToRgb(color);
    }

    const csvLayer = new CSVLayer({
      url: url,
      title: name,
    });

    this.layerCreated.push(name);
    this.createAddDataLayerGroup();
    MapService.getInstance().addDataGroupLayer.add(csvLayer);

    csvLayer.when(() => {
      if (color != null) {
        (csvLayer.renderer.get("symbol") as any).set("color", colortmp);
        csvLayer.visible = this.allLayer[this.allLayer.length - 1].visible;
      } else {
        let colortmp2 = (csvLayer.renderer.get("symbol") as any).get("color");
        colortmp = this.rgbToHex(colortmp2.r, colortmp2.g, colortmp2.b);
        this.allLayer[this.allLayer.length - 1].color = colortmp;
      }
    });
  }

  loadGeoJson(url: string, name: string, color?: any) {
    let colortmp: any;
    if (color != null) {
      colortmp = this.hexToRgb(color);
    }

    const geoJSONLayer = new GeoJSONLayer({
      url: url,
      title: name,
    });

    this.layerCreated.push(name);
  
    this.createAddDataLayerGroup(); 
    MapService.getInstance().addDataGroupLayer.add(geoJSONLayer);

    geoJSONLayer.when(() => {
      if (color != null) {
        (geoJSONLayer.renderer.get("symbol") as any).set("color", colortmp);
        geoJSONLayer.visible = this.allLayer[this.allLayer.length - 1].visible;
      } else {
        let colortmp2 = (geoJSONLayer.renderer.get("symbol") as any).get(
          "color"
        );
        colortmp = this.rgbToHex(colortmp2.r, colortmp2.g, colortmp2.b);
        this.allLayer[this.allLayer.length - 1].color = colortmp;
      }
    });
  }

  loadKml(url: string, name: string) {
    const kmlLayer = new KMLLayer({
      url: url,
      title: name,
    });
    this.layerCreated.push(name);

    this.createAddDataLayerGroup();
    MapService.getInstance().addDataGroupLayer.add(kmlLayer);
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 1,
        }
      : null;
  }

  componentToHex(c: any) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r: any, g: any, b: any) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }
  //#endregion

  //#region LAYER TAB
  async SaveLayer() {
    const url =
      "https://services1.arcgis.com/yzmkOoV8UPHjC9ex/arcgis/rest/services/CONFIG_DEV/FeatureServer/1";
    let save = this.allLayer.filter((element) => element.canBeSaved == true);

    const webmapGraphic: Graphic = new Graphic({
      attributes: {
        OBJECTID: ConfigService.getInstance().webmapConfig.attributes.OBJECTID,
        customLayers: JSON.stringify(save),
      },
    });
    await ApiService.callEsriApplyEdits(
      url,
      [webmapGraphic],
      ApiService.editMode.UPDATE
    );
  }

  EditName(index: any) {
    this.editName = index;
  }

  HideEditName() {
    this.editName = -1;
  }

  ShowColor(layerIndex: any) {
    (this.$refs["colorPicker"] as any).value = this.allLayer[layerIndex].color;
    this.showColor = true;
    this.layerColorIndex = layerIndex;
  }

  HideColor() {
    this.showColor = false;
    this.layerColorIndex = null;
  }

  ToogleVisibleLayer(layerIndex: any) {
    MapService.getInstance().addDataGroupLayer.layers.getItemAt(
      layerIndex
    ).visible =
      !MapService.getInstance().addDataGroupLayer.layers.getItemAt(layerIndex)
        .visible;
    this.allLayer[layerIndex].visible = !this.allLayer[layerIndex].visible;
  }

  DeleteLayer(layerIndex: any) {
    if (MapService.getInstance().addDataGroupLayer.layers) {
      this.allLayer.splice(layerIndex, 1);

      MapService.getInstance().addDataGroupLayer.layers.removeAt(layerIndex);
      eventBus().emitter.emit("addLayer");

      if (MapService.getInstance().addDataGroupLayer.layers.length == 0) {
        MapService.getInstance().map.remove(
          MapService.getInstance().addDataGroupLayer
        );
        MapService.getInstance().addDataGroupLayer = null;
      }
    }
  }

  createAddDataLayerGroup() {
    if (!MapService.getInstance().addDataGroupLayer) {
      MapService.getInstance().addDataGroupLayer = new GroupLayer({
        title: "Data added",
        visible: true,
        visibilityMode: "independent",
        layers: [],
      });
      MapService.getInstance().map.add(
        MapService.getInstance().addDataGroupLayer
      );
    }
  }
}

//#endregion
