import { ILatLng } from "@/interfaces/IVia";
import Point from "@arcgis/core/geometry/Point";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import CIMSymbol from "@arcgis/core/symbols/CIMSymbol";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Color from "@arcgis/core/Color";

export class ViaR {
  label: string;
  mapView!: MapView;
  graphicsAsJson: string;
  lat: number;
  lng: number;
  graphic: Graphic;
  graphicAsJson: string;
  parentLayer: GraphicsLayer;
  coords: ILatLng;
  color: string;
  address: string;
  type: string;
  name: string;
  uuid: string;

  constructor(coords: ILatLng, parentLayer: GraphicsLayer, label: string, color: string, address?: string, type?: string, name?: string) {
    this.coords = coords;
    this.lat = coords.lat;
    this.lng = coords.lng;
    this.parentLayer = parentLayer;
    this.label = label;
    this.color = color,
    this.address = address;
    this.type = type;
    this.name = name;

    if (label) {
      this.createGraphicLabel();
    } else {
      this.createGraphic();
    }
  }

  public createGraphicLabel(): void {
    const point = new Point({
      longitude: this.lng,
      latitude: this.lat,
    });

    const convertedGeom = webMercatorUtils.geographicToWebMercator(point);

    const cimSymbol = {
      type: "cim",
      // gets JSON data defining CIMSymbolReference
      data: this.getCIMSymbolData()
    };
    
    this.graphic = new Graphic({
      geometry: convertedGeom,
      attributes: {
        text: this.label
      },
      symbol: cimSymbol as any
    });

    // this.graphic = new Graphic({
    //   geometry: convertedGeom as Point,
    //   attributes: {
    //     text: this.label
    //   },
    //   symbol : {
    //     type: "CIMPointSymbol",
    //     data: this.getCIMSymbolData()
    //   }
    // });


    this.parentLayer.add(this.graphic);

    // copy uid as attributes... due to JS Proxy
    this.graphic.setAttribute("oid", (this.graphic as any).uid);
    this.graphicsAsJson = this.graphic.toJSON();
  }

  public getMarkerViaLabel(): SimpleMarkerSymbol {
    const marker = new SimpleMarkerSymbol({
      color: this.color,
      size: 15,
    });
    return marker;
  }
  public getMarkerVia(): SimpleMarkerSymbol {
    const marker = new SimpleMarkerSymbol({
      style: "circle",

      size: "15px", // pixels
      outline: {
        // autocasts as new SimpleLineSymbol()
        color: "white",
        width: 1, // points
      },
    });
    return marker;
  }

  public createGraphic() {
    const point = new Point({
      longitude: this.lng,
      latitude: this.lat,
    });

    const convertedGeom = webMercatorUtils.geographicToWebMercator(point);

    this.graphic = new Graphic({
      symbol: this.getMarkerVia(),
      geometry: convertedGeom as Point,
    });

    this.parentLayer.add(this.graphic);
    // copy uid as attributes... due to JS Proxy
    this.graphic.setAttribute("oid", (this.graphic as any).uid);
    this.graphicAsJson = this.graphic.toJSON();
    return ViaR;
  }

  getCIMSymbolData() {

    const color = new Color(this.color);

    return {
      type: "CIMSymbolReference",
      primitiveOverrides: [
        {
          type: "CIMPrimitiveOverride",
          primitiveName: "textGraphic",
          propertyName: "TextString",
          valueExpressionInfo: {
            type: "CIMExpressionInfo",
            title: "Custom",
            expression: "$feature.text",
            returnType: "Default"
          }
        }
      ],
      symbol: {
        type: "CIMPointSymbol",
        "symbolLayers": [
          {
            "type": "CIMVectorMarker",
            "enable": true,
            "size": 20,
            "colorLocked": true,
            "anchorPointUnits": "Relative",
            "frame": {
              "xmin": -5,
              "ymin": -5,
              "xmax": 5,
              "ymax": 5
            },
            "markerGraphics": [
              {
                "type": "CIMMarkerGraphic",
                "geometry": {
                  "x": 0,
                  "y": 0
                },
                "symbol": {
                  "type": "CIMTextSymbol",
                  "fontFamilyName": "Arial",
                  "fontStyleName": "Bold",
                  "height": 4,
                  "horizontalAlignment": "Center",
                  "offsetX": 0,
                  "offsetY": 0,
                  "symbol": {
                    "type": "CIMPolygonSymbol",
                    "symbolLayers": [
                      {
                        "type": "CIMSolidFill",
                        "enable": true,
                        "color": [
                          255,
                          255,
                          255,
                          255
                        ]
                      }
                    ]
                  },
                  "verticalAlignment": "Center",
                  "font": {
                    "family": "arial",
                    "decoration": "none",
                    "style": "",
                    "weight": "bold"
                  }
                },
                "textString": this.label
              }
            ],
            "scaleSymbolsProportionally": true,
            "respectFrame": true,
            "offsetX": 0,
            "offsetY": -12,
            "anchorPoint": {
              "x": 0,
              "y": 0
            }
          },
          {
            "type": "CIMVectorMarker",
            "enable": true,
            "anchorPoint": {
              "x": 0,
              "y": 0
            },
            "anchorPointUnits": "Relative",
            "dominantSizeAxis3D": "Y",
            "size": 15,
            "billboardMode3D": "FaceNearPlane",
            "frame": {
              "xmin": 0,
              "ymin": 0,
              "xmax": 17,
              "ymax": 17
            },
            "markerGraphics": [
              {
                "type": "CIMMarkerGraphic",
                "geometry": {
                  "rings": [
                    [
                      [
                        8.5,
                        0
                      ],
                      [
                        7.02,
                        0.13
                      ],
                      [
                        5.59,
                        0.51
                      ],
                      [
                        4.25,
                        1.14
                      ],
                      [
                        3.04,
                        1.99
                      ],
                      [
                        1.99,
                        3.04
                      ],
                      [
                        1.14,
                        4.25
                      ],
                      [
                        0.51,
                        5.59
                      ],
                      [
                        0.13,
                        7.02
                      ],
                      [
                        0,
                        8.5
                      ],
                      [
                        0.13,
                        9.98
                      ],
                      [
                        0.51,
                        11.41
                      ],
                      [
                        1.14,
                        12.75
                      ],
                      [
                        1.99,
                        13.96
                      ],
                      [
                        3.04,
                        15.01
                      ],
                      [
                        4.25,
                        15.86
                      ],
                      [
                        5.59,
                        16.49
                      ],
                      [
                        7.02,
                        16.87
                      ],
                      [
                        8.5,
                        17
                      ],
                      [
                        9.98,
                        16.87
                      ],
                      [
                        11.41,
                        16.49
                      ],
                      [
                        12.75,
                        15.86
                      ],
                      [
                        13.96,
                        15.01
                      ],
                      [
                        15.01,
                        13.96
                      ],
                      [
                        15.86,
                        12.75
                      ],
                      [
                        16.49,
                        11.41
                      ],
                      [
                        16.87,
                        9.98
                      ],
                      [
                        17,
                        8.5
                      ],
                      [
                        16.87,
                        7.02
                      ],
                      [
                        16.49,
                        5.59
                      ],
                      [
                        15.86,
                        4.25
                      ],
                      [
                        15.01,
                        3.04
                      ],
                      [
                        13.96,
                        1.99
                      ],
                      [
                        12.75,
                        1.14
                      ],
                      [
                        11.41,
                        0.51
                      ],
                      [
                        9.98,
                        0.13
                      ],
                      [
                        8.5,
                        0
                      ]
                    ]
                  ]
                },
                "symbol": {
                  "type": "CIMPolygonSymbol",
                  "symbolLayers": [
                    {
                      "type": "CIMSolidStroke",
                      "enable": true,
                      "capStyle": "Round",
                      "joinStyle": "Round",
                      "lineStyle3D": "Strip",
                      "miterLimit": 10,
                      "width": 0,
                      "color": [
                        0,
                        0,
                        0,
                        255
                      ]
                    },
                    {
                      "type": "CIMSolidFill",
                      "enable": true,
                      "color": [color.r, color.g, color.b, 255]
                    }
                  ]
                }
              }
            ],
            "scaleSymbolsProportionally": true,
            "respectFrame": true,
            "offsetY": 0
          }
        ]
      }
    };
  }
}
