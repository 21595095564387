import { ApiService } from "./ApiService";
import { ConfigService } from "./ConfigService";
import { ContextService } from "./ContextService";

export class Constants {
  
  //ERROR MESSAGES
  public static errorMsgUnknownError = "Unknown error";

  public static errorMsgCustomBaseMaps = "Invalid basemaps in WebMapConfig. Check your configuration."
  public static errorMsgWidgetList = "Invalid widget list in WebMapConfig. Check your configuration.";
  public static errorMsgInvalidWebMapConfig = "Invalid WebMapConfig. Check your configuration.";
  public static errorMsgInvalidWebMapItem = "Invalid WebMapItem in WebMapConfig. Check your configuration.";
  public static errorMsgInvalidWebMapZoomToExtent = "Invalid ZoomToExtent field in WebMapItem. Check your configuration.";
  public static errorMsgInvalidWebMapZoomToExtentGeometry = "Invalid geometry for the ZoomToExtent in WebMapItem. Check your configuration.";
  public static errorMsgLocateButtonAccessDenied = "Impossible to add the Locate widget. Access denied by eRebus.";
  public static errorMsgInvalidCustomLayers = "Invalid custom layers in WebMapConfig. Please check your configuration.";

  public static errorMessageRouteV7 = "Impossible to compute the route, pls check your settings";
  public static errorMessageODMatrixInvalidATravelDefinition = "Impossible to compute the route. Please generate the matrix definition in the advanced settings tab.";
  public static errorMessageODMatrixImpossibleSaveVia = "Impossible to import a predefined route for this Start and End locations. Please check your lcation and matrix configuration and data.";
  public static errorMessageODMatrixInvalidSameOriginDestination = "Impossible to compute a route for the same origin and destination.";
  public static errorMessageODMatrixSlotDeletion = "Impossible to delete the slot(s). Please try again and/or check your configuration.";
  public static errorMessageODMatrixAllRoutesDeletion = "Impossible to delete the computed route(s). Please try again and/or check your configuration.";
  public static errorMessageODMatrixNoSlotDefined = "Impossible to compute the route(s) because there is not time slot defined. Please setup at least once in the slot panel.";
  public static errorMessageODFailedComputationStatus = "Failed to get the OD computation status from the GIS Core Service.";
  public static errorMessageODInvalidSlotData = "Invalid slot time data format. Please check your configuration.";
  public static errorMessageODMatrixGenerateATravelTimeDefinition = "Error while generating a travel definition";
  public static errorMessageODMatrixGenerateMatrix = "Error while generating the matrix definition";

  public static errorMessageAddDataInitialisation = "Impossible to initialize the AddData Widget. Please check your configuration.";

  public static errorMessageManageLocationsInitialisation = "Impossible to initialize the Manage Event Locations Widget. Please check your configuration.";
  public static errorMessageManageLocationsLoadingLocations = "Error loading the Location layer.";
  public static errorMessageManageLocationsTransportationPoints = "Error loading the Transportation Points layer.";
  public static errorMessageManageLocationsImportedLocations = "Error loading the feature layer storing the imported feature locations.";
  public static errorMessageManageLocationsGeteRebusData = "Impossible to fetch the event locations and transportation points for this event (" + ContextService.getInstance().eventId + ") from eRebus. Please try again later and/or contact your administrator.";
  public static errorMessageManageLocationsGetClusters = "Impossible to fetch the clusters for this event (" + ContextService.getInstance().eventId + ") from eRebus. Please try again later and/or contact your administator.";

  public static errorMessageAuthorisationError = "You are not authorised to access the service. Your connection may have been lost. Please restart the application."


  //RESPONSE MESSAGE
  public static messageODMatrixGenerateATravelTimeDefinitionWithSuccess = "Created a travel definition for the selected start and end locations.";
  public static messageODMatrixGenerateMatrixWithSuccess = "Travels matrix definition is up to date.";
  public static messageODMatrixSaveViaWithSuccess = "The steps have been saved successfully for the selected origin and destination.";
  public static messageODMatrixSlotDeletionWithSuccess = "All slots have been deleted.";
  public static messageODMatrixAllRoutesDeletionWithSuccess = "All computed routes have been deleted.";
  public static messageODMatrixResetComputationStatus = "Reset computation status";
  public static messageODMatrixResetComputationStatusWithSuccess = "Computation status succesfully updated.";

  public static messageRouteViaSavedWithSuccess = "The steps have been saved. Please select the start and end location in the settings tab to see the result of your import.";

  // CONST

  public static strReturnLine = "<br>";
  public static mediumNotificationTimeOut = 10000;

  public static defaultBaseMapTitles: string[] = [
    "Imagery",
    "Imagerie",
    "Streets",
    "Rues",
    "Topographic",
    "Topographie",
    "Navigation",
  ];

  public static availableWidgetList: string[] = [
    "searchWidget",
    "homeWidget",
    "locateWidget",
    "xyWidget",
    "basemapWidget",
    "layerListWidget",
    "bookmarksWidget",
    "legendWidget",
  ];

  public static defaultWidgetList: any = [
    { title: "searchWidget", enable: 1, position: "top-left" },
    { title: "homeWidget", enable: 1, position: "top-left" },
    { title: "xyWidget", enable: 1, position: "bottom-left" },
    { title: "basemapWidget", enable: 1, position: "top-right" },
    { title: "layerListWidget", enable: 1, position: "top-right" },
    { title: "legendWidget", enable: 1, position: "top-right" },
  ];

  /*public static defaultCustomBaseMapList: any = [
    {
      templateURL:
        "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{level}/{col}/{row}/256/png8?apiKey=L_ryVyUDkn9xxdYVVSukJbvyF7yZfnT25fxZjB1tKDs",
      title: "HERE Maps",
      copyright: "HERE",
      thumbnailURL:
        "https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/11/1202/843/256/png8?apiKey=L_ryVyUDkn9xxdYVVSukJbvyF7yZfnT25fxZjB1tKDs",
    },
  ];*/

  public static defaultCustomBaseMapList: any = [];

}

