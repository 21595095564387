
import { ApiService } from "@/services/ApiService";
import { IUserPermission } from "@/interfaces/IUserPermission";

export class ContextService {
    private static instance: ContextService;
    public token: string;
    public orgId: string;
    public eventId: string;
    public userId: string;
    public user: IUserPermission = {name: '', surname: '', user_found: false, is_editor: false, empty_response: false};
    public role: string;

    /**
     * Get config service instance
     */
     public static getInstance(): ContextService {
        if (!ContextService.instance) {
            ContextService.instance = new ContextService();

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.instance.orgId = urlParams.get('organization_id');
            this.instance.eventId = urlParams.get('event_id');
            this.instance.userId = urlParams.get('user_id');
            this.instance.token = urlParams.get('token');
        }

        return ContextService.instance;
    }

    /**
     * Load user
     */
    public async loadUser(login: string = null, pass: string = null): Promise<void> {
        try {
            if(ContextService.instance.token) {
                await ApiService.getInstance().logout();
                this.user = await ApiService.getInstance().login(login, pass, ContextService.instance.token);
                const userpermission: any = await ApiService.getInstance().getCurrentPermissionUser(ContextService.instance.userId);
                this.user.name = userpermission.name;
                this.user.surname = userpermission.surname;
            }

            return Promise.resolve();
        } 
        catch (error) {
            this.user.user_found = false;
            return Promise.reject();
        }
    }

    /**
     * Is authorized
     */
    public isAuthorized(): boolean {
        return ContextService.instance.user && ContextService.getInstance().user.user_found;
    }

    /**
     * Is editor
     */
     public isEditor(): boolean {
        return ContextService.instance.user && ContextService.getInstance().user.is_editor;
    }
}
